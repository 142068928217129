.time-list {
	list-style: none;
	padding: 0;
	margin: 0;
	cursor: pointer;

	input[type='radio'] {
		margin-right: 0.5rem;
		cursor: pointer;
	}
}

.ag-header-cell {
	.pan-popover-inner-content {
		height: 72px !important;
		background-color: white !important;
		color: black !important;
	}
	.icon-clock {
		cursor: pointer;
		&:hover {
			.show-icon {
        display: inline;
      }
		}
  }
  .show-icon {
    background: transparent url('../../../../images/icon-clock.svg') no-repeat center;
    padding-left: 70px;
  }
}
