// The prefix to use on all css classes from ant.
$treeselect-prefix: treeselect;

// An override for the html selector for theme prefixes
$html-selector: html;

// -------- Animations -----------

@keyframes slideUpIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}

@keyframes slideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}

@keyframes slideDownIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}

@keyframes slideDownOut {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
}

// -------- Colors -----------
$primary-color: #1890ff;
$info-color: #1890ff;
$success-color: #52c41a;
$processing-color: #1890ff;
$error-color: #f5222d;
$highlight-color: #f5222d;
$warning-color: #faad14;
$normal-color: #d9d9d9;
$white: #fff;
$black: #000;

// Color used by default to control hover and active backgrounds and for
// alert info backgrounds.
$primary-1: mix(white, $primary-color, 90%); // replace tint(@primary-color, 90%)
$primary-2: mix(white, $primary-color, 80%); // replace tint(@primary-color, 80%)
$primary-3: mix(white, $primary-color, 70%); // replace tint(@primary-color, 70%)
$primary-4: mix(white, $primary-color, 60%); // replace tint(@primary-color, 60%)
$primary-5: mix(white, $primary-color, 50%); // replace tint(@primary-color, 50%)
$primary-6: mix(white, $primary-color, 40%); // replace tint(@primary-color, 40%)
$primary-7: mix(white, $primary-color, 30%); // replace tint(@primary-color, 30%)


// Base Scaffolding Variables
// ---

// Background color for `<body>`
$body-background: #fff;
// Base background color for most components
$component-background: #fff;
$font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB',
  'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol';
$code-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
$text-color: rgba($black, 65%);
$text-color-secondary: rgba($black, 45%);
$text-color-inverse: $white;
$icon-color: inherit;
$icon-color-hover: rgba($black, 75%);
$heading-color: rgba(#000, 85%);
$heading-color-dark: rgba($white, 100%);
$text-color-dark: rgba($white, 85%);
$text-color-secondary-dark: rgba($white, 65%);
$text-selection-bg: $primary-color;
$font-variant-base: tabular-nums;
$font-feature-settings-base: 'tnum';
$font-size-base: 14px;
$font-size-lg: $font-size-base + 2px;
$font-size-sm: 12px;
$heading-1-size: ceil($font-size-base * 2.71);
$heading-2-size: ceil($font-size-base * 2.14);
$heading-3-size: ceil($font-size-base * 1.71);
$heading-4-size: ceil($font-size-base * 1.42);
$line-height-base: 1.5;
$border-radius-base: 4px;
$border-radius-sm: 2px;

// vertical paddings
$padding-lg: 24px; // containers
$padding-md: 16px; // small containers and buttons
$padding-sm: 12px; // Form controls and items
$padding-xs: 8px; // small items

// vertical padding for all form controls
$control-padding-horizontal: $padding-sm;
$control-padding-horizontal-sm: $padding-xs;

// The background colors for active and hover states for things like
// list items or table cells.
$item-active-bg: $primary-1;
$item-hover-bg: $primary-1;

// Animation
$ease-base-out: cubic-bezier(0.7, 0.3, 0.1, 1);
$ease-base-in: cubic-bezier(0.9, 0, 0.3, 0.7);
$ease-out: cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-in: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
$ease-out-back: cubic-bezier(0.12, 0.4, 0.29, 1.46);
$ease-in-back: cubic-bezier(0.71, -0.46, 0.88, 0.6);
$ease-in-out-back: cubic-bezier(0.71, -0.46, 0.29, 1.46);
$ease-out-circ: cubic-bezier(0.08, 0.82, 0.17, 1);
$ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.34);
$ease-in-out-circ: cubic-bezier(0.78, 0.14, 0.15, 0.86);
$ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
$ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);

// Border color
$border-color-base: #d9d9d9; // base border outline a component
$border-color-split: #e8e8e8; // split border inside a component
$border-color-inverse: $white;
$border-width-base: 1px; // width of the border for a component
$border-style-base: solid; // style of a components border

// Outline
$outline-blur-size: 0;
$outline-width: 2px;
$outline-color: $primary-color;

$background-color-light: #fafafa; // background of header and selected item
$background-color-base: #f5f5f5; // Default grey background color

// Disabled states
$disabled-color: rgba(#000, 25%);
$disabled-bg: $background-color-base;
$disabled-color-dark: rgba(#fff, 35%);

// Shadow
$shadow-color: rgba(0, 0, 0, 0.15);
$shadow-color-inverse: $component-background;
$shadow-1-up: 0 -2px 8px $shadow-color;
$shadow-1-down: 0 2px 8px $shadow-color;
$shadow-1-left: -2px 0 8px $shadow-color;
$shadow-1-right: 2px 0 8px $shadow-color;
$shadow-2: 0 4px 12px $shadow-color;
$box-shadow-base: $shadow-1-down;

// z-index list, order by `z-index`
$zindex-table-fixed: auto;
$zindex-affix: 10;
$zindex-back-top: 10;
$zindex-badge: 10;
$zindex-picker-panel: 10;
$zindex-popup-close: 10;
$zindex-modal: 1000;
$zindex-modal-mask: 1000;
$zindex-message: 1010;
$zindex-notification: 1010;
$zindex-popover: 1030;
$zindex-dropdown: 1050;
$zindex-picker: 1050;
$zindex-tooltip: 1060;

// Input
// ---
$input-height-base: 32px;
$input-height-lg: 40px;
$input-height-sm: 24px;
$input-padding-horizontal: $control-padding-horizontal - 1px;
$input-padding-horizontal-base: $input-padding-horizontal;
$input-padding-horizontal-sm: $control-padding-horizontal-sm - 1px;
$input-padding-horizontal-lg: $input-padding-horizontal;
$input-padding-vertical-base: 4px;
$input-padding-vertical-sm: 1px;
$input-padding-vertical-lg: 6px;
$input-placeholder-color: #bfbfbf;
$input-color: $text-color;
$input-border-color: $border-color-base;
$input-bg: $component-background;
$input-number-handler-active-bg: #f4f4f4;
$input-number-handler-hover-bg: $primary-5;
$input-number-handler-bg: $component-background;
$input-number-handler-border-color: $border-color-base;
$input-addon-bg: $background-color-light;
$input-hover-border-color: $primary-5;
$input-disabled-bg: $disabled-bg;
$input-outline-offset: 0 0;
$input-number-hover-border-color: $input-hover-border-color;

// Select
// ---
$select-border-color: $border-color-base;
$select-item-selected-font-weight: 600;
$select-dropdown-bg: $component-background;
$select-item-selected-bg: $background-color-light;
$select-item-active-bg: $item-active-bg;
$select-background: $component-background;


// Tag
// --
$tag-default-bg: $background-color-light;
$tag-default-color: $text-color;
$tag-font-size: $font-size-sm;


