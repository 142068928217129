#alerts-app {
	.alerts-main {
		background: white;
		// background: #f4f5f5;
		.alerts-summary {
			height: 42px;
			position: relative;
			border-bottom: 1px solid #dadbdb;
			.alerts-stat {
				position: absolute;
				top: 10px;
				.active-count,
				.open-count {
					color: #707070;
					font-size: 16px;
					display: inline;
					padding: 0px 16px;
				}

				.active-count {
					border-right: 1px solid #707070;
				}
			}

			.pan-time-range-picker,
			.export-btn {
				cursor: pointer;
				font-size: 12px;
				color: #006fcc;
				display: inline-block;
				position: absolute;
				top: 9px;
				height: 24px;
				border: 1px solid #dadbdb;
				box-sizing: border-box;
				border-radius: 4px;
				> img {
					position: absolute;
					left: 12px;
					top: 4px;
				}
				> .text {
					position: absolute;
					line-height: 16px;
					top: 4px;
				}
			}
			.pan-time-range-picker {
				right: 120px;

				.pan-input {
					border: none;
					box-shadow: none;
				}

				input.pan-calendar-picker-input {
					margin-left: 32px;

					outline: none;
				}

				.pan-select-arrow {
					top: 9px;
					right: 10px;
				}

				.pan-calendar-picker-input {
					width: 118px;
				}

				.time-picker-preset-custom > .pan-calendar-picker-input {
					width: 280px;
				}

				.time-picker-calendar-icon {
					background-image: none;
					opacity: 1;
					content: url('../../../images/icon-calendar.svg');
				}
				.pan-dropdown {
					min-width: 150px !important;
					.pan-dropdown-menu {
						font-size: 12px;
						> li {
							font-size: 12px;
						}
					}
				}

				.time-picker-preset-custom .pan-dropdown {
					min-width: 312px !important;
				}

				.pan-calendar-picker-container {
					left: -240px !important;
					top: 25px !important;
					.pan-calendar-time-picker-btn,
					.pan-calendar-ok-btn {
						text-decoration: none;
						color: #006fcc;
					}

					.pan-calendar-ok-btn {
						color: #fff !important;
						background: #1890ff !important;
					}
				}
			}
			.export-btn {
				width: 80px;
				right: 16px;
				> .text {
					left: 32px;
				}
			}
		}
		.alerts-table {
			height: calc(100vh - 155px);
		}
	}
}
