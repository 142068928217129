//test
@import './expVariables';
.ag-theme-balham {
    color: $dark-gray;
    font-family: Lato, sans-serif;

    .ag-header-cell-label .ag-header-cell-text {
        background-color: #fff;
        color: $dark-gray;
        text-transform: capitalize;
    }

    .ag-header, .ag-header-row {
        font-family: Lato, sans-serif;
        //border-bottom: 1px solid $light-gray;
        height: 30px !important;
        min-height: 30px !important;
    }

    .ag-header-cell::after, .ag-header-group-cell::after {
        margin-top: 0px; //this was breaking the dividers!!
    }

    .ag-icon-checkbox-checked {
        color: $link-color;
    }

    .ag-icon.ag-icon-checkbox-unchecked {
        color: $gray-medium;
    }

    .ag-tab-header {
        background-color: $table-head-bg; //used this since it is used for sidecar header bg
    }

    .ag-theme-balham .ag-input-wrapper input[type='text'] {
        border-color: $light-gray;
        border-radius: $border-radius;

        &:focus {
            border-color: $link-color;
        }
    }

    .ag-tab-body, .ag-popup-editor, .ag-menu {
        color: $dark-gray;
        font-size: $text-sm;
    }

    .ag-column-tool-panel-column-label {
        text-transform: capitalize;
    }

}