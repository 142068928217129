.detail-panel {
	@import '../expVariables';
	.log-detail-container {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: row;
	}

	.log-detail-timeline {
		flex-grow: 0;
		flex-shrink: 0;
		flex-basis: 200px;
		height: calc(100vh - #{$drawer-header-height});
		overflow-x: hidden;
		overflow-y: auto;
		white-space: nowrap;
		position: relative;

		.timeline-err {
			position: absolute;
			width: min-content;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: #0a9cdd;
			justify-content: center;

			& > img {
				display: block;
				margin: auto;
			}

			& > div {
				font-size: 12px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: normal;
				text-align: center;
				color: $dark-gray;
				padding-top: 16px;
			}
		}

		.timeline-spin {
			position: relative;
			width: min-content;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: #0a9cdd;
		}

		.timeline-list {
			list-style: none;
			margin: 0;
			padding: 20px 0 0 20px;
			font-size: 11px;

			.timeline-item {
				position: relative;
				&::before {
					content: '\2022';
					color: #a4a9ad;
					display: inline-block;
					z-index: 1;
					font-size: 1.3rem;
					height: 15px;
					line-height: 15px;
				}

				&:not(:last-child)::after {
					content: '';
					display: block;
					background-color: #a4a9ad;
					width: 1px;
					height: 44px;
					border-radius: 0.5px;
					margin-left: 5px;
				}

				.timestamp-date {
					font-family: Lato;
					font-size: 11px;
					font-weight: bold;
					font-stretch: normal;
					font-style: normal;
					line-height: normal;
					letter-spacing: 0.5px;
					color: #7f868c;
					padding-left: 15px;
				}

				.timeline-timestamp {
					position: absolute;
					left: 15px;
					top: -10px;
					height: 36px;
					line-height: 36px;

					.timestamp-logtype {
						font-family: Lato;
						font-size: 11px;
						font-weight: bold;
						font-stretch: normal;
						font-style: normal;
						line-height: normal;
						letter-spacing: 0.75px;
						color: $dark-gray;
						padding-left: 15px;
					}

					.timestamp-time {
						font-family: Lato;
						font-size: 11px;
						font-weight: normal;
						font-stretch: normal;
						font-style: normal;
						line-height: normal;
						letter-spacing: normal;
						color: #24292d;
						padding-left: 5px;
					}
				}

				&.selected-row::before {
					content: '\2022';
					//based on new mockups there's only blue now... Not sure about this change
					color: red;
					display: inline-block;
					z-index: 3;
					font-size: 1.3rem;
					height: 15px;
					line-height: 15px;
				}

				&.selected-log {
					&::before {
						content: '\2022';
						color: #0a9cdd;
						display: inline-block;
						z-index: 2;
						font-size: 1.3rem;
						height: 15px;
						line-height: 15px;
					}
					.timeline-timestamp {
						width: 142px;
						height: 36px;
						border-radius: 18px;
						border: solid 1px #69b7db;
						background-color: #dfedf4;
						display: inline-block;
						position: absolute;
						top: -10px;
						left: 15px;
					}
				}
			}
		}
	}

	.log-detail-tabs {
		padding: 10px 10px 0px 10px;
		width: 100%;
		height: calc(100vh - #{$drawer-header-height});
		.nav-tabs {
			font-size: $text-sm;

			.nav-item .nav-link {
				color: $text-light;

				&.active {
					color: $link-color;
				}
			}
		}

		> .tab-content {
			height: calc(100% - 28px);
			> .tab-pane {
				height: 100%;
				position: relative;
				padding: 0px;
				> .anchor-anchors {
					height: 30px;
					line-height: 40px;
					padding-left: 10px;
					position: relative;
				}
				> .anchor-cards {
					overflow-y: scroll;
					height: calc(100% - 30px);
				}
			}
		}
	}

	.anchor-anchor,
	.anchor-anchor:hover {
		margin-right: 20px;
		font-family: Lato;
		font-size: 11px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: 0.1px;
		color: $text-light;
		text-decoration: none;
	}

	.anchor-highlight,
	.anchor-highlight:hover {
		font-family: Lato;
		font-size: 11px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: 0.1px;
		color: $link-color;
	}

	.anchor-white-space {
		height: 100%;
	}

	.expand-card {
		margin: 16px auto;
		width: 99%;
		//border: 1px solid black;
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
		border-radius: 4px;
		cursor: pointer;

		&-content {
			height: 100%;
			width: 100%;
			padding: 15px;
			display: inline-grid;
			grid-gap: 0;
		}

		&-title {
			font-family: Lato;
			font-size: 16px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			padding-bottom: 16px;
			color: $dark-gray;
			grid-column: 1 / span 3;
		}

		&-more {
			color: $link-color;
			padding: 15px;
			padding-top: 0px;
			text-align: right;
		}
	}
	.expand-card-userbadge {
		grid-row-start: 2;
		grid-row-end: 4 span;
		display: flex;
		flex: 1;
		flex-direction: column;
		.ubadge {
			border-radius: 25px;
			background: #ffc528;
			width: 50px;
			font-size: 18px;
			color: #fff;
			height: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			align-self: center;
			font-weight: bold;
			letter-spacing: 0.5px;
		}
		.name {
			text-align: center;
			font-size: 18px;
			margin-top: 15px;
			font-weight: 500;
			letter-spacing: normal;
			color: $dark-gray;
		}
		.title {
			text-align: center;
			font-size: 11px;
			color: #7f868c;
			letter-spacing: 0.5px;
		}
	}

	.expand-card-cell {
		min-width: 0;
		overflow: hidden;
		&-title {
			height: 13px;
			width: calc(100% - 10px);
			font-family: Lato, sans-serif;
			font-size: $text-sm;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: 14px;
			color: $dark-gray;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			text-transform: capitalize;
		}

		&-data {
			height: 15px;
			width: calc(100% - 5px);
			font-family: Lato, sans-serif;
			font-size: $text-sm;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: 0.1px;
			color: $dark-gray;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}
}
