// -------- Colors -----------
$primary-color: #1890ff;
$info-color: #1890ff;
$success-color: #52c41a;
$processing-color: #1890ff;
$error-color: #f5222d;
$highlight-color: #f5222d;
$warning-color: #faad14;
$normal-color: #d9d9d9;
$white: #fff;
$black: #000;

// Color used by default to control hover and active backgrounds and for
// alert info backgrounds.
$primary-1: mix(white, $primary-color, 90%); // replace tint(@primary-color, 90%)
$primary-2: mix(white, $primary-color, 80%); // replace tint(@primary-color, 80%)
$primary-3: mix(white, $primary-color, 70%); // replace tint(@primary-color, 70%)
$primary-4: mix(white, $primary-color, 60%); // replace tint(@primary-color, 60%)
$primary-5: mix(white, $primary-color, 50%); // replace tint(@primary-color, 50%)
$primary-6: mix(white, $primary-color, 40%); // replace tint(@primary-color, 40%)
$primary-7: mix(white, $primary-color, 30%); // replace tint(@primary-color, 30%)


// Base Scaffolding Variables
// ---

// Background color for `<body>`
$body-background: #fff;
// Base background color for most components
$component-background: #fff;
$font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB',
'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
'Segoe UI Emoji', 'Segoe UI Symbol';
$code-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
$text-color: rgba(0, 0, 0, 65%);
$text-color-secondary: rgba(0, 0, 0, 45%);
$text-color-inverse: $white;
$icon-color: inherit;
$icon-color-hover: rgba(0, 0, 0, 75%);
$heading-color: rgba(0, 0, 0, 85%);
$heading-color-dark: rgba(255, 255, 255, 100%);
$text-color-dark: rgba(255, 255, 255, 85%);
$text-color-secondary-dark: rgba(255, 255, 255, 65%);
$text-selection-bg: $primary-color;
$font-variant-base: tabular-nums;
$font-feature-settings-base: 'tnum';
$font-size-base: 14px;
$font-size-lg: $font-size-base + 2px;
$font-size-sm: 12px;
$heading-1-size: ceil($font-size-base * 2.71);
$heading-2-size: ceil($font-size-base * 2.14);
$heading-3-size: ceil($font-size-base * 1.71);
$heading-4-size: ceil($font-size-base * 1.42);
$line-height-base: 1.5;
$border-radius-base: 4px;
$border-radius-sm: 2px;

// Border color
$border-color-base: #d9d9d9; // base border outline a component
$border-color-split: #e8e8e8; // split border inside a component
$border-color-inverse: $white;
$border-width-base: 1px; // width of the border for a component
$border-style-base: solid; // style of a components border

// z-index list, order by `z-index`
$zindex-table-fixed: auto;
$zindex-affix: 10;
$zindex-back-top: 10;
$zindex-badge: 10;
$zindex-picker-panel: 10;
$zindex-popup-close: 10;
$zindex-modal: 1000;
$zindex-modal-mask: 1000;
$zindex-message: 1010;
$zindex-notification: 1010;
$zindex-popover: 1030;
$zindex-dropdown: 1050;
$zindex-picker: 1050;
$zindex-tooltip: 1060;

// vertical paddings
$padding-lg: 24px; // containers
$padding-md: 16px; // small containers and buttons
$padding-sm: 12px; // Form controls and items
$padding-xs: 8px; // small items

// Shadow
$shadow-color: rgba(0, 0, 0, 0.15);
$shadow-color-inverse: $component-background;
$shadow-1-up: 0 -2px 8px $shadow-color;
$shadow-1-down: 0 2px 8px $shadow-color;
$shadow-1-left: -2px 0 8px $shadow-color;
$shadow-1-right: 2px 0 8px $shadow-color;
$shadow-2: 0 4px 12px $shadow-color;
$box-shadow-base: $shadow-1-down;

// Tooltip
// ---
// Tooltip max width
$tooltip-max-width: 250px;
// Tooltip text color
$tooltip-color: #fff;
// Tooltip background color
$tooltip-bg: rgba(0, 0, 0, 0.75);
// Tooltip arrow width
$tooltip-arrow-width: 5px;
// Tooltip distance with trigger
$tooltip-distance: $tooltip-arrow-width - 1px + 4px;
// Tooltip arrow color
$tooltip-arrow-color: $tooltip-bg;

// Popover
// ---
// Popover body background color
$popover-bg: $component-background;
// Popover text color
$popover-color: $text-color;
// Popover maximum width
$popover-min-width: 177px;
// Popover arrow width
$popover-arrow-width: 6px;
// Popover arrow color
$popover-arrow-color: $popover-bg;
// Popover outer arrow width
// Popover outer arrow color
$popover-arrow-outer-color: $popover-bg;
// Popover distance with trigger
$popover-distance: $popover-arrow-width + 4px;