@import '../../styles/variables';

.nav-container {
    padding-top: $nav-bar-height;
    display: flex;
    align-items: stretch;
    height: 100vh;
    overflow: hidden;
}

.nav-left-panel {
    background-color: rgb(245, 245, 245);
    padding-bottom: 20px;
}

.nav-vertical .nav-link {
    border-radius: 0;
}

.nav-vertical .nav-link.active,
.nav-vertical .show > .nav-link {
    background-color: #dadada;
}

.nav-vertical .nav-item .nav-link .fa {
    width: 18px;
    height: 18px;
    font-size: 18px;
}

.nav-vertical .nav-item .nav-link.active .fa,
.nav-vertical .nav-item .nav-link.active .nav-label {
    color: #1d2124;
}

.nav-label.pl-2 {
    vertical-align: bottom;
}

.nav-vertical .nav-item .nav-link {
    font-size: 13px;
    color: rgba(145, 151, 157, 1);
    text-transform: uppercase;
}

.nav-vertical .nav-item .nav-link span img {
    width: 24px;
    height: 24px;
}
.nav-left-panel .Pane1 {
    overflow-y: auto;
}

.nav-left-panel .Pane2, .nav-left-panel .Pane1 {
    padding-bottom: 20px;
    flex-grow: 1;
    background-color: #fff;
}