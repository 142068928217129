//@import '../../../../styles/common.scss';
@import '../GridWidget/GridWidget.scss';

.hlgrid-header {
    background: transparent !important;

    &:empty {
        height: 0;
    }
}

.hlgrid-header.card-header {
    padding: 16px 24px 12px;
    min-height: 64px;
}

.hlgrid-header.card-header > .align-items-start {
    justify-content: space-between;

    > div.d-flex {
        flex-direction: column;

        .hlgrid-header-title {
            margin-top: 0;
        }
    }

    .flex-grow-1.input-group {
        max-width: 400px;
    }
}

.hlgrid .pan-ag-grid {
    height: calc(100vh - 116px);   
    max-height: calc(100vh - 116px);
    width: calc(100vw - 200px);
    border: 0;
}

.wizard-step .hlgrid .pan-ag-grid {
    width: 100%;
    height: calc(50vh - 116px);
}

.hlgrid-header-title {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    color: #0e1012;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 0 !important;
    margin-right: 20px;
    margin-top: 4px;
}

.location-dropdown {
    line-height: 1;
    margin-bottom: -3px;
    top: -3px;
    position: relative;

    > .dropdown {

        &.show > .btn-secondary,
        &.show > .btn-secondary:hover {
            background: #fff;
            border: 0;
            box-shadow: none;
        }

        .dropdown-menu {
            padding: 4px 0;
            font-size: 12px;
        }

        .btn {
            height: auto;
            padding: 0 14px 0 0;
            border: 0;

            &:hover,
            &.hover,
            &:active,
            &.active,
            &:not(:disabled):not(.disabled):active,
            &:not(:disabled):not(.disabled).active,
            &:focus,
            &.focus {
                background: #fff;
                border: 0;
                box-shadow: none;
            }

            &.dropdown-toggle:after {
                top: 0;
                right: 0;
            }
        }
    }

    .dropdown-item {
        border: 0;
        border: 2px solid transparent;
        border-radius: 2px;
        padding: 4px 8px;

        &:hover {
            background-color: rgba(11, 164, 232, .1);
        }

        &:active,
        &:active:focus {
            background-color: rgba(11, 164, 232, .18);
            border-color: transparent;
        }

        &:focus {
            background: #fff;
            border-color: #033045;
            outline: 0;
        }
    }
}