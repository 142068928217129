@import './expVariables';
.log-viewer-header {
	background-color: $grid-bg;

	.pan-select,
	.pan-select-tree,
	.pan-dropdown-menu-item,
	.pan-calendar-picker-input {
		font-size: 12px;
	}

	//no extra spacing for second level in search dropdown tree
	.pan-select-tree-switcher.pan-select-tree-switcher-noop {
		width: 10px;
	}

	.pan-select-search__field:focus {
		border: 1px solid $link-color;
	}

	.pan-select-selection-selected-value,
	input.pan-calendar-picker-input {
		color: $link-color;
	}

	.second-search-bar {
		position: relative;
		display: flex;
		margin: 16px 16px 0 16px;
		background-color: white;
		border-top-left-radius: $border-radius;
		border-top-right-radius: $border-radius;
		height: 50px;
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);

		.time-n-results {
			align-items: center;
			color: #7f868c;
			display: flex;
			flex: 1;
			line-height: 50px;
			margin-left: 16px;
			.current-date-range,
			.logs-count-results {
				display: flex;
				padding-right: 10px;
				font-weight: 400;
				font-size: 12px;
				color: $dark-gray;
			}
		}
		.logs-count-results {
			padding-right: 10px;
			&::after {
				content: '\007C';
				padding-left: 10px;
				color: $light-gray;
			}
		}
		.pagination {
			margin-right: 14px;
			.log-export {
				display: inline-block;
				line-height: 50px;
				height: 50px;
				display: flex;
				align-items: center;
				.export-btn,
				.cancel-btn {
					margin-right: 13px;
					box-shadow: none;
				}
				.history-btn {
					width: 14px;
					height: 14px;
					cursor: pointer;
					margin-right: 13px;
				}
				.progress {
					height: 22px;
					width: 200px;
					border-radius: 5rem;
					margin-right: 15px;
					font-size: inherit;

					.bg-success {
						background-color: #28a3e8 !important;
					}
				}
				.hide-text {
					font-size: 0px !important;
				}
				.show-text {
					font-size: 12px !important;
				}
				.badge.download {
					border-radius: 20px !important;
					padding: 5px 10px !important;
					margin-right: 15px;
				}
				&::after {
					content: '\007C';
					padding-right: 10px;
					color: $light-gray;
				}
			}
			&-prev,
			&-next {
				position: relative;
			}
			.pagination-btn {
				position: relative;
				height: 50px;
				width: 24px;
				line-height: 50px;
				img {
					position: absolute;
					top: 13px;
					left: 0px;
					width: 24px;
					height: 24px;
				}
			}
			display: flex;
			&-btn,
			&-btn:focus {
				outline: none;
				border: none;
				background-color: transparent;
				cursor: pointer;
				font-size: 20px;
			}
			&-btn:disabled {
				cursor: not-allowed;
				color: grey;
			}
			&-page_count {
				line-height: 50px;
				margin: 0 8px;
			}
		}
	}
}
