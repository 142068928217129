.notifications-drawer {
	.ant-drawer-content-wrapper {
		.ant-drawer-body {
			.rule-config-card {
				.content {
					[class$='config'] {
						height: fit-content;
						min-height: 55px;
						padding: 10px 16px;
						padding-top: 17px;
						&:not(:last-child) {
							border-bottom: 1px solid #eaebeb;
						}
						.type {
							margin-bottom: 5px;
							.title {
								position: relative;
								left: 12px;
								top: 2px;
							}
						}
						.template-picker {
							margin-bottom: 15px;
							padding-left: 25px;
							> .ant-select {
								> .ant-select-selector {
									border-radius: 4px;
								}
								&:not(:last-child) {
									margin-right: 10px;
								}
								font-family: Lato;
								font-size: 12px;
								.ant-select-selector {
								}
								.ant-select-selection-item {
								}
							}
						}
						.email-freq {
							padding-left: 25px;
							.ant-radio-wrapper {
								display: block;
								.ant-radio {
									margin-right: 8px;
									position: relative;
									top: 2px;
								}
							}
						}
					}
				}
			}
		}
	}
}

// .assignment-select,
.servicenow-template-select,
.email-template-select {
	animation: none;
	.ant-select-arrow {
		right: 5px;
		* {
			display: inline-block;
		}
	}
	&.create-mode {
		height: fit-content;
		padding: 0px;
	}
	&.ant-select-dropdown {
		height: fit-content;
		padding: 0px;
		border-radius: 4px;
		border: 1px solid #d4d4d4;
		.ant-select-item-option-content {
			.create-group {
				position: relative;
				background-color: white;
				> .title {
					padding-left: 16px;
					height: 33px;
					line-height: 33px;
					font-weight: bold;
					border-bottom: 1px solid #eaebeb;
				}
				> .input-item {
					font-weight: normal;
					padding: 10px 16px;
				}
			}
			.btns {
				height: 45px;
				.cancel-btn,
				.next-btn {
					display: grid;
					justify-content: center;
					align-content: center;
					width: 105px;
					height: 24px;
					position: absolute;
					bottom: 15px;
					cursor: pointer;

					box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
					border-radius: 4px;
				}
				.next-btn {
					right: 16px;
					background: #006fcc;
					color: white;
					&.disabled {
						background-color: #b8b8b8;
					}
				}

				.cancel-btn {
					right: 136px;
					border: 1px solid #dadbdb;
					background: white;
					color: #006fcc;
				}
			}
		}

		.ant-select-item {
			&:not(:last-child) {
				border-bottom: 1px solid #d4d4d4;
			}
			font-family: Lato;
			font-size: 12px;
			.icon-add-group {
				margin-right: 8px;
				position: relative;
				top: -2px;
			}
			.icon-modify {
				position: absolute;
				top: 8px;
				right: 40px;
			}
			.icon-remove {
				position: absolute;
				top: 8px;
				right: 15px;
			}
		}
	}
}

.servicenow-template-select.create-mode {
	.rc-virtual-list-holder {
		max-height: 500px !important;
	}
	.rc-virtual-list-scrollbar {
		display: none;
	}
}
