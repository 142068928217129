.help-tab {
	position: absolute;
	height: 40px;
	width: 100%;
	bottom: 12px;
	left: 0px;
	.tab-btn {
		cursor: pointer;
		font-family: Lato;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 40px;
		letter-spacing: 0.2px;

		* {
			display: inline-block;
		}

		.icon {
			padding-left: 21px;
			padding-right: 8px;
		}

		&:hover,
		&.menu-visible {
			background: #354252;
		}
	}

	.tab-menu {
		width: 250px;
		padding: 24px;
		border-radius: 4px;
		position: absolute;
		left: calc(100% + 4px);
		bottom: -85px;
		color: white;
		background: #19222e;
		font-family: Lato;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		display: none;
		&.menu-visible {
			display: block;
		}

		.title {
			color: #b8b8b8;
			font-size: 12px;
			margin-bottom: 16px;
		}

		.item {
			cursor: pointer;
			&:not(:last-child) {
				margin-bottom: 16px;
			}

			a {
				color: white;
			}
		}
	}
}

._pendo-step-container._pendo-resource-center-global-container._pendo-guide-walkthrough_ {
	left: 254px !important;
	bottom: 4px !important;
	top: auto !important;
}
