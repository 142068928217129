@import 'Constants';

@mixin reset{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: $text-color;
  font-size: $font-size-base;
  font-variant: $font-variant-base;
  line-height: $line-height-base;
  list-style: none;
  font-feature-settings: $font-feature-settings-base;
}

@mixin clearfix() {
  zoom: 1;f~
&::before,
&::after {
  display: table;
  content: '';
}
  &::after {
    clear: both;
  }
}

@mixin hover($color: $input-hover-border-color) {
  border-color: $color;
  border-right-width: $border-width-base !important;
}

@mixin active($color: $outline-color) {
  border-color: mix(white, $color, 50%);
  border-right-width: $border-width-base !important;
  outline: none;
  // box-shadow: $input-outline-offset $outline-blur-size $outline-width rgba($color, 20%);
}

// for iconfont font size
// fix chrome 12px bug, support ie
@mixin iconfont-size-under-12px($size, $rotate: 0deg) {
  display: inline-block;
  $font-scale: unit($size / 12px);

  font-size: 12px;
  // IE9
  font-size: '#{$size} \9';
  transform: scale($font-scale) rotate($rotate);
  :root & {
    font-size: $font-size-sm; // reset IE9 and above
  }
}

@mixin iconfont-mixin() {
  display: inline-block;
  color: $icon-color;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em; // for SVG icon, see https://blog.prototypr.io/align-svg-icons-to-text-and-say-goodbye-to-font-icons-d44b3d7b26b4
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  > * {
    line-height: 1;
  }

  img {
    display: inline-block;
  }

  &::before {
    display: none; // dont display old icon.
  }

  & &-icon {
    display: block;
  }
}

@mixin TreeSwitcherIcon($type: 'tree-default-open-icon') {
  .#{$tree-prefix-cls}-switcher-icon,
  .#{$select-prefix-cls}-switcher-icon {
    @include iconfont-size-under-12px(10px);

    display: inline-block;
    font-weight: bold;
    img {
      transition: transform 0.3s;
    }
  }
}

@mixin selection__clear() {
  position: absolute;
  top: 50%;
  right: $control-padding-horizontal - 1px;
  z-index: 1;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: $disabled-color;
  font-size: $font-size-sm;
  font-style: normal;
  line-height: 12px;
  text-align: center;
  text-transform: none;
  background: $component-background;
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease, opacity 0.15s ease;
  text-rendering: auto;
  &::before {
    display: block;
  }
  &:hover {
    color: $text-color-secondary;
  }
}
