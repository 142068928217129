.setting-popover.pan-popover {
	.pan-popover-inner-content {
		padding: 0px;
		padding-bottom: 4px;
	}
	& .pan-popover-arrow {
		display: none;
	}
}

.nav-popover.pan-popover {
	z-index: 1055;
	transform: inherit !important;
	animation-duration: inherit !important;

	.pan-popover-arrow {
		border-color: #333333;
	}
	.pan-popover-inner-content {
		padding: 0 8px;
		background-color: #333333;
		border-radius: 3px;
		color: white;
		font-family: Lato;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 32px;
		height: 32px;
	}
}

.filter-popover.pan-popover {
	z-index: 1100;
	transform: inherit !important;
	animation-duration: inherit !important;

	.pan-popover-arrow {
		border-color: #333333;
		left: 10px;
	}
	.pan-popover-inner-content {
		padding: 0 8px;
		background-color: #333333;
		border-radius: 3px;
		color: white;
		font-family: Lato;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 32px;
		height: 32px;
	}
}

#strata-visibility {
	height: 100vh;
	width: 100vw;
	display: grid;
	background-color: #f4f5f5;
	font-family: Lato;
	font-style: normal;
	font-weight: normal;
	color: #333333;

	.ag-theme-balham .ag-root-wrapper {
		border: none;
	}

	.ag-center-cols-container .ag-row {
		border: none;
	}

	.ag-cell-focus {
		border: none;
	}

	&.left-nav-open {
		.visibility-body {
			left: 250px;
		}
	}
	&.left-nav-close {
		.visibility-nav {
			.account-switcher {
				.username,
				.account {
					display: none;
				}
			}
			.refresh-tab {
				.refresh-btn {
					.text {
						display: none;
					}
				}
				.refresh-info {
					display: none;
				}
			}
		}

		.visibility-body {
			left: 65px;
		}

		.tab-name {
			display: none;
		}
	}

	.info-area {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);

		& * {
			text-align: center;
		}

		@keyframes loading-spinner {
			from {
				transform: rotate(0deg);
			}
			to {
				transform: rotate(360deg);
			}
		}

		.loading-spinner {
			animation: loading-spinner 1s linear infinite;
			width: 16px;
			height: 16px;
			margin-right: 10px;
		}
	}

	#app-bar {
		position: absolute;
		top: 0px;
		bottom: 0px;
	}

	.visibility-nav {
		background: #19222e;
		color: white;
		height: 100%;
		position: relative;
		img.icon {
			opacity: 1;
		}
	}
	> .visibility-body {
		position: absolute;
		top: 0px;
		right: 0px;
		transition: left 0.3s;
		> .notification {
			width: 100%;
			position: absolute;
			top: 0px;
			z-index: 1040;

			font-family: Lato;
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			padding: 0px 15px;
			display: flex;
			justify-content: space-between;
			height: 64px;
			line-height: 64px;
			&.info {
				background-color: #eff7ff;
				&::after {
					position: absolute;
					bottom: 0px;
					left: 0px;
					content: '';
					display: block;
					height: 4px;
					width: 100%;
					background: linear-gradient(90deg, #2c95c7 0%, #26418d 100%);
				}
			}
			&.warning {
				background-color: #fef4d5;
				&::after {
					position: absolute;
					bottom: 0px;
					left: 0px;
					content: '';
					display: block;
					height: 4px;
					width: 100%;
					background: linear-gradient(90deg, #ffde65 0%, #ff7b41 100%);
				}
			}
			&.error {
				background-color: #ffefef;
				&::after {
					position: absolute;
					bottom: 0px;
					left: 0px;
					content: '';
					display: block;
					height: 4px;
					width: 100%;
					background: linear-gradient(90deg, #f0b3b6 0%, #e27d86 23.83%, #c83a4b 60.82%, #c0212b 83.6%, #ad1618 100%);
				}
			}
		}
	}
}
