.log-collector-group-list {
  ul {
    font-size: 12px;
    padding: 0;
  }

  li {
    padding-left: 12px;
    list-style-type: none;
    line-height: 2em;

    img, svg {
      vertical-align: text-bottom;
      margin-right: 8px;
      font-size: 1.4em;
    }

    li {
      padding-left: 20px;
      font-weight: bold;
      color: #006FCC;
      list-style-type: none;
      line-height: 2em;
      cursor: pointer;

      img {
        vertical-align: baseline;
        margin-right: 8px;
        font-size: 1.4em;
      }
    }
  }
}
