@import "Constants";
@import "Mixins";

.#{$calendar-prefix-cls}-month {
  .#{$calendar-prefix-cls}-month-header-wrap {
    position: relative;
    height: 288px;
  }
  .#{$calendar-prefix-cls}-month-panel,
  .#{$calendar-prefix-cls}-year-panel {
    top: 0;
    height: 100%;
  }
}
