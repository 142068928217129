#alerts-app {
	height: 100vh;
	background: #ffffff;
	.alerts-header {
		position: relative;
		padding: 16px;
		height: 65px;
		box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
		.title {
			position: absolute;
			font-weight: 300;
			font-size: 24px;
			line-height: 32px;
		}
	}
	.alerts-nav + div {
		display: block;
		height: 16px;
		background: #f4f5f5;
	}
}
