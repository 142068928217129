.rect {
    path {
        &:hover {
            fill: "#5991d8"
        }
        &:active {
            fill: "#5991d8"
        }
    }
    
}

.hover-tooltip{
    border-radius: 3px;
    display: inline-block;
    font-size: 13px;
    left: -999em;
    opacity: 0;
    padding: 8px 21px;
    position: fixed;
    pointer-events: none;
    transition: opacity 0.3s ease-out;
    top: -999em;
    display: none;
    z-index: 999;
    background: #222;
    color:#fff;
    transform:translate(-50%,-120%)
}
