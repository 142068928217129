#alerts-app {
	.alert-forecast-label{
		color: red;
		font-style: italic;
		font-size: 10px;
		font-weight: 600
	}
	.alert-details {
		.alert-charts {
			.sync-chart {
				height: 100%;
				min-height: 280px;
				position: relative;

				.line-chart {
					&:not(:last-child) {
						border-bottom: 1px solid #dadbdb;
					}
					.chart-title {
						font-size: 16px;

						height: 40px;
						line-height: 40px;

						position: relative;

						border: none;
						border-bottom: 1px solid #dadbdb;

						.desc {
							z-index: 3;
							display: none;
							position: absolute;
							top: 35px;
							left: 0px;

							background: #24292d;
							border-radius: 4px;

							padding: 12px;
							width: max-content;
							max-width: 300px;

							color: white;

							font-size: 12px;
							line-height: 14px;
							letter-spacing: 0.1px;

							white-space: normal;
							word-wrap: break-word;

							* {
								margin: 0px;
							}

							ul {
								padding-inline-start: 20px;
							}

							&::before {
								content: '';
								width: 0px;
								height: 0px;
								border-width: 5px;
								border-style: solid;
								border-color: transparent transparent #24292d transparent;

								position: absolute;
								bottom: 100%;
								left: 13px;
							}
						}

						.icon {
							&::before {
								content: url('../../../../../images/icon-info.svg');
								width: 16px;
								height: 16px;
								vertical-align: middle;
								margin-right: 5px;
								margin-left: 10px;
							}

							&:hover ~ .desc {
								display: block;
							}
						}
					}
					.chart-config {
						position: absolute;
						top: 0px;
						right: 0px;
						height: 40px;
						width: 185px;
						border-left: 1px solid#DADBDB;

						.time-range-picker {
							position: absolute;
							left: 35px;
							top: 8px;
							button.selected.dropdown-item {
								background-color: rgba(31, 117, 178, 0.15);
							}
							.dropdown {
								& .btn.dropdown-toggle {
									&:focus {
										box-shadow: none;
									}
									position: relative;
									height: 24px;
									width: 120px;
									padding: 2px 10px;
									border: none;
									background: #ffffff;
									/* Light/Structures/Border */

									font-size: 12px;
									line-height: 24px;
									/* identical to box height, or 182% */

									display: flex;
									align-items: center;

									/* Light/Text/Link Text */

									color: #006fcc;

									&::after {
										position: absolute;
										top: 0px;
									}
								}
							}
						}

						.setting {
							position: absolute;
							right: 15px;
							top: 8px;
							cursor: pointer;
							.chart-tools {
								position: relative;
								.setting-popover.pan-popover {
									width: 150px;
									padding-top: 5px;
									.setting-title {
										padding-left: 16px;
										height: 32px;

										font-weight: bold;
										font-size: 14px;
										line-height: 32px;
										letter-spacing: 0.5px;
										text-transform: uppercase;
										color: #7f868c;

										border-bottom: 2px solid #c8cbce;
										margin-bottom: 16px;
									}

									.setting-option {
										margin: 12px 16px;

										font-size: 12px;
										letter-spacing: 0.1px;

										color: #24292d;
										.checkbox-icon {
											margin-right: 10px;
											cursor: pointer;
										}
									}
								}
							}
						}
					}
					.chart {
						height: 240px;
						position: relative;

						.legend-bg {
							position: absolute;
							display: inline-block;
							width: 170px;
							height: 214px;
							background: #f4f5f5;
							top: 15px;
							right: 15px;
							z-index: 1;
						}
						& > div {
							overflow: visible !important;
							& > .highcharts-container {
								overflow: visible !important;
								z-index: 2 !important;
								// .highcharts-legend {
								// 	left: 400px !important;
								// 	top: 15px;
								// }
								// .highcharts-legend-item {
								// 	left: 23px !important;
								// }
								.highcharts-axis {
									&.highcharts-xaxis {
										display: none;
									}
									&.highcharts-yaxis {
										display: none;
									}
								}

								.legend-item {
									position: relative;
									// left: -30px;
									left: -13px;
									top: 6px;
									.checkbox-icon {
										margin-left: 1px;
										margin-bottom: 1px;
										cursor: pointer;
									}
									.legend-text {
										position: relative;
										left: 20px;
										top: -15px;
										width: 122px;
										max-width: 122px;
										display: block;
										word-wrap: break-word;
										white-space: normal;
									}
								}

								.ref-line {
									position: relative;
									right: 10px;
									top: 15px;

									font-size: 12px;
									letter-spacing: 0.1px;
									z-index: 0;
								}

								.timeline-tooltip {
									letter-spacing: 0.1px;

									.tooltip-timestamp {
										font-size: 12px;
									}

									.tooltip-value {
										font-size: 16px;
									}
								}

								.commits-tooltip {
									position: relative;
									left: 100px;
									z-index: 3;

									background: #24292d;
									border-radius: 4px;

									width: 200px;

									color: white;

									font-weight: bold;
									font-size: 12px;
									line-height: 14px;
									letter-spacing: 0.1px;

									white-space: normal;
									word-wrap: break-word;

									.tooltip-title {
										padding: 7px 12px;
										border-bottom: 1px solid white;
									}

									.tooltip-content {
										padding: 7px 12px;
										border-bottom: 1px solid white;
										p {
											margin-bottom: 5px;
										}
										p:last-child {
											margin-bottom: 0px;
										}
									}

									.tooltip-footer {
										padding: 7px 12px;
									}

									&.arrow-left:after {
										content: '';
										width: 0px;
										height: 0px;
										border-width: 4px;
										border-style: solid;
										border-color: #24292d transparent transparent transparent;

										position: absolute;
										top: 100%;
										left: 6px;
									}
									&.arrow-right:after {
										content: '';
										width: 0px;
										height: 0px;
										border-width: 4px;
										border-style: solid;
										border-color: #24292d transparent transparent transparent;

										position: absolute;
										top: 100%;
										right: 6px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
