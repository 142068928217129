//@import '../../../../styles/common.scss';

.fieldset {
    border: 1px solid #c8cbce;
    padding: 4px 12px 12px;
    margin-bottom: 16px;
    border-radius: 4px;

    &:first-child:last-child,
    &:last-child {
        margin-bottom: 0;
    }

    > .form-group:last-child,
    > .form-group:first-child:last-child,
    .row:last-child .form-group:last-child,
    .row:last-child .form-group:first-child:last-child,
    .flex-row:last-child .form-group:last-child,
    .flex-row:last-child .form-group:first-child:last-child,
    .form-group:last-child > .form-group:last-child,
    .form-group:first-child:last-child > .form-group:last-child {
        margin-bottom: 0;
    }
}

.legend {
    display: block;
    padding: 1px 6px 0;
    width: auto;
}

legend {
    font-size: 11px;
    font-weight: 800;
    color: #6d757b;
    text-transform: uppercase;
    margin-bottom: 0;
}

legend > span {
    margin-left: 6px;
}
