//breakpoints general and specific

$break-large: 1200px;
$break-medium: 1020px; //using this for top nav for now
// Variables overrides
// Root override file. Sets some core values so that Bootstrap can use them while setting other variables.

// Colors

$blue: #006fcc;
$green: #3f9b6f;
$accent: #ffcb06;
// $red: #e15050;
$red: #d94949;
$nav-bg: #263444;
$text-muted: #49535b;
$text-light: #808080; //used in some paragraphs
$dark-gray: #333;
$gray-medium: #7f868c; // Used for form labels and some subheads
$gray-pri-9: #dadcde;
$gray-pri-3: #6d757b;
$gray-dark-pri-4: #24292d;
$light-gray: #dadbdb; //used in separators, secondary button borders etc.
$lighter-gray: #eaeaea;
$disabled-gray: #c4c4c4; // used in primary disabled but not sure where else
$disabled-gray2: #a3a3a3; //used in secondary disabled!!
$light-blue: #d3e8fc; //used for hover, and selected states in grids, dropdowns etc.

$theme-colors: (
	secondary: #a4a9ad
);

$border-color-default: #e8e8e8;
$border-color-darker: #ccc;
// $border-color: #dadcde;
$border-color: #c8cbce;
$hover-bg: rgba(0, 0, 0, 0.05);
//To Do add latest hover behavior for link
$hover-bg: lighten(#0ba4e8, 50%);
$hover-bg: rgba(11, 164, 323, 0.06);

//appbar overrides

// Body

$body-bg: #fff;
$body-color: #24292d;
$page-title-color: #0e1012; // Is this intentionally slightly different than the body color?

$enable-shadows: true;

$base-margin: 12px;

$border-radius: 4px;

// Fonts

$font-family-sans-serif: 'Lato', sans-serif;

// Text sizes

$text-lg: 16px;
$text-med: 14px;
$text-sm: 12px;
$text-nav: 18px;
$text-title: 24px;

$h1-font-size: 1.7rem;
$h2-font-size: 1.3rem;
$headings-margin-bottom: 0;
$font-size-sm: 300;

// Links

$link-color: $blue;
$link-hover-color: #0993d1;

// Spacing

$grid-gutter-width-base: 15px;
$grid-gutter-width-lg: 30px;

// Navs

$nav-bar-height: 50px;
$navbar-padding-y: 0;
$navbar-padding-x: 0;
$nav-link-padding: 0.8em 1em; // Is this getting used?

$navbar-dark-color: rgba(255, 255, 255, 0.8);
$navbar-dark-hover-color: rgba(255, 255, 255, 1);
$navbar-dark-active-color: rgba(255, 255, 255, 1);

$nav-pills-link-active-color: $body-color;
$nav-pills-link-active-bg: rgba(0, 0, 0, 0.1);
$nav-pills-border-radius: 3em;
$nav-hover-bg: #475566; //for left nav hover and selected

//breadcrumb overrides
$breadcrumb-divider: url(/images/caret-right.png);
$breadcrumb-font-size: 12px;

// Buttons

$btn-height: 30px;
$btn-border-radius: 4px;
$btn-border-radius-sm: 4px;
$btn-border-radius-lg: 4px;
$btn-border-width: 1px;
$btn-padding-x: 1rem;
$btn-padding-y: 0.5rem;
// $btn-padding-x: 16px; // [awong] The units need to be rem, not px
// $btn-padding-y: 10px; // [awong] The units need to be rem, not px
$btn-gray: $body-bg;

$btn-height-sm: 24px;
$btn-height-lg: 44px;

$btn-sm-padding-x: 10px;
$btn-sm-padding-y: 5px;

$btn-lg-padding-x: 20px;
$btn-lg-padding-y: 12px;

$input-btn-padding-y-lg: 0.7rem;
$input-btn-padding-x-lg: 1.4rem;
$input-btn-line-height-lg: 1.5;

$btn-transition: all 0.15s ease-in-out;

$main-header-btn-bg: #ffffff;
$sec-btn-border: $light-gray;
$sec-btn-hover-bg: #eff7ff;

// Cards

$card-cap-bg: #f2f2f2;
$card-spacer-x: 1.28rem;
$card-spacer-y: 1.128rem;
// $card-spacer-x: 18px; // [awong] The units need to be rem, not px
// $card-spacer-y: 18px; // [awong] The units need to be rem, not px
// $card-border-color: #dadcde;
$card-border-color: $border-color;
$card-cap-bg: #fff;

// Forms

// $input-border-radius: 4px;
$input-border-radius: 2px;
$input-rl-padding: 10px;

$input-border-radius-sm: 4px;
$input-rl-padding-sm: 8px;

$input-border-color: #c8cbce;
$input-focus-border-color: $blue;

$form-check-input-gutter: 20px;

$custom-select-border-width: 1px;
$custom-select-bg: none;
$custom-select-border-radius: 30px;
$custom-select-indicator: url(../images/chevron-down.svg);

// Modals

$modal-content-border-width: 0;
$modal-backdrop-bg: #000;
$modal-backdrop-opacity: 0.5;
$modal-footer-border-width: 0;
$modal-inner-padding: 24px;
// $modal-header-padding: 24px;
$modal-header-padding: 12px 24px;
$modal-header-bg-color: #f6f7f7;

// Tables

// $table-cell-padding: 14px;
$table-cell-padding: 10px;
$table-border-color: #efeff0;
$table-bg-accent: rgba(0, 0, 0, 0.03);
$table-hover-bg: rgba(0, 0, 0, 0.02);
$table-head-bg: #fff;
$row-hover-color: rgba(11, 164, 232, 0.08);

// $table-accent-bg: #f8fcfd;
// $table-accent-bg: rgba(11, 164, 232, .03);
$table-accent-bg: rgba($blue, 0.07);

$table-head-bg: #f4f5f5;
$table-head-color: #49535b;

$table-hover-bg: #fff;

$active-row: #d8e8e8;

$border-h-color: #eaf1f4;
$border-v-color: #e4e4e4;

$table-border-color-h: #edeeee;
$table-border-color-v: #dadcde;

// Dropdowns

// $dropdown-border-color: #dadcde;
$dropdown-border-color: $border-color;
$dropdown-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
$dropdown-divider-bg: $border-color-default;
$dropdown-header-color: $blue;
$dropdown-item-padding-y: 0.5rem;
$dropdown-item-padding-x: 0.5rem;
// $dropdown-divider-bg: #dadcde;
$dropdown-divider-bg: $border-color;

// $dropdown-link-hover-bg: $hover-bg;

$dropdown-link-hover-bg: rgba($blue, 0.06);

// $dropdown-link-active-color: #fff;
// $dropdown-link-active-bg: rgba(11, 164, 232, .12);

$dropdown-link-active-color: $body-color;
$dropdown-link-active-bg: rgba($blue, 0.12);

// Tabs
$nav-tabs-link-active-bg: #fff;
$nav-tabs-border-radius: 0;
$nav-tabs-link-hover-border-color: transparent;
$nav-tabs-border-width: 0;

// Badges

$badge-font-weight: normal;
$badge-pill-border-radius: 2em;
$badge-padding-y: 0.3em;
$badge-padding-x: 0.4em;
$badge-pill-padding-x: 0.6em;

// Alerts
// Define alert colors, border radius, and padding.

$alert-padding-y: 1.25rem;
$alert-padding-x: 2rem;
$alert-margin-bottom: 1rem;
$alert-border-radius: 0;
$alert-link-font-weight: normal;
$alert-border-width: 0;

// Tooltips

$tooltip-font-size: 12px;
$tooltip-bg: $body-color;
$tooltip-opacity: 1;
$tooltip-padding-y: 4px;
$tooltip-padding-x: 8px;

$tooltip-arrow-width: 10px;
$tooltip-arrow-height: 6px;

// Containers

//grid background
$grid-bg: #f4f5f5;
$card-bg: $grid-bg;

//agGrid color, bg variables

$ag-row-group-bg: #eaebeb;
//To do: change when specs given
$ag-row-group-bg-hover: #eaebeb;
$ag-row-selected-bg: $light-blue;
$ag-row-even-bg: #fff;
$ag-row-odd-bg: #f4f5f5;
$ag-header-bg: #fff;
$ag-header-text: $dark-gray;
$ag-group-value: #7f868c;
$ag-group-value-row: $gray-dark-pri-4; //this is for special row groupings cell color
$ag-header-separator: $light-gray;
$ag-inherited-header: #ef9e00;
$ag-inherited-row: #c6920e;

// Drawer
$drawer-border-radius: 0px;
$drawer-header-height: 50px;
