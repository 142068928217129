@import "style/Constants";
@import "style/Mixins";

$input-prefix-cls: "#{$pan-prefix}-input";
$select-prefix-cls: "#{$pan-prefix}-recycle-select";
$autocomplete-prefix-cls: "#{$select-prefix-cls}-auto-complete";

.#{$autocomplete-prefix-cls} {
	@include reset;

	&.#{$select-prefix-cls} {
		.#{$select-prefix-cls} {
			&-selection {
				border: 0;
				box-shadow: none;
				&__rendered {
					height: 100%;
					margin-right: 0;
					margin-left: 0;
					line-height: $input-height-base;
				}
				&__placeholder {
					margin-right: ($input-padding-horizontal-base + 1px);
					margin-left: ($input-padding-horizontal-base + 1px);
				}

				&--single {
					height: auto;
				}
			}
		}

		.#{$select-prefix-cls}-search--inline {
			position: static;
			float: left;
		}

		&-allow-clear {
			.#{$select-prefix-cls}-selection:hover .#{$select-prefix-cls}-selection__rendered {
				margin-right: 0 !important;
			}
		}

		.#{$input-prefix-cls} {
			height: $input-height-base;
			line-height: $line-height-base;
			background: transparent;
			border-width: $border-width-base;
			&:focus,
			&:hover {
				@include hover;
			}
			&[disabled] {
				@include disabled;

				background-color: transparent;
			}
		}

		&-lg {
			.#{$select-prefix-cls}-selection__rendered {
				line-height: $input-height-lg;
			}
			.#{$input-prefix-cls} {
				height: $input-height-lg;
				padding-top: $input-padding-vertical-lg;
				padding-bottom: $input-padding-vertical-lg;
			}
		}

		&-sm {
			.#{$select-prefix-cls}-selection__rendered {
				line-height: $input-height-sm;
			}
			.#{$input-prefix-cls} {
				height: $input-height-sm;
				padding-top: $input-padding-vertical-sm;
				padding-bottom: $input-padding-vertical-sm;
			}
		}
	}
}

.#{$input-prefix-cls}-group > .#{$autocomplete-prefix-cls} {
	.#{$select-prefix-cls}-search__field.#{$input-prefix-cls}-affix-wrapper {
		display: inline;
		float: none;
	}
}
