.alerts-nav {
	height: 32px;
	display: flex;
	flex-direction: row;

	.alerts-nav-item {
		// width: 75px;
		text-align: center;
		padding: 6px 15px;
		cursor: pointer;

		font-size: 12px;
		line-height: 20px;
		margin-right: 4px;

		border-bottom: 4px solid #b8b8b8;

		&.active {
			border-bottom: 4px solid #006fcc;
		}
	}

	.alerts-nav-filler {
		flex-grow: 1;
		border-bottom: 4px solid #eaebeb;
	}
}
