#insights-app {
	.insights-main {
		> .grid {
			> .hex-grid {
				@keyframes grow {
					0% {
						transform: scale(0.5);
					}
					100% {
						transform: scale(1);
					}
				}

				.expander-grow {
					animation: grow 200ms;
					animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
					animation-fill-mode: forwards;
				}

				> .setting {
					position: absolute;
					top: 8px;
					right: 10px;
					z-index: 2;

					.setting-popover.pan-popover {
						width: 170px;
						padding-top: 2px;
						.option-sort {
							border-bottom: 1px solid #c8cbce;
						}
						.option-sort,
						.option-health {
							padding: 14px 17px;
							> * {
								&:not(:last-child) {
									margin-bottom: 10px;
								}
							}
							> .option-title {
								height: 14px;

								font-weight: bold;
								font-size: 11px;
								line-height: 14px;
								letter-spacing: 0.5px;
								text-transform: uppercase;

								color: #7f868c;
							}
							> .option {
								height: 16px;

								font-size: 12px;
								line-height: 16px;
								letter-spacing: 0.1px;
								color: #24292d;

								[class$='-icon'] {
									margin-right: 10px;
									vertical-align: text-top;
								}
							}
						}
					}
				}
			}
		}
	}
}
