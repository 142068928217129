//@import '../../../../styles/common.scss';

.editor-grid-builder.form-group {

    .col-form-label {
        align-self: auto;
        padding-top: 6px;
    }

    .pagination-bar {
        padding: 2px 16px;

        &:first-of-type:not(:last-of-type) {
            padding-bottom: 0;
            height: 32px;
        }
    }
}