@import '../../../../styles/_variables.scss';

.dropdown-error {
    border: 1px solid $red;
    border-radius: 5px;
}

.dropdown-item {
    &.level-1 {
        padding-left: 15px !important
    }

    &.selected {
        font-weight: bold
    }
}