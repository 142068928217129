#insights-app {
	.log-query-wrapper {
		display: flex;

		.pan-recycle-select-selection {
			outline: none;
			border: none;
		}
		.pan-input {
			border-color: #dadbdb;
		}

		.pan-recycle-select-dropdown {
			z-index: 1150;
		}

		.pan-input-group.pan-input-group-compact {
			flex: 1 0;
			display: flex;
			// background-color: #fff;

			&.log-query-date-group {
				flex: 0;
			}

			> * {
				flex: none;
				// fix border and effect overlapping issue
				&.pan-recycle-select-focused,
				&:focus,
				&:active,
				&:hover {
					z-index: 1100;
				}
			}

			> .log-query-completion-wrapper {
				display: flex;
				flex: 1 0;

				> .log-query-completion,
				> .log-query-input {
					flex: 1 0;
				}

				// fix border moving issue
				> .log-query-logtype-select {
					> .pan-recycle-select-selection {
						border-right-width: 1px !important;
						margin-right: -1px;
						min-width: 100px;
					}
				}

				> *:first-child,
				> .pan-recycle-select:first-child > .pan-recycle-select-selection,
				> .pan-calendar-picker:first-child .pan-input,
				> .pan-recycle-select-auto-complete:first-child .pan-input,
				> .pan-cascader-picker:first-child .pan-input,
				> .pan-mention-wrapper:first-child .pan-mention-editor,
				> .pan-time-picker:first-child .pan-time-picker-input,
				> .pan-time-range-picker:first-child .pan-input {
					border-top-left-radius: 4px !important;
					border-bottom-left-radius: 4px !important;
				}
			}

			.log-query-btn {
				position: relative;
				border: 1px solid #dadbdb;
				background: #ffffff;
				height: 32px;
				width: 32px;
				cursor: pointer;

				.search-icon {
					position: absolute;
					top: 10px;
					left: 10px;
					opacity: 1;
					transition: none;
				}
			}

			> .log-query-btn,
			> .pan-recycle-select:last-child > .pan-recycle-select-selection,
			> .pan-calendar-picker:last-child .pan-input,
			> .pan-recycle-select-auto-complete:last-child .pan-input,
			> .pan-cascader-picker:last-child .pan-input,
			> .pan-mention-wrapper:last-child .pan-mention-editor,
			> .pan-time-picker:last-child .pan-time-picker-input,
			> .pan-time-range-picker:first-child .pan-input {
				border-width: 1px;
				border-top-right-radius: 4px !important;
				border-bottom-right-radius: 4px !important;
			}
		}
	}
}
