.hlgrid-form > .form-group.wizard-step.card {
    padding-top: 16px;
}

.out-container {
    font-family: Lato;
    display: flex;
    flex-direction: column;
    width: 75%;
    .map-top-container {
        margin-bottom: 1em;
        font-size: 14px;
        color: #6d757b;
        
        .init-title {
            font-size: 20px;
            color: #0e1012;
        }
    }

    .newLine {
        width: 80%;
        height: 15px;
        border-bottom: 1px solid #dadcde;
        margin-bottom: 20px;
    }

    .map-middle-container {
        padding-bottom: .5em;
        display: flex;
        font-family: Lato;
        font-size: 14px;
        flex-direction: column;
        position: relative;

        .key {
            font-weight: bold;
            margin-right: .5rem;
        }
    }
}

.map-bottom-btn-group {
    display: flex;
    position: absolute;
    top: 10px;
    right: 24px;
}

.center {
    position: absolute;
    width: 100%;
    height: 80%;
    top: 17%;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Lato;

    .message {

        .status {
            font-size: 20px;
            .in-progress{
                font-weight: bold;
            }
            .success {
                color: #1faf2c;
                font-weight: bold;
            }

            .error {
                color: #d94949;
                font-weight: bold;
            }
        }
    }
}

.regionMap {
    margin: 1rem 0;
}
