.device-list {
  .card {
    margin-top: 1em;
  }

  ul {
    font-size: 12px;
    padding: 0;
  }

  li {
    padding-left: 12px;
    font-weight: bold;
    color: #006FCC;
    list-style-type: none;
    line-height: 2em;
    cursor: pointer;

    img {
      margin-right: .7em;
      font-size: 1.4em;
    }
  }
}
