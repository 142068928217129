.row-builder {

    .form-group {
        margin-bottom: 0;
    }

    .col-10 {
        max-width: 80%;
        padding-right: 12px !important;
    }

    .col-2 {
        .col-form-label {
            width: 0;
            padding: 0 !important;
        }
    }
}
