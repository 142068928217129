.device-graph-drawer {
  .services-drawer-content {
    font-size: 12px;
    line-height: 16px;

    table {
      white-space: nowrap;
    }

    table th {
      text-align: right;
      white-space: nowrap;
      font-weight: normal;
      width: 35%;
      padding-left: 5px;
      padding-right: 5px;
      border-right: solid 2px #f0f0f0;
    }

    table td {
      padding-left: 5px;
    }
  }
}