.hlgrid-header-search.input-group-text {
    border-radius: 20px 0 0 20px;
    background-color: #fff;

    .fa-search {
        &:before {
            content: '';
            display: block;
            width: 16px;
            height: 16px;
            background: url(/images/search.png) no-repeat;
        }
    }
}

.filter-input.form-control {
    border-radius: 0 20px 20px 0;
    height: 28px;
}

.hlgrid-header-search {
    height: 28px;
}

.pagination-bar  {
   .hlgrid-header-search, .filter-input.form-control {
       height: 26px;
   }
}