.plusButton {
    width: 30px;
    height: 32px;
    cursor: pointer;
    background-image: url(../../../../images/plus_default.png);
    &:hover {
        background-image: url(../../../../images/plus_hover.png);
    }
    &:active {
        background-image: url(../../../../images/plus_pressed.png);
    }
}
.minusButton {
    width: 30px;
    height: 32px;
    cursor: pointer;
    background-image: url(../../../../images/minus_default.png);
    &:hover {
        background-image: url(../../../../images/minus_hover.png);
    }
    &:active {
        background-image: url(../../../../images/minus_pressed.png);
    }
}