//@import '../../../../styles/common.scss';

.ag-cell-inline-editing{
    overflow: visible;
}

.editorgrid-cell-editor .form-group {
    margin-bottom: 0;
}

.cell-editor.editor-cell-show {
    position: absolute;
    z-index: 100;
}

/*
.editorgrid-cell-editor .form-control {
    height: 32px;
}
*/

.editorgrid-cell-editor {
    z-index: 10;
    flex: 100 0 auto;
    width: 100%;
    min-height: 21px;
}

.cell-editor {
    background: #fff;
}

.cell-value {
    text-overflow: ellipsis;
    overflow: hidden;
    padding-top: 3px;
    padding-bottom: 3px;
}

.editor-cell-hide {
    display: none;
}

.editor-grid-cell-error {
    border-bottom : 1px solid #e15050;
}