#alerts-app {
	.alert-details {
		.alert-charts {
			.table-chart {
				.ag-root {
					border: 0px !important;
					.ag-header {
						height: 28px !important;
						min-height: 28px !important;
						border-bottom: 1px solid#DADBDB !important;
						.ag-header-cell {
							font-family: Lato;
							font-style: normal;
							font-weight: bold;
							font-size: 12px;
							color: #333333;

							padding-left: 16px;
							padding-right: 16px;

							height: 28px !important;
							background: #ffffff;
							&:last-child::after {
								display: none !important;
							}
							&:not(:last-child)::after {
								top: 0px;
								height: 28px;
								margin-top: 0px !important;
								background: #dadbdb !important;
							}
						}
					}
					.ag-cell {
						border: 0px;
						font-family: Lato;
						font-style: normal;
						font-weight: normal;
						font-size: 12px;
						color: #333333;
						line-height: 28px;
						padding-left: 16px;
						padding-right: 16px;

						.link {
							color: #006fcc;
							cursor: pointer;
						}

						.list-btn {
							width: 16px;
							height: 16px;
							vertical-align: text-bottom;
							margin-right: 5px;
						}
					}
					.ag-row:hover {
						background: #ffffff;
					}

					.ag-header-viewport {
						background-color: white;
					}

					.ag-body-viewport {
						overflow: hidden;
					}
					.severity-icon {
						vertical-align: text-top;
						margin-left: 5px;
						margin-right: 7px;
					}
				}
				.ag-status-bar {
					display: none;
				}

				.chart-title {
					background: #ffffff;
					font-size: 16px;
					line-height: 48px;
					height: 48px;
					padding-left: 16px;
					border-bottom: 1px solid #dadbdb;
				}

				.main,
				.supplemental {
					background: #ffffff;
					border-bottom-left-radius: 4px;
					border-bottom-right-radius: 4px;
					overflow: hidden;
					border-top: none;

					.ag-body-horizontal-scroll {
						display: none;
					}

					.ag-row:last-child {
						border-bottom: 0px;
					}

					.ag-row-odd {
						background-color: #f4f5f5;
					}

					.ag-row-even {
						background-color: white;
					}
				}

				.supplemental {
					border-top-left-radius: 4px;
					border-top-right-radius: 4px;
					border-top: 1px solid #dadbdb;
				}
			}
		}
	}
}
