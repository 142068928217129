#insights-app {
	.working-tab-body {
		.line-metric-details {
			.ag-root {
				border: 0px !important;
				.ag-header {
					height: 28px !important;
					min-height: 28px !important;
					border-bottom: 1px solid#DADBDB !important;
					.ag-header-cell {
						font-family: Lato;
						font-style: normal;
						font-weight: bold;
						font-size: 12px;
						color: #333333;

						padding-left: 16px;
						padding-right: 16px;

						height: 28px !important;
						background: #ffffff;
						&:last-child::after {
							display: none !important;
						}
						&:not(:last-child)::after {
							top: 0px;
							height: 28px;
							margin-top: 0px !important;
							background: #dadbdb !important;
						}
					}
				}
				.ag-cell {
					border: 0px;
					font-family: Lato;
					font-style: normal;
					font-weight: normal;
					font-size: 12px;
					color: #333333;
					line-height: 28px;
					padding-left: 16px;
					padding-right: 16px;

					.list-btn {
						width: 16px;
						height: 16px;
						vertical-align: text-bottom;
						margin-right: 5px;
					}
				}

				.ag-row:hover {
					background: #ffffff;
				}

				.ag-header-viewport {
					background-color: white;
				}

				.ag-body-viewport {
					overflow: hidden;
				}
				.severity-icon {
					vertical-align: text-top;
					margin-left: 5px;
					margin-right: 7px;
				}
			}
			.ag-status-bar {
				display: none;
			}

			.metric-details {
				height: 56px;
				border-top: 1px solid#DADBDB !important;
				border-bottom: 1px solid#DADBDB !important;

				.severity-icon {
					margin-left: 0px;
				}
			}
			.metric-desc {
				height: fit-content;
				padding: 16px 140px;
				background: #ffffff;
				border-bottom: 1px solid #dadbdb;

				> div:not(:last-child) {
					margin-bottom: 20px;
				}

				.title {
					text-transform: uppercase;
					height: 17px;
					font-weight: bold;
					font-size: 14px;
					line-height: 17px;

					letter-spacing: 0.12em;
					text-transform: uppercase;
				}

				.content {
					font-weight: normal;
					font-size: 12px;
					line-height: 20px;
					margin-top: 5px;

					* {
						margin-bottom: 0px;
					}

					ul {
						display: block;
						list-style-type: disc;
						margin-block-start: 0px;
						margin-block-end: 0px;
						margin-inline-start: 0px;
						margin-inline-end: 0px;
						padding-inline-start: 15px;
						padding-block-start: 0px;
						li {
							margin-top: 5px;
							p {
								margin-left: -5px;
							}
						}
					}
				}

				.related-metrics {
					.content {
						position: relative;
						left: -26px;
						width: calc(100% + 26px);
						.ag-header {
							min-height: 0px !important;
							height: 0px !important;
							display: none;
						}

						.ag-body-viewport {
							overflow-y: hidden;
							.ag-cell {
								padding: 0px;
							}
							.severity-icon {
								margin-left: 10px;
								margin-right: 10px;
							}
							.timestamp::after {
								position: relative;
								display: inline-block;
								content: '';
								height: 12px;
								width: 1px;
								background: #333333;
								left: 5px;
								top: 1px;
							}
							.ag-row {
								border: none;
							}

							.ag-row-odd {
								background-color: #ffffff;
							}

							.ag-row-even {
								background-color: #ffffff;
							}

							.checkbox-icon {
								vertical-align: text-top;
							}
						}

						&.show-scroll {
							.ag-body-viewport {
								overflow-y: scroll;
							}
							.ag-body-viewport-wrapper.ag-layout-normal {
								overflow-y: scroll;
							}
							::-webkit-scrollbar {
								-webkit-appearance: none;
								width: 4px;
								height: 8px;
							}
							::-webkit-scrollbar-thumb {
								border-radius: 4px;
								background-color: rgba(112, 119, 119, 0.7);
							}
						}

						.gray-out-metric {
							opacity: 0.5;
						}
					}
				}
			}
			.chart-info {
				display: flex;
				flex-direction: row;
				.chart-graph {
					height: fit-content;
					width: 75%;
					margin-top: 16px;
					margin-left: 16px;
					margin-right: 16px;
					margin-bottom: 16px;
					border-radius: 4px;
					background-color: white;
					overflow: hidden;
					border: 1px solid #dadbdb;

					.sync-chart {
						height: 100%;
						min-height: 150px;
						position: relative;

						.chart {
							&:not(:last-child) {
								border-bottom: 1px solid #dadbdb;
							}
						}
					}
				}
				.chart-misc {
					display: flex;
					flex-direction: column;
					width: 25%;
					margin-top: 16px;
					margin-right: 16px;
					background: #ffffff;
					border: 1px solid #dadbdb;
					border-radius: 4px;
					padding: 12px 16px;
					height: fit-content;

					display: flex;

					.item {
						display: inline-block;
						height: 100%;
						&:not(:first-child) {
							margin-top: 20px;
						}

						.title {
							font-weight: bold;
							font-size: 14px;
							color: #24292d;

							.misc-icon {
								vertical-align: text-bottom;
								margin-right: 5px;
							}
						}

						.content {
							margin-top: 8px;

							font-size: 12px;
							letter-spacing: 0.1px;
							color: #333333;

							overflow-wrap: break-word;
							p {
								display: block;
								margin-block-start: 0px;
								margin-block-end: 0px;
								margin-inline-start: 0px;
								margin-inline-end: 0px;
							}
							ol,
							ul {
								display: block;
								margin-block-start: 0px;
								margin-block-end: 0px;
								margin-inline-start: 0px;
								margin-inline-end: 0px;
								padding-inline-start: 15px;
								padding-block-start: 0px;
								li {
									margin: 5px 0px;
								}
							}
						}
					}
				}
			}
		}
	}
}
