.pan-drawer {
	@import './expVariables';
	&.export-panel,
	&.detail-panel {
		.pan-drawer-content-wrapper {
			border-radius: $drawer-border-radius;
			overflow: hidden;
			.pan-drawer-wrapper-body {
				display: flex;
				flex-direction: column;
				overflow: hidden;
				.pan-drawer-header,
				.pan-drawer-header-no-title {
					position: absolute;
					top: 0px;
					right: 0px;
					height: $drawer-header-height;
					width: 100%;
					padding: 0px;
					border-bottom: 1px solid $gray-pri-9;
					background-color: $table-head-bg;
					border-radius: 0px;

					.pan-drawer-title {
						height: $drawer-header-height;
						line-height: $drawer-header-height;
						padding-left: 15px;
						font-family: Lato;
						font-size: $text-lg;
						font-stretch: normal;
						font-style: normal;
						font-weight: 400;
						color: $dark-gray;
					}

					.pan-drawer-close {
						height: $drawer-header-height;
						line-height: $drawer-header-height;
						opacity: 0.6;

						.pan-drawer-clear-icon {
							display: inline-block;
							position: relative;
							height: $drawer-header-height;
							> img {
								position: absolute;
								top: 50%;
								transform: translateY(-50%);
							}
						}
					}
				}
				.pan-drawer-body {
					position: absolute;
					top: $drawer-header-height;
					right: 0px;
					width: 100%;
					height: calc(100vh - #{$drawer-header-height});
					overflow: hidden;
					padding-top: 0px;
					padding-bottom: 0px;
				}
			}
		}
	}

	&.detail-panel {
		.pan-drawer-content-wrapper {
			.pan-drawer-wrapper-body {
				.pan-drawer-body {
					padding: 0px 10px;
				}
			}
		}
	}

	&.export-panel {
		.pan-drawer-content-wrapper {
			.pan-drawer-wrapper-body {
				.pan-drawer-body {
					padding-top: 20px;
				}
			}
		}
	}
}

.sidebar .nav {
	padding: 0px;
}

#explore-logviewer {
	@import './expVariables';
	@import './gridWidget';
	@import './expGrid.scss';

	@keyframes loading-spinner {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}

	.loading-spinner {
		animation: loading-spinner 1s linear infinite;
		width: 16px;
		height: 16px;
		margin-right: 10px;
	}

	position: relative;

	//only import from _common others may not be needed now To do: check
	.btn-primary {
		background: linear-gradient(180deg, #2686cb 0.89%, #1f75b2 100.89%);
		background-repeat: no-repeat;
		border-radius: 4px;
		box-shadow: 1px 2px rgba(0, 0, 0, 0.1);
		border-color: $link-color;

		&.export-btn {
			background-image: url(~ui-lib/src/images/icon-export.svg), linear-gradient(180deg, #2686cb 0.89%, #1f75b2 100.89%);
			background-repeat: no-repeat;
			background-position: 20% center;
			box-shadow: none;
			width: 88px;
			height: 32px;
			padding-left: 26px;
		}

		&.cancel-btn {
			box-shadow: none;
		}

		&:hover {
			//border-color: transparent;
			box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
		}

		&:not(:disabled):not(.disabled):active,
		&:not(:disabled):not(.disabled).active {
			//border-color: transparent;
			box-shadow: none;
		}

		svg {
			fill: #fff;
		}
	}

	font-size: $text-sm;
	height: 100vh;
	width: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;

	.pan-calendar-picker-input.pan-input.pan-dropdown-trigger.time-range-picker {
		//to override too many important styles scattered
		border-right: 1px solid $light-gray !important;
		background: linear-gradient(180deg, #ffffff 0.89%, #fafafa 100.89%);
		border: 1px solid $light-gray;
		box-sizing: border-box;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
	}

	.pan-calendar-date-input-wrap .pan-calendar-input {
		font-size: $text-sm;
	}

	.pan-calendar {
		font-size: 12px;
		font-family: Lato, sans-serif;

		.pan-calendar-year-select,
		.pan-calendar-month-select,
		.pan-calendar-time-picker-btn,
		.pan-calendar-day-select.pan-calendar-time-status {
			color: $link-color;
		}
		.pan-calendar-selected-start-date .pan-calendar-date,
		.pan-calendar-end-date .pan-calendar-day,
		.pan-calendar-date-hover,
		.pan-calendar-selected-end-date .pan-calendar-date {
			background: $link-color;
		}
		//applying pri-button styles
		.pan-calendar-ok-btn.pan-calendar-ok-btn-disabled,
		.pan-calendar-ok-btn {
			background-image: linear-gradient(180deg, #2686cb 0.89%, #1f75b2 100.89%) !important;
			background-repeat: no-repeat;
			border-radius: 4px;
			box-shadow: 1px 2px rgba(0, 0, 0, 0.1);
			border-color: $link-color;
			font-size: $text-sm;

			&:hover {
				text-decoration: none;
			}
		}
		.pan-calendar-time-picker .pan-calendar-time-picker-select {
			font-size: $text-sm;
			font-family: Lato, sans-serif;
		}
	}

	.pan-ag-grid {
		height: calc(100vh - 145px);
		width: 100%;

		.ag-root {
			border: none;

			.ag-sort-descending-icon .ag-icon {
				color: $blue;
			}
		}

		.ag-status-bar {
			display: none;
		}
	}

	.info-area {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);

		& * {
			text-align: center;
		}
	}
}
