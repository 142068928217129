.comment-dropdown {
	z-index: 1200;
}

#alerts-app {
	.alerts-main {
		.alerts-table {
			.ag-theme-balham {
				.ag-row,
				.ag-cell {
					border: none;
				}
				.ag-pinned-right-header {
					border-left: none;
				}

				.ag-pinned-right-floating-bottom,
				.ag-pinned-right-cols-container {
					.ag-cell {
						border-left: none;
					}
				}
				.ag-pinned-right-header {
					.ag-header-row {
						.ag-header-cell:last-child {
							border-right: none !important;
						}
					}
				}
				.ag-header {
					.ag-header-cell {
						font-family: Lato;
						font-style: normal;
						font-weight: bold;
						font-size: 12px;
						color: #333333;
					}
					&.sticky {
						position: absolute;
						top: 56px;
						width: 100%;
						height: 28px !important;
						min-height: 28px !important;

						.ag-header-row {
							height: 28px !important;
						}
						background: #ffffff;
						border-bottom: 1px solid#DADBDB;
						.ag-header-cell {
							&:last-child {
								border-right: none;
							}
						}
					}
				}
				.ag-status-bar {
					display: none;
				}
				.ag-primary-cols-header-panel {
					.ag-icon {
						display: none !important;
					}
					.ag-input-wrapper {
						margin-left: 0px;
						margin-right: 7px;
					}
				}
				.ag-root {
					border-width: 0px;

					.ag-body-viewport {
						overflow: auto;
						border: none;
						.ag-cell {
							border: none;
							background-color: transparent;
						}
						.ag-row.ag-row-last {
							border-bottom: 1px solid #dadbdb;
						}
					}
					.ag-header {
						background-color: white;
						height: 28px !important;
						min-height: 28px !important;
						font-family: Lato;
						font-style: normal;
						font-weight: normal;
						font-size: 12px;
						color: #333333;

						.ag-header-row {
							height: 28px !important;
						}

						.ag-header-cell {
							&::after {
								display: none;
							}

							border-right: 1px solid#DADBDB;
						}
					}
					.ag-row {
						> .ag-react-container {
							height: 100%;
						}
					}
					.ag-cell {
						font-family: Lato;
						font-style: normal;
						font-weight: normal;
						letter-spacing: 0.1px;
						text-decoration-line: underline;
						cursor: default;
						border-right: none;
						color: #24292d;
						background-color: #eaebeb;
						text-decoration: none;

						.severity-cell {
							position: relative;
							cursor: pointer;
							.arrow-icon {
								transition: transform 0.3s;
								&.up {
									transform: rotate(180deg);
								}
							}
							.severity-icon {
								position: absolute;
								left: 26px;
								top: 6px;
							}
						}
						.alert-name-cell {
							color: #006fcc;
							cursor: pointer;
						}
						.hostname-cell {
							color: #006fcc;
							cursor: pointer;
						}
					}
				}
				.ag-cell {
					line-height: 28px !important;
				}
			}
		}
	}
}
