#insights-app {
	.working-tab-body {
		position: relative;
		height: 100vh;
		overflow: auto;
		&::-webkit-scrollbar {
			display: none;
		}
		-ms-overflow-style: none;
	}
}
