.page-header {
	width: 100%;
	background: #ffffff;
	top: 0px;
	position: sticky;
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
	z-index: 1035;
	font-family: Lato;

	> .nav {
		font-size: 12px;
		line-height: 20px;

		.parent {
			color: #006fcc;
			margin-right: 10px;
			cursor: pointer;
		}
		.current {
			color: #333333;
			margin-left: 10px;
		}
	}

	> .title {
		margin-top: 10px;
		font-weight: normal;
		font-size: 24px;
		line-height: 32px;
	}

	> .desc {
		margin-top: 5px;
		font-size: 12px;
		line-height: 16px;

		color: #707070;

		* {
			display: inline-block;
		}

		> div {
			height: 16px;
			.key {
				margin-right: 5px;
				font-weight: bold;
			}
			&:not(:last-child) {
				margin-right: 20px;
				&::after {
					display: inline-block;
					content: '';
					height: 12px;
					width: 1px;
					background: #707070;
					position: relative;
					top: 2px;
					left: 11px;
				}
			}
		}
	}
}
