#insights-app {
	.device-details {
		height: 100vh;
		overflow-x: hidden;
		overflow-y: scroll;

		.overview {
			display: flex;
			flex-direction: column;
			> .content {
				flex: 1;
				display: flex;
				flex-direction: column;
			}
		}

		.overview,
		.alert-timeline,
		.top-alert-types {
			height: 300px;
			> .title {
				height: 40px;
				border-bottom: 1px solid #dadbdb;
				font-size: 16px;
				font-weight: 500;
				line-height: 20px;
				padding: 10px 16px;
			}
			> .content {
				height: 260px;
			}
		}

		.overview,
		.alert-timeline,
		.top-alert-types {
			border: 1px solid #dadbdb;
			border-radius: 4px;
			margin: 8px;
			margin-bottom: 0px;
			background: white;
		}

		.top-alert-types {
			height: 210px;
			position: relative;
			border: 1px solid #dadbdb;
			border-radius: 4px;
			margin: 8px;
			background: white;
			.time-range {
				font-size: 12px;
				position: absolute;
				right: 16px;
			}
		}
	}
}
