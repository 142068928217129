@import "Constants";
@import "Mixins";

.#{$calendar-prefix-cls}-picker-container {
  @include reset;

  position: absolute;
  z-index: $zindex-picker;
  font-family: $font-family;

  &.slide-up-enter.slide-up-enter-active#{&}-placement-topLeft,
  &.slide-up-enter.slide-up-enter-active#{&}-placement-topRight,
  &.slide-up-appear.slide-up-appear-active#{&}-placement-topLeft,
  &.slide-up-appear.slide-up-appear-active#{&}-placement-topRight {
    animation-name: slideDownIn;
  }

  &.slide-up-enter.slide-up-enter-active#{&}-placement-bottomLeft,
  &.slide-up-enter.slide-up-enter-active#{&}-placement-bottomRight,
  &.slide-up-appear.slide-up-appear-active#{&}-placement-bottomLeft,
  &.slide-up-appear.slide-up-appear-active#{&}-placement-bottomRight {
    animation-name: slideUpIn;
  }

  &.slide-up-leave.slide-up-leave-active#{&}-placement-topLeft,
  &.slide-up-leave.slide-up-leave-active#{&}-placement-topRight {
    animation-name: slideDownOut;
  }

  &.slide-up-leave.slide-up-leave-active#{&}-placement-bottomLeft,
  &.slide-up-leave.slide-up-leave-active#{&}-placement-bottomRight {
    animation-name: slideUpOut;
  }
}

.#{$calendar-prefix-cls}-picker {
  @include reset;

  position: relative;
  display: inline-block;
  outline: none;
  cursor: text;
  transition: opacity 0.3s;

  &-input {
    outline: none;

    &.#{$pan-prefix}-input {
      line-height: $line-height-base;
    }
  }

  &-input.#{$pan-prefix}-input-sm {
    padding-top: 0;
    padding-bottom: 0;
  }

  &:hover &-input:not(.#{$pan-prefix}-input-disabled) {
    border-color: $input-hover-border-color;
  }

  &:focus &-input:not(.#{$pan-prefix}-input-disabled) {
    @include active();
  }

  &-clear,
  &-icon {
    position: absolute;
    top: 50%;
    right: $control-padding-horizontal;
    z-index: 1;
    width: 14px;
    height: 14px;
    margin-top: -7px;
    font-size: $font-size-sm;
    line-height: 14px;
    transition: all 0.3s;
    user-select: none;
  }

  &-clear {
    z-index: 2;
    color: $disabled-color;
    font-size: $font-size-base;
    background: $input-bg;
    cursor: pointer;
    opacity: 0;
    pointer-events: none;
    &:hover {
      color: $text-color-secondary;
    }
  }

  &:hover &-clear {
    opacity: 1;
    pointer-events: auto;
  }

  &-icon {
    display: inline-block;
    color: $disabled-color;
    font-size: $font-size-base;
    line-height: 1;
  }

  &-small &-clear,
  &-small &-icon {
    right: $control-padding-horizontal-sm;
  }
}
