#alerts-app {
	.alert-details {
		height: 100vh;
		.page-header {
			box-shadow: none;
		}
		.alert-table {
			border-top: 1px solid #dadbdb;
		}
		.chart-info {
			overflow: scroll;
			display: flex;
			flex-direction: row;
			background: #f4f5f5;
			.chart-graph {
				position: relative;
				min-height: 280px;
				height: fit-content;
				width: 75%;
				margin-top: 16px;
				margin-left: 16px;
				margin-right: 16px;
				margin-bottom: 16px;
				overflow: hidden;
				.table-chart {
					.chart {
						height: fit-content;
						min-height: 56px;
						position: relative;
						border: 1px solid #dadbdb;
						border-radius: 4px;
						background-color: white;
						overflow: hidden;
						&:not(:last-child) {
							margin-bottom: 16px;
						}
					}
				}
				.sync-chart {
					height: 100%;
					min-height: 280px;
					position: relative;
					border: 1px solid #dadbdb;
					border-radius: 4px;
					background-color: white;

					.chart {
						&:not(:last-child) {
							border-bottom: 1px solid #dadbdb;
						}
					}
				}
			}
			.chart-misc {
				display: flex;
				flex-direction: column;
				width: 25%;
				margin-top: 16px;
				margin-right: 16px;
				background: #ffffff;
				border: 1px solid #dadbdb;
				border-radius: 4px;
				padding: 12px 16px;
				height: fit-content;

				display: flex;

				.item {
					display: inline-block;
					height: 100%;
					&:not(:first-child) {
						margin-top: 20px;
					}

					.title {
						text-transform: uppercase;
						font-weight: bold;
						font-size: 14px;
						color: #24292d;

						.misc-icon {
							vertical-align: text-bottom;
							margin-right: 5px;
						}
					}

					.content {
						margin-top: 8px;

						font-size: 12px;
						letter-spacing: 0.1px;
						color: #333333;

						overflow-wrap: break-word;
						p {
							display: block;
							margin-block-start: 0px;
							margin-block-end: 0px;
							margin-inline-start: 0px;
							margin-inline-end: 0px;
						}
						ol,
						ul {
							display: block;
							margin-block-start: 0px;
							margin-block-end: 0px;
							margin-inline-start: 0px;
							margin-inline-end: 0px;
							padding-inline-start: 15px;
							padding-block-start: 0px;
							li {
								margin: 5px 0px;
							}
						}
					}
				}
			}
		}
	}
}
