//@import '../../../../styles/common.scss';

$sidebar-nav-dropdown-indicator-hover:      url(/static/media/chevron-down.034d8e64.svg);

.btn.btn-secondary.collapse-btn {
    outline: 0;
    border: 0;
    padding: 0;
    background:  0;
    height: unset;
    min-width: unset;
    color: #0ba4e8;
    font-size: 12px;

    &:active,
    &:not(:disabled):not(.disabled):active,
    &:hover,
    &:focus {
        color: #0a9cdd;
        outline: 0;
        border: 0;
        background: 0;
        text-decoration: underline;
        box-shadow: none;
    }

    &::after {
        content: '';
        background-image: $sidebar-nav-dropdown-indicator-hover;
        background-repeat: no-repeat;
        background-position: left;
        transition: transform .3s;
        width: 10px;
        height: 10px;
        margin-left: 4px;
        margin-top: 1px;
        position: absolute;
    }

    &.expanded::after {
        transform: rotate(-180deg);
    }
}