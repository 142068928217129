//@import '../../../../styles/common.scss';
@import '../../../../styles/variables.scss';
.checkbox-selection-header {
    text-align: center;
}

.form-group .pan-ag-grid .ag-theme-balham .pagination-bar {
    height: 34px;
    padding-top: 2px;
}

.pagination-row {
    display: flex;
    align-items: center;
}

.pagination-control {
    line-height: 14px;
    float: right;
    padding: 20px 10px;
    margin-left: auto;

    .btn {

        &.prev-page,
        &.next-page {
            border: 0;

            &.btn-secondary.disabled,
            &.btn-secondary:disabled {
                opacity: .5;
            }
        }

        + .btn {
            margin-left: 0;
        }
    }
}

.prev-page {
    background: url(/images/caret-left.png) 50% 50% no-repeat;
}

.next-page {
    background: url(/images/caret-right.png) 50% 50% no-repeat;
}

.pagination-control .btn-secondary.disabled:hover {
    background-color: transparent;
    border-color: transparent;
}

.pagination-control .btn-secondary:not(:disabled):not(.disabled):hover {
    background-color: #edeeee;
    border-color: #edeeee;
}

.pagination-control .btn-secondary:not(:disabled):not(.disabled):active,
.pagination-control .btn-secondary:not(:disabled):not(.disabled).active {
    background-color: #c8cbce;
    border-color: #c8cbce;
}

.pagination-control .page-input {
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
    text-align: center;
    width: auto;
    margin-left: 8px;
    margin-right: 8px;
}

.pagination-control .full-count {
    margin-right: 12px;
}

.pagination-bar {
    padding: 0 16px 2px 24px;
    z-index: 10000;
    /* box-shadow: 0 0 5px rgba(0, 0, 0, .3); */
    /* border-top: 1px solid darken(#dcdddc, 20%); */
    /* border-top: 1px solid #ccc; */
    display: flex;
    border-bottom: 0;
    font-size: 12px;
    .btn-export-csv{
        line-height: 24px;
        margin: 0 10px;
    }
}

.pagination-bar .items-area {
    /* border-right: 1px solid #ccc; */
    border-right: 0;
}

.pagination-bar .items-area + div:not(:empty) {
    padding-left: 12px;
    margin-left: 12px;

    &::before {
        content: '';
        display: inline-block;
        overflow: hidden;
        width: 1px;
        height: 16px;
        position: relative;
        top: 5px;
        left: -12px;
        border-right: 1px solid #a4a9ad;
    }
}

.editor-grid-builder .editor-grid .is-invalid .ag-root{
    border: 1px solid;
    border-color: $red;
}

/* Storybook css*/
.sb-show-main .pan-ag-grid {
    height: 250px;
}

.pan-ag-grid {
    
    height: 250px;
    width: 100%;

    .ag-ltr .ag-header-cell:not(:last-of-type)::after {
        border-right: 1px solid #49535b;
    }

    .ag-row-selected {
        background-color: #dfedf4 !important;
        border-top: 1px solid #a5e1ef;
        border-bottom: 1px solid #a5e1ef;
    }

    .ag-row-selected.ag-row-hover {
        box-shadow: none;
        border-bottom-color: #a5e1ef;
    }

    .ag-row-odd, .ag-row-odd.ag-row-hover {
        background-color: #eff6f8;
    }

    .ag-row-even, .ag-row-even.ag-row-hover { 
        background-color: #fff;
    }

    .ag-cell-range-selected-1:not(.ag-cell-focus) {
        background-color: transparent;
    }

    .pagination-bar {
        height: 36px;
    }

    .ag-row.disabled-row {
        font-style: italic;
        color: #91979c;
    
        a:not([href]):not([tabindex]) {
            font-style: italic;
            color: #91979c;
        }
    }

    .ag-ltr .ag-cell {
        border-right: 1px solid #edeeee;
    }

    .ag-row.ag-row-odd.inherited,
    .ag-row.ag-row-odd.inherited:not(.-padRow):hover {
        background: #fff7df;
    }

    .ag-row.ag-row-even.inherited, 
    .ag-row.ag-row-even.inherited:not(.-padRow):hover {
        background: #ffedbe;
    }
    .ag-cell.ag-cell-not-inline-editing.ag-cell-with-height.hover-over-bottom{
        border-bottom: 1px solid #000;
    }
    .ag-cell.ag-cell-not-inline-editing.ag-cell-with-height.hover-over-top{
        border-top: 1px solid #000;
    }

    .ag-cell img {
        width: 16px;
        height: 16px;
        margin-right: 3px;
    }

    .progress-bar {
        background-color: #63C2DE;
    }

    .ag-cell-wrapper {
        align-items: normal;
    }

    .pan-drag-checkbox-header{
        padding-left: 40px;
    }

    .ag-theme-balham .ag-cell{
        line-height: 24px;
        .css-151xaom-placeholder{
            top: 70%
        }
    }
}

.array-value-cell{
    height: 24px;
}