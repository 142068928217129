// -------- Animations -----------

@keyframes slideUpIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}

@keyframes slideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}

@keyframes slideDownIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}

@keyframes slideDownOut {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
}

// -------- Colors -----------
$primary-color: #1890ff;
$info-color: #1890ff;
$success-color: #52c41a;
$processing-color: #1890ff;
$error-color: #f5222d;
$highlight-color: #f5222d;
$warning-color: #faad14;
$normal-color: #d9d9d9;
$white: #fff;
$black: #000;

$background-color-light: #fafafa; // background of header and selected item
$background-color-base: #f5f5f5; // Default grey background color

// Color used by default to control hover and active backgrounds and for
// alert info backgrounds.
$primary-1: mix(white, $primary-color, 90%); // replace tint(@primary-color, 90%)
$primary-2: mix(white, $primary-color, 80%); // replace tint(@primary-color, 80%)
$primary-3: mix(white, $primary-color, 70%); // replace tint(@primary-color, 70%)
$primary-4: mix(white, $primary-color, 60%); // replace tint(@primary-color, 60%)
$primary-5: mix(white, $primary-color, 50%); // replace tint(@primary-color, 50%)
$primary-6: mix(white, $primary-color, 40%); // replace tint(@primary-color, 40%)
$primary-7: mix(white, $primary-color, 30%); // replace tint(@primary-color, 30%)

// Layout
$layout-body-background: #f0f2f5;
$layout-header-background: #001529;
$layout-footer-background: $layout-body-background;

// Base Scaffolding Variables
// ---

// Background color for `<body>`
$body-background: #fff;
// Base background color for most components
$component-background: #fff;
$font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB',
'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
'Segoe UI Emoji', 'Segoe UI Symbol';
$code-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
$text-color: rgba($black, 65%);
$text-color-secondary: rgba($black, 45%);
$text-color-inverse: $white;
$icon-color: inherit;
$icon-color-hover: rgba($black, 75%);
$heading-color: rgba(#000, 85%);
$heading-color-dark: rgba($white, 100%);
$text-color-dark: rgba($white, 85%);
$text-color-secondary-dark: rgba($white, 65%);
$text-selection-bg: $primary-color;
$font-variant-base: tabular-nums;
$font-feature-settings-base: 'tnum';
$font-size-base: 14px;
$font-size-lg: $font-size-base + 2px;
$font-size-sm: 12px;
$heading-1-size: ceil($font-size-base * 2.71);
$heading-2-size: ceil($font-size-base * 2.14);
$heading-3-size: ceil($font-size-base * 1.71);
$heading-4-size: ceil($font-size-base * 1.42);
$line-height-base: 1.5;
$border-radius-base: 4px;
$border-radius-sm: 2px;

// Border color
$border-color-base: #d9d9d9; // base border outline a component
$border-color-split: #e8e8e8; // split border inside a component
$border-color-inverse: $white;
$border-width-base: 1px; // width of the border for a component
$border-style-base: solid; // style of a components border

// Disabled states
$disabled-color: rgba(#000, 25%);
$disabled-bg: $background-color-base;
$disabled-color-dark: rgba(#fff, 35%);

// dark theme
$menu-dark-color: $text-color-secondary-dark;
$menu-dark-bg: $layout-header-background;
$menu-dark-arrow-color: #fff;
$menu-dark-submenu-bg: #000c17;
$menu-dark-highlight-color: #fff;
$menu-dark-item-active-bg: $primary-color;
$menu-dark-selected-item-icon-color: $white;
$menu-dark-selected-item-text-color: $white;
$menu-dark-item-hover-bg: transparent;

// Shadow
$shadow-color: rgba(0, 0, 0, 0.15);
$shadow-color-inverse: $component-background;
$shadow-1-up: 0 -2px 8px $shadow-color;
$shadow-1-down: 0 2px 8px $shadow-color;
$shadow-1-left: -2px 0 8px $shadow-color;
$shadow-1-right: 2px 0 8px $shadow-color;
$shadow-2: 0 4px 12px $shadow-color;
$box-shadow-base: $shadow-1-down;

// z-index list, order by `z-index`
$zindex-table-fixed: auto;
$zindex-affix: 10;
$zindex-back-top: 10;
$zindex-badge: 10;
$zindex-picker-panel: 10;
$zindex-popup-close: 10;
$zindex-modal: 1000;
$zindex-modal-mask: 1000;
$zindex-message: 1010;
$zindex-notification: 1010;
$zindex-popover: 1030;
$zindex-dropdown: 1050;
$zindex-picker: 1050;
$zindex-tooltip: 1060;

// ICONFONT
$iconfont-css-prefix: panicon;

// vertical padding
$padding-lg: 24px; // containers
$padding-md: 16px; // small containers and buttons
$padding-sm: 12px; // Form controls and items
$padding-xs: 8px; // small items

// vertical padding for all form controls
$control-padding-horizontal: $padding-sm;
$control-padding-horizontal-sm: $padding-xs;

//Dropdown
$dropdown-vertical-padding: 5px;
$dropdown-font-size: $font-size-base;
$dropdown-line-height: 22px;
$dropdown-selected-color: $primary-color;

// The background colors for active and hover states for things like
// list items or table cells.
$item-active-bg: $primary-1;
$item-hover-bg: $primary-1;

