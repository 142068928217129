.refresh-tab {
	cursor: pointer;
	position: absolute;
	height: 40px;
	width: 100%;
	bottom: 55px;
	left: 0px;

	.refresh-btn {
		font-family: Lato;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		letter-spacing: 0.2px;

		position: absolute;
		display: block;
		height: 40px;
		line-height: 40px;
		left: 0px;
		top: 0px;

		* {
			display: inline-block;
		}

		.icon {
			padding-left: 21px;
			padding-right: 8px;
		}
		&.stale {
			&::after {
				content: url(../../images/icon-stale-small.svg);
				display: block;
				height: 12px;
				width: 9px;
				position: absolute;
				right: -5px;
				top: 2px;
			}
		}
	}

	.refresh-info {
		position: absolute;
		top: 0px;
		right: 14px;
		width: 60px;
		height: 100%;

		.desc,
		.timestamp {
			text-align: right;
			font-family: Lato;
			font-style: normal;
			font-weight: normal;
			font-size: 10px;
			position: absolute;
			display: block;
			width: 100%;
			height: 10px;
		}

		.desc {
			top: 8px;
		}

		.timestamp {
			bottom: 10px;
		}

		&.stale {
			&::before {
				content: url(../../images/icon-stale.svg);
				display: block;
				height: 16px;
				width: 16px;
				position: absolute;
				left: -15px;
				top: 12px;
			}
			.desc,
			.timestamp {
				color: #ffcb06;
			}
		}
	}
}
