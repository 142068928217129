@import '../GridWidget/GridWidget.scss';

.pan-ag-grid.editor-grid {

    &.pagination-bar {
        border: 1px solid #a4a9ad;
        border-bottom-width: 0px;
    }

    .ag-cell.invalid-cell{
        border-bottom: 1px solid $red;
    }
}