.alert-name-popover.pan-popover {
	.pan-popover-inner-content {
		padding: 0px;
	}
	.alert {
		display: flex;
		flex-direction: row;
		color: black;
		height: 40px;
		width: 100%;
		padding: 12px 16px;
		margin: 0px;
		font-family: Lato;
		font-style: normal;
		&:not(:last-child) {
			border-bottom: 1px solid #dadbdb;
		}
		[class^='icon-'] {
			width: fit-content;
			margin-right: 8px;
		}
		.icon-critical {
			content: url(../../../../../images/icon-critical.svg);
		}
		.icon-warning {
			content: url(../../../../../images/icon-warning.svg);
		}
		.icon-healthy {
			content: url(../../../../../images/icon-healthy.svg);
		}
		.data {
			width: 100%;
			display: flex;
			flex-direction: column;
			.name {
				height: 12px;
				width: 100%;
				font-size: 12px;
				font-weight: 400;
				color: #006fcc;
				position: relative;
				top: -8px;
				cursor: pointer;
			}
			.time {
				height: 12px;
				width: 100%;
				font-weight: 300;
				font-size: 10px;
				position: relative;
				top: -2px;
			}
		}
	}
}

.top-alert-types {
	> .content {
		height: 170px;

		.top-list {
			font-family: Lato;
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			height: 167px;
			border-bottom-left-radius: 4px;
			border-bottom-right-radius: 4px;
			.ag-theme-balham {
				.ag-row,
				.ag-cell {
					border: none;
					.hits {
						font-weight: 600;
						font-size: 16px;
					}
					.alert-name {
						cursor: pointer;
						color: #006fcc;
					}
				}

				.ag-full-width-container {
					border-bottom: 1px solid #dadbdb;
				}

				.ag-pinned-right-header {
					border-left: none;
				}

				.ag-pinned-right-floating-bottom,
				.ag-pinned-right-cols-container {
					.ag-cell {
						border-left: none;
					}
				}
				.ag-pinned-right-header {
					.ag-header-row {
						.ag-header-cell:last-child {
							border-right: none !important;
						}
					}
				}
				.ag-header {
					.ag-header-cell {
						font-family: Lato;
						font-style: normal;
						font-weight: bold;
						font-size: 12px;
						color: #333333;
					}
				}
				.ag-header-cell-text {
					color: #333333;
				}

				.ag-root {
					border: none;
				}
				.ag-cell {
					background-color: white;
					text-decoration: none;
					cursor: default;
				}
				.ag-header {
					background: #ffffff;
					border-bottom: 1px solid#DADBDB;
					.ag-header-row {
						.ag-header-cell {
							&:last-child {
								border-right: none !important;
							}
						}
					}
				}

				.ag-body-viewport {
					overflow: auto;
					border: none;

					&::-webkit-scrollbar {
						display: none;
					}
					scrollbar-width: none;
					-ms-overflow-style: none;
					.ag-cell {
						border: none;
					}
					.ag-row.ag-row-last {
						border-bottom: none;
					}
					.ag-row-odd {
						.ag-cell {
							background-color: #f4f5f5;
						}
					}
					.ag-row-even {
						.ag-cell {
							background-color: white;
						}
					}
				}

				.ag-status-bar {
					display: none;
				}
				.ag-primary-cols-header-panel {
					.ag-icon {
						display: none !important;
					}
					.ag-input-wrapper {
						margin-left: 0px;
						margin-right: 7px;
					}
				}

				.ag-root {
					border-width: 0px;
					.ag-header {
						background-color: white;
						height: 28px !important;
						min-height: 28px !important;
						font-family: Lato;
						font-style: normal;
						font-weight: normal;
						font-size: 12px;
						color: #333333;

						.ag-header-row {
							height: 28px !important;
						}

						.ag-header-cell {
							&::after {
								display: none;
							}

							border-right: 1px solid#DADBDB;
						}
					}
				}
			}
		}
	}
}
