@import 'Constants';

$pan-prefix: pan;
$input-affix-width: 19px;
$input-affix-with-clear-btn-width: 38px;

@mixin reset{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: $text-color;
  font-size: $font-size-base;
  font-variant: $font-variant-base;
  line-height: $line-height-base;
  list-style: none;
  font-feature-settings: $font-feature-settings-base;
}

@mixin clearfix() {
  zoom: 1;
  &::before,
  &::after {
    display: table;
    content: '';
  }
  &::after {
    clear: both;
  }
}

@mixin selection__clear() {
  position: absolute;
  top: 50%;
  right: $control-padding-horizontal - 1px;
  z-index: 1;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: $disabled-color;
  font-size: $font-size-sm;
  font-style: normal;
  line-height: 12px;
  text-align: center;
  text-transform: none;
  background: $component-background;
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease, opacity 0.15s ease;
  text-rendering: auto;
  &::before {
    display: block;
  }
  &:hover {
    color: $text-color-secondary;
  }
}

// Placeholder text
@mixin placeholder($color: $input-placeholder-color) {
  // Firefox
  &::-moz-placeholder {
    color: $color;
    opacity: 1; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
  }
  // Internet Explorer 10+
  &:-ms-input-placeholder {
    color: $color;
  }
  // Safari and Chrome
  &::-webkit-input-placeholder {
    color: $color;
  }

  &:placeholder-shown {
    text-overflow: ellipsis;
  }
}

// size mixins for input
@mixin input-lg() {
  height: $input-height-lg;
  padding: $input-padding-vertical-lg $input-padding-horizontal-lg;
  font-size: $font-size-lg;
}

@mixin input-sm() {
  height: $input-height-sm;
  padding: $input-padding-vertical-sm $input-padding-horizontal-sm;
}

// input status
// == when focus or active
@mixin active($color: $outline-color) {
  border-color: mix(white, $color, 50%);
  border-right-width: $border-width-base !important;
  outline: none;
  // box-shadow: $input-outline-offset $outline-blur-size $outline-width rgba($color, 20%);
}

// == when hover
@mixin hover($color: $input-hover-border-color) {
  border-color: $color;
  border-right-width: $border-width-base !important;
}

@mixin disabled() {
  color: $disabled-color;
  background-color: $input-disabled-bg;
  cursor: not-allowed;
  opacity: 1;

  &:hover {
    @include hover($input-border-color);
  }
}

// Basic style for input
@mixin input() {
  position: relative;
  display: inline-block;
  width: 100%;
  height: $input-height-base;
  padding: $input-padding-vertical-base $input-padding-horizontal-base;
  color: $input-color;
  font-size: $font-size-base;
  line-height: $line-height-base;
  background-color: $input-bg;
  background-image: none;
  border: $border-width-base $border-style-base $input-border-color;
  border-radius: $border-radius-base;
  transition: all 0.3s;
  @include placeholder(); // Reset placeholder

  &:hover {
    @include hover();
  }

  &:focus {
    @include active();
  }

  &-disabled {
    @include disabled();
  }

  &[disabled] {
    @include disabled();
  }

  // Reset height for `textarea`s
  textarea#{&} {
    max-width: 100%; // prevent textearea resize from coming out of its container
    height: auto;
    min-height: $input-height-base;
    line-height: $line-height-base;
    vertical-align: bottom;
    transition: all 0.3s, height 0s;
  }

  // Size
  &-lg {
    @include input-lg();
  }

  &-sm {
    @include input-sm();
  }
}

// label input
@mixin input-group($inputClass) {
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  // Undo padding and float of grid classes
  &[class*='col-'] {
    float: none;
    padding-right: 0;
    padding-left: 0;
  }

  > [class*='col-'] {
    padding-right: 8px;

    &:last-child {
      padding-right: 0;
    }
  }

  &-addon,
  &-wrap,
  > .#{$inputClass} {
    display: table-cell;

    &:not(:first-child):not(:last-child) {
      border-radius: 0;
    }
  }

  &-addon,
  &-wrap {
    width: 1px; // To make addon/wrap as small as possible
    white-space: nowrap;
    vertical-align: middle;
  }

  &-wrap > * {
    display: block !important;
  }

  .#{$inputClass} {
    float: left;
    width: 100%;
    margin-bottom: 0;
    text-align: inherit;

    &:focus {
      z-index: 1; // Fix https://gw.alipayobjects.com/zos/rmsportal/DHNpoqfMXSfrSnlZvhsJ.png
      border-right-width: 1px;
    }

    &:hover {
      z-index: 1;
      border-right-width: 1px;
    }
  }

  &-addon {
    position: relative;
    padding: 0 $input-padding-horizontal-base;
    color: $input-color;
    font-weight: normal;
    font-size: $font-size-base;
    text-align: center;
    background-color: $input-addon-bg;
    border: $border-width-base $border-style-base $input-border-color;
    border-radius: $border-radius-base;
    transition: all 0.3s;

    // Reset Select's style in addon
    .#{$pan-prefix}-select {
      margin: -($input-padding-vertical-base + 1px) (-$input-padding-horizontal-base);

      .#{$pan-prefix}-select-selection {
        margin: -1px;
        background-color: inherit;
        border: $border-width-base $border-style-base transparent;
        box-shadow: none;
      }

      &-open,
      &-focused {
        .#{$pan-prefix}-select-selection {
          color: $primary-color;
        }
      }
    }

    // Expand addon icon click area
    > i:only-child::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: '';
    }
  }

  // Reset rounded corners
  > .#{$inputClass}:first-child,
  &-addon:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    // Reset Select's style in addon
    .#{$pan-prefix}-select .#{$pan-prefix}-select-selection {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  > .#{$inputClass}-affix-wrapper {
    &:not(:first-child) .#{$inputClass} {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(:last-child) .#{$inputClass} {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &-addon:first-child {
    border-right: 0;
  }

  &-addon:last-child {
    border-left: 0;
  }

  > .#{$inputClass}:last-child,
  &-addon:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    // Reset Select's style in addon
    .#{$pan-prefix}-select .#{$pan-prefix}-select-selection {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  // Sizing options
  &-lg .#{$inputClass},
  &-lg > &-addon {
    @include input-lg();
  }

  &-sm .#{$inputClass},
  &-sm > &-addon {
    @include input-sm();
  }

  &-lg .#{$pan-prefix}-select-selection--single {
    height: $input-height-lg;
  }

  &-sm .#{$pan-prefix}-select-selection--single {
    height: $input-height-sm;
  }

  .#{$inputClass}-affix-wrapper {
    display: table-cell;
    float: left;
    width: 100%;
  }

  #{&}#{&}-compact {
    display: block;
    @include clearfix;

    &-addon,
    &-wrap,
    > .#{$inputClass} {
      &:not(:first-child):not(:last-child) {
        border-right-width: $border-width-base;

        &:hover {
          z-index: 1;
        }

        &:focus {
          z-index: 1;
        }
      }
    }

    & > * {
      display: inline-block;
      float: none;
      vertical-align: top;
      border-radius: 0;
    }

    & > *:not(:last-child) {
      margin-right: -$border-width-base;
      border-right-width: $border-width-base;
    }

    // Undo float for .pan-input-group .pan-input
    .#{$inputClass} {
      float: none;
    }

    // reset border for Select, DatePicker, AutoComplete, Cascader, Mention, TimePicker
    & > .#{$pan-prefix}-select > .#{$pan-prefix}-select-selection,
    & > .#{$pan-prefix}-calendar-picker .#{$pan-prefix}-input,
    & > .#{$pan-prefix}-select-auto-complete .#{$pan-prefix}-input,
    & > .#{$pan-prefix}-cascader-picker .#{$pan-prefix}-input,
    & > .#{$pan-prefix}-mention-wrapper .#{$pan-prefix}-mention-editor,
    & > .#{$pan-prefix}-time-picker .#{$pan-prefix}-time-picker-input {
      border-right-width: $border-width-base;
      border-radius: 0;

      &:hover {
        z-index: 1;
      }

      &:focus {
        z-index: 1;
      }
    }

    & > *:first-child,
    & > .#{$pan-prefix}-select:first-child > .#{$pan-prefix}-select-selection,
    & > .#{$pan-prefix}-calendar-picker:first-child .#{$pan-prefix}-input,
    & > .#{$pan-prefix}-select-auto-complete:first-child .#{$pan-prefix}-input,
    & > .#{$pan-prefix}-cascader-picker:first-child .#{$pan-prefix}-input,
    & > .#{$pan-prefix}-mention-wrapper:first-child .#{$pan-prefix}-mention-editor,
    & > .#{$pan-prefix}-time-picker:first-child .#{$pan-prefix}-time-picker-input {
      border-top-left-radius: $border-radius-base;
      border-bottom-left-radius: $border-radius-base;
    }

    & > *:last-child,
    & > .#{$pan-prefix}-select:last-child > .#{$pan-prefix}-select-selection,
    & > .#{$pan-prefix}-calendar-picker:last-child .#{$pan-prefix}-input,
    & > .#{$pan-prefix}-select-auto-complete:last-child .#{$pan-prefix}-input,
    & > .#{$pan-prefix}-cascader-picker:last-child .#{$pan-prefix}-input,
    & > .#{$pan-prefix}-cascader-picker-focused:last-child .#{$pan-prefix}-input,
    & > .#{$pan-prefix}-mention-wrapper:last-child .#{$pan-prefix}-mention-editor,
    & > .#{$pan-prefix}-time-picker:last-child .#{$pan-prefix}-time-picker-input {
      border-right-width: $border-width-base;
      border-top-right-radius: $border-radius-base;
      border-bottom-right-radius: $border-radius-base;
    }

    & > .#{$pan-prefix}-select-auto-complete .#{$pan-prefix}-input {
      vertical-align: top;
    }
  }
}

@mixin input-affix-wrapper($inputClass) {
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: start;

  &:hover .#{$inputClass}:not(.#{$inputClass}-disabled) {
    @include hover();
  }

  .#{$inputClass} {
    position: relative;
    text-align: inherit;
  }

  // Should not break align of icon & text
  .#{$inputClass}-prefix,
  .#{$inputClass}-suffix {
    position: absolute;
    top: 50%;
    z-index: 2;
    display: flex;
    align-items: center;
    color: $input-color;
    line-height: 0;
    transform: translateY(-50%);

    :not(.panicon) {
      line-height: $line-height-base;
    }
  }

  .#{$inputClass}-disabled ~ .#{$inputClass}-suffix {
    .panicon {
      color: $disabled-color;
      cursor: not-allowed;
    }
  }

  .#{$inputClass}-prefix {
    left: $input-padding-horizontal-base + 1px;
  }

  .#{$inputClass}-suffix {
    right: $input-padding-horizontal-base + 1px;
  }

  .#{$inputClass}:not(:first-child) {
    padding-left: $input-padding-horizontal-base + $input-affix-width;
  }

  .#{$inputClass}:not(:last-child) {
    padding-right: $input-padding-horizontal-base + $input-affix-width;
  }

  &.#{$inputClass}-affix-wrapper-input-with-clear-btn .#{$inputClass}:not(:last-child) {
    padding-right: $input-padding-horizontal-base + $input-affix-with-clear-btn-width;
  }

  &.#{$inputClass}-affix-wrapper-textarea-with-clear-btn .#{$inputClass} {
    padding-right: 22px;
  }
}

@mixin clear-icon() {
  color: $disabled-color;
  font-size: $font-size-sm;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: $text-color-secondary;
  }

  &:active {
    color: $text-color;
  }

  + i {
    margin-left: 6px;
  }
}

@mixin iconfont-mixin() {
  display: inline-block;
  color: $icon-color;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em; // for SVG icon, see https://blog.prototypr.io/align-svg-icons-to-text-and-say-goodbye-to-font-icons-d44b3d7b26b4
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  > * {
    line-height: 1;
  }

  svg {
    display: inline-block;
  }

  &::before {
    display: none; // dont display old icon.
  }

  & &-icon {
    display: block;
  }
}

// for iconfont font size
// fix chrome 12px bug, support ie
@mixin iconfont-size-under-12px($size, $rotate: 0deg) {
  display: inline-block;
  $font-scale: unit($size / 12px);

  font-size: 12px;
  // IE9
  font-size: '#{$size} \9';
  transform: scale($font-scale) rotate($rotate);
  :root & {
    font-size: $font-size-sm; // reset IE9 and above
  }
}