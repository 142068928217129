.autocompletewidget-wrapper {
    display: block;
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    bottom: auto;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    width: 100%;
    font-size: 12px;
    list-style: none;
    padding: 1px;
    position: absolute;
    text-align: left;
    z-index: 100;
    overflow: auto;
}

.autocompletewidget-list>li {
    cursor: pointer;
    padding-left: 10px;
    min-width: 100px;
}

.autocompletewidget-list>li:hover {
    background-color: #337ab7;
    color: #fff;
}

.autocompletewidget-condition {
    padding-left: 10px;
    padding-top: 10px;
    .form-control {
        display: inline;
    }
    .autocompletewidget-not-condition {
        display: inline;
        .form-check {
            display: inline;
            margin-left: 20px;
            .form-check-input {
                margin-top: 3px
            }
        }
    }
}
