#insights-app {
	.device-details {
		.metrics-table {
			background: white;
			height: calc(100vh - 123px);

			> .summary {
				padding: 10px 0px;
				height: 40px;
				font-size: 16px;
				line-height: 20px;
				color: #707070;

				.item {
					padding: 0px 12px;
					&:not(:last-child) {
						border-right: 1px solid #707070;
					}
					[class^='icon'] {
						position: relative;
						top: -2px;
						margin-right: 5px;
					}
					.count {
						margin-right: 3px;
					}
				}
			}

			> .filter-builder {
				width: 100%;
				height: 48px;
				background: #eaebeb;
				position: relative;
				border-bottom: 1px solid #dadbdb;
				.filter-icon {
					position: absolute;
					top: 16px;
					left: 12px;
				}
				.log-query-wrapper {
					position: absolute;
					left: 35px;
					top: 8px;
					right: 16px;
					.log-query-completion-dropdown {
						min-width: 800px !important;
						width: 50% !important;
						.pan-recycle-select-dropdown-menu-item.pan-recycle-select-dropdown-menu-item-active {
							background-color: #e6f5fc;
						}
					}
				}
			}
			> .device-metrics-table {
				height: calc(100vh - 211px);
			}
		}
	}
}
