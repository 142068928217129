// Variables overrides
//
// Root override file. Sets some core values so that Bootstrap can use them while setting other variables.


// Colors

$blue: #0ba4e8;
$green: #3f9b6f;
// $red: #e15050;
$red: #d94949;

$gray-medium: #7f868c; // Used for form labels and some subheads

$theme-colors: (secondary: #a4a9ad);

$border-color-default: #e8e8e8;
$border-color-darker: #ccc;
/* $border-color: #dadcde; */
$border-color: #d4d4d4;

$hover-bg: rgba(0, 0, 0, .05);
$hover-bg: lighten(#0ba4e8, 50%);
$hover-bg: rgba(11, 164, 323, .06);


// Body

$body-bg: #fff;
$body-color: #1d2124;
$page-title-color: #0e1012; // Is this intentionally slightly different than the body color?

$enable-shadows: true;

$base-margin: 12px;

$border-radius: 4px;


// Fonts

$font-family-sans-serif: 'Lato', sans-serif;

$text-lg: 17px;
$text-med: 14px;
$text-sm: 12px;

$text-muted: #49535b;

$h1-font-size: 1.7rem;
$h2-font-size: 1.3rem;
$headings-margin-bottom: 0;
$font-size-sm: 300;


// Links

$link-color: $blue;
$link-hover-color: #0993d1;


// Spacing

$grid-gutter-width-base: 15px;
$grid-gutter-width-lg: 30px;


// Navs

$nav-bar-height: 50px;
$navbar-padding-y: 0;
$navbar-padding-x: 0;
$nav-link-padding: .8em 1em; // Is this getting used?

$navbar-dark-color: rgba(255, 255, 255, .8);
$navbar-dark-hover-color: rgba(255, 255, 255, 1);
$navbar-dark-active-color: rgba(255, 255, 255, 1);

$nav-pills-link-active-color: $body-color;
$nav-pills-link-active-bg: rgba(0, 0, 0, .1);
$nav-pills-border-radius: 3em;


// Buttons

$btn-height: 30px;
$btn-border-radius: 5rem;
$btn-border-radius-sm: 5rem;
$btn-border-radius-lg: 5rem;
$btn-border-width: 1px;
$btn-padding-x: 1rem;
$btn-padding-y: .5rem;
// $btn-padding-x: 16px; // [awong] The units need to be rem, not px
// $btn-padding-y: 10px; // [awong] The units need to be rem, not px
$btn-gray: $body-bg;

$btn-height-sm: 24px;
$btn-height-lg: 44px;

$btn-sm-padding-x: 10px;
$btn-sm-padding-y: 5px;

$btn-lg-padding-x: 20px;
$btn-lg-padding-y: 12px;

$input-btn-padding-y-lg: .7rem;
$input-btn-padding-x-lg: 1.4rem;
$input-btn-line-height-lg: 1.5;

$btn-transition: all .15s ease-in-out;


// Cards

$card-cap-bg: #f2f2f2;
$card-spacer-x: 1.28rem;
$card-spacer-y: 1.128rem;
// $card-spacer-x: 18px; // [awong] The units need to be rem, not px
// $card-spacer-y: 18px; // [awong] The units need to be rem, not px
// $card-border-color: #dadcde;
$card-border-color: $border-color;
$card-cap-bg: #fff;


// Forms

// $input-border-radius: 4px;
$input-border-radius: 2px;
$input-rl-padding: 10px;

$input-border-radius-sm: 4px;
$input-rl-padding-sm: 8px;

$input-border-color: #d4d4d4;
$input-focus-border-color: $blue;

$form-check-input-gutter: 20px;

$custom-select-border-width: 1px;
$custom-select-bg: none;
$custom-select-border-radius: 30px;
$custom-select-indicator: url(../images/caret-down.png);


// Modals

$modal-content-border-width: 0;
$modal-backdrop-bg: #000;
$modal-backdrop-opacity: .5;
$modal-footer-border-width: 0;
$modal-inner-padding: 24px;
// $modal-header-padding: 24px;
$modal-header-padding: 12px 24px;
$modal-header-bg-color: #f6f7f7;


// Tables

// $table-cell-padding: 14px;
$table-cell-padding: 10px;
$table-border-color: #efeff0;
$table-bg-accent: rgba(0, 0, 0, .03);
$table-hover-bg: rgba(0, 0, 0, .02);
$table-head-bg: #fff;
$row-hover-color: rgba(11, 164, 232, .08);

// $table-accent-bg: #f8fcfd;
// $table-accent-bg: rgba(11, 164, 232, .03);
$table-accent-bg: rgba($blue, .07);

$table-head-bg: #f4f5f5;
$table-head-color: #49535b;

$table-hover-bg: #fff;

$active-row: #d8e8e8;

$border-h-color: #eaf1f4;
$border-v-color: #e4e4e4;

$table-border-color-h: #edeeee;
$table-border-color-v: #dadcde;


// Dropdowns

// $dropdown-border-color: #dadcde;
$dropdown-border-color: $border-color;
$dropdown-box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
$dropdown-divider-bg: $border-color-default;
$dropdown-header-color: $blue;
$dropdown-item-padding-y: .5rem;
$dropdown-item-padding-x: .5rem;
// $dropdown-divider-bg: #dadcde;
$dropdown-divider-bg: $border-color;

// $dropdown-link-hover-bg: $hover-bg;

$dropdown-link-hover-bg: rgba($blue, 0.06);

// $dropdown-link-active-color: #fff;
// $dropdown-link-active-bg: rgba(11, 164, 232, .12);

$dropdown-link-active-color: $body-color;
$dropdown-link-active-bg: rgba($blue, .12);


// Tabs
$nav-tabs-link-active-bg: #fff;
$nav-tabs-border-radius: 0;
$nav-tabs-link-hover-border-color: transparent;
$nav-tabs-border-width: 0;


// Badges

$badge-font-weight: normal;
$badge-pill-border-radius: 2em;
$badge-padding-y: .3em;
$badge-padding-x: .4em;
$badge-pill-padding-x: .6em;


// Alerts
// Define alert colors, border radius, and padding.

$alert-padding-y: 1.25rem;
$alert-padding-x: 2rem;
$alert-margin-bottom: 1rem;
$alert-border-radius: 0;
$alert-link-font-weight: normal;
$alert-border-width: 0;


// Tooltips

$tooltip-font-size: 12px;
$tooltip-bg: $body-color;
$tooltip-opacity: 1;
$tooltip-padding-y: 4px;
$tooltip-padding-x: 8px;

$tooltip-arrow-width: 10px;
$tooltip-arrow-height: 6px;
