#insights-app {
	.insights-main {
		background: #ffffff;
		height: 100vh;
		overflow: hidden;
		> .insights-header {
			height: 65px;
			padding: 16px;

			font-weight: 300;
			font-size: 24px;
			line-height: 32px;

			box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
		}

		> .health-stat {
			display: flex;
			position: relative;
			flex-direction: row;
			justify-content: center;
			height: 128px;
			border-bottom: 1px solid #dadbdb;
			.stat-box {
				width: 164px;
				height: 78px;
				margin-top: 25px;
				position: relative;
				&:not(:last-of-type) {
					border-right: 1px solid #b8b8b8;
				}

				.stat-category {
					font-weight: 300;
					font-size: 18px;
					display: block;
					width: 100%;
					text-align: center;
					margin-top: 10px;
				}
				.stat-value {
					display: block;

					font-weight: 300;
					font-size: 48px;
					line-height: 40px;

					width: 100%;
					text-align: center;
				}
				.stat-icon {
					position: absolute;
					top: 55px;
					left: 29px;
				}
			}
		}

		> .grid {
			// hide scroll
			&::-webkit-scrollbar {
				display: none;
			}
			scrollbar-width: none;
			-ms-overflow-style: none;

			height: calc(100vh - 225px);
			overflow: auto;
			> .hex-grid {
				overflow: hidden;
				position: relative;
				height: 425px;
			}
			> .table-grid {
				overflow: hidden;
				.filter-and-config {
					position: relative;
					> .filter-builder {
						width: 100%;
						height: 48px;
						background: #eaebeb;
						position: relative;
						border-bottom: 1px solid #dadbdb;
						.filter-icon {
							position: absolute;
							top: 16px;
							left: 12px;
						}
						.log-query-wrapper {
							position: absolute;
							left: 35px;
							top: 8px;
							right: 16px;
							.log-query-completion-dropdown {
								min-width: 800px !important;
								width: 50% !important;
								.pan-recycle-select-dropdown-menu-item.pan-recycle-select-dropdown-menu-item-active {
									background-color: #e6f5fc;
								}
							}
						}
					}
				}

				.log-table {
					.hidden-row {
						display: none;
					}

					position: relative;
					height: calc(100vh - 273px);
					> .outer-grid {
						height: calc(100vh - 303px);
						position: relative;
						.ag-theme-balham {
							.ag-row,
							.ag-cell {
								border: none;
							}

							.ag-body-viewport {
								&::-webkit-scrollbar {
									display: none;
								}
								scrollbar-width: none;
								-ms-overflow-style: none;
							}
						}
						.ag-header-cell-text {
							color: #333333;
						}

						.master-detail-grid {
							overflow: hidden;
							height: 100% !important;
							&.sticky-mode {
								height: calc(100% + 28px) !important;
							}

							.ag-floating-bottom {
								height: 28px !important;
								transform: translateY(calc(331px - 100vh));

								&::-webkit-scrollbar {
									display: none;
								}
								scrollbar-width: none;
								-ms-overflow-style: none;
							}
						}
					}
					> .grid-footer {
						.info-area {
							top: 50%;
						}

						position: absolute;
						bottom: 0px;
						left: 0px;
						width: 100%;

						user-select: none;
						height: 30px;
						background: #eaebeb;

						display: flex;
						justify-content: flex-end;

						font-size: 12px;

						.prev {
							padding-left: 10px;
							cursor: pointer;
						}
						.next {
							padding-left: 5px;
							cursor: pointer;
						}

						> .config-page-size {
							height: 20px;
							line-height: 20px;
							margin-top: 5px;
							border-right: 1px solid grey;
							padding: 0px 10px;
							> .dropdown .btn {
								display: inline-block;
								position: relative;
								border: 1px solid #c8cbce;
								box-sizing: border-box;
								border-radius: 4px;
								height: 20px;
								width: 40px;
								min-width: 0px;
								padding: 0px 0px 0px 5px;
								margin-left: 5px;
								background: #edeeee;
								font-size: 12px;

								&:focus {
									box-shadow: none;
								}

								&.dropdown-toggle:after {
									display: inline;
									position: relative;
									display: inline;
									position: relative;
									margin: 0px;
									padding: 0px;
									vertical-align: baseline;
									right: -3px;
									top: -2px;
								}
							}
						}
						> .config-page-number {
							height: 20px;
							line-height: 20px;
							margin-top: 5px;
							padding: 0px 10px;
							> #page-number,
							#page-number:disabled {
								display: inline-block;
								width: 40px;
								height: 20px;
								padding: 0px 0px 0px 5px;
								margin: 0px 5px;
								background: #edeeee;
								border: 1px solid #c8cbce;
							}
						}
					}
				}
			}
		}
	}
}
