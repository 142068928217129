.status-cell {
	position: relative;
	cursor: pointer;
	.arrow-icon {
		position: absolute;
		right: 4px;
		top: 12px;
		transition: transform 0.3s;
		&.up {
			transform: rotate(180deg);
		}
	}
}

.status-dropdown {
	overflow: hidden;
	border-radius: 4px;
	box-shadow: none;

	&::before {
		display: none;
	}

	.status-dropdown-overlay {
		background: white;
		border: 1px solid #dadbdb;
		border-radius: 4px;
		overflow: hidden;
		.item {
			height: 30px;
			line-height: 30px;
			font-size: 12px;
			padding-left: 16px;
			&:not(:last-child) {
				border-bottom: 1px solid #dadbdb;
			}

			&.active {
				font-weight: bold;
			}

			&:hover {
				background: #eff7ff;
			}
		}
	}
}
