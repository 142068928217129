//@import '../../../../styles/common.scss';
.errortab {
    border-bottom: 1px solid red;
}

.row {

    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6 {

        .nav-tabs {
            border-bottom: 0;

            .nav-link {
                padding: 10px;
            }

            .nav-item:after {
                border-right: 0;
            }
        }
    }
}

.tab-content .tab-pane {
    padding: 8px 0;

    .btn-sm,
    .btn-group-sm > .btn {
        padding: 4px 10px 6px;
    }

    .row:not(:last-child) .row-builder.form-group {
        margin-bottom: 8px;
    }

    .row:first-child:last-child .form-group:first-child:last-child,
    .row:last-child .form-group:last-child,
    > .form-group:first-child:last-child,
    > .form-group:last-child {
        margin-bottom: 0;
    }
}

.vertical-tabs.row {
    margin-left: 0;
    margin-right: 0;

    .tab-content > .tab-pane {
        padding: 8px 16px 16px;

        .tab-content .tab-pane {
            padding: 8px 0 0;
        }
    }

    > .col-sm-2 {
        padding: 0;

        > .nav-tabs {

            .nav-item {

                .nav-link {
                    border-bottom: 3px solid transparent;
                    padding: 8px 12px 2px;

                    &.active {
                        border-color: #0ba4e8;
                    }
                }
                
                &:after {
                    display: none;
                }
            }
        }
    }

    > .col {
        padding-left: 0;
        padding-right: 0;

        > .tab-content {
            margin: 0;
            border-width: 0 0 0 1px;
            min-height: 500px;
        }
    }
}

.nav.nav-tabs {
    flex-wrap: nowrap;

    + .tab-content {
        padding-top: 4px;
    }
}

.tab-content .card {
    border: 0;
}

.nav-tabs + .tab-content {
    border-width: 1px 0 0;
}

/*
.tab-content .tab-pane.active {
    padding-left: 0;
}
*/