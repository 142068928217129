.nav-item {
	position: relative;
	font-family: Lato;
	font-style: normal;
	font-weight: normal;
	color: #ffffff;

	a,
	a:hover {
		color: #ffffff;
		text-decoration: none;
	}

	.nav-item-icon {
		display: block;
		position: relative;
		height: 40px;
		border-left: 4px solid #19222e;
		cursor: pointer;
		&:hover {
			background: #354252;
			border-left: 4px solid #354252;
		}
		&.active {
			background: #475566;
			border-left: 4px solid #ffcb06;
			&.side-menu-open {
				background: #19222e;
				border-left: 4px solid #19222e;
			}
		}
		> img {
			position: absolute;
			top: 11.5px;
			right: 23px;
		}
	}
	.sub-items {
		background: #19222e;
		.sub-item {
			display: block;
			position: relative;
			height: 32px;
			font-size: 12px;
			line-height: 32px;
			border-left: 4px solid #19222e;
			cursor: pointer;
			&:hover {
				background: #354252;
				border-left: 4px solid #354252;
			}
			&.active {
				background: #475566;
				border-left: 4px solid #ffcb06;
			}

			a {
				display: block;
				height: inherit;
			}
			* {
				display: inline-block;
			}
			.sub-item-name {
				position: absolute;
				left: 44px;
				top: 0px;
				max-width: 185px;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}
			.sub-item-close {
				position: absolute;
				top: 0px;
				right: 8px;
				height: 32px;
				width: 9px;
				> img {
					position: absolute;
					top: 11.5px;
				}
			}
			&:first-of-type {
				height: 40px;
				font-size: 14px;
				line-height: 40px;
				.sub-item-icon {
					position: relative;
					height: 40px;

					> img {
						position: absolute;
						top: 11.5px;
						left: 16px;
					}
				}
				.sub-item-name {
					left: 40px;
				}
			}
		}

		&.side-menu {
			position: absolute;
			top: 0px;
			left: 69px;
			width: 250px;
			border-radius: 4px;
			overflow: hidden;
		}
	}
}
