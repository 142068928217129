.list-container{
    height: 433px;
    .operation-buttons{
        display: flex;
        position: absolute; 
        left: 520px; 
        top:23px;
    }
    .text-info-check{
        font-size: 19px;
        color:#ababab; 
        margin:1.5em 2.5em 3.5em;
    }
    .selectArea{
        margin: 0 2.9em;
        font-size: 15px;
    }
}
.checkbox-groups{
    display: flex;
    margin: 0 2em;
    .checkbox-col{
        padding: 1em 1em;
    }
    .areas{
        margin: 0 1.5em;
        font-weight: bold;
    }
    .checkbox-groups-colums{
        display: flex; 
        flex-direction: column;   
        margin: 0 1em;    
        .sub-select-all{
            padding: 0 1em;
            input{
                margin-right: 4px;
            }
        }
        .checkbox-groups-item-container{
            margin-top:1em;
            border-top: 1px solid #c2c5c7; 
            max-height: 500px;
            overflow: auto;
            padding: 1em;
        }
        input{
            max-width: 220px;
        }
    }
}