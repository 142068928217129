@import '../../styles/variables';

.widget-container {
    border: 1px solid $border-color;
    border-radius: 4px;

    &::before {
        content: ''""'';
        position: absolute;
        height: 1px;
        z-index: 10;
        left: 4px;
        top: -1px;
        width: calc(100% - 8px);
        background: linear-gradient(to right, rgba(218, 220, 222, 0), #0ba4e8, rgba(218, 220, 222, 0));
    }
}

.grid-layout {
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.react-grid-item.react-grid-placeholder {
    background: none;
    border: 2px dashed #0a9cdd;
    opacity: .5;
    border-radius: 8px;
}