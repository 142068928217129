.alert-detail-cell {
	overflow-wrap: break-word;
	white-space: normal;

	border-top: 1px solid #dadbdb;
	border-bottom: 1px solid #dadbdb;

	font-family: Lato;
	font-style: normal;
	font-weight: normal;
	color: #333333;

	.alert-desc {
		height: fit-content;
		padding: 16px 140px;
		background: #ffffff;
		border-bottom: 1px solid #dadbdb;

		> div:not(:last-child) {
			margin-bottom: 20px;
		}

		.title {
			height: 17px;
			font-weight: bold;
			font-size: 14px;
			line-height: 17px;

			letter-spacing: 0.12em;
			text-transform: uppercase;
		}

		.content {
			font-weight: normal;
			font-size: 12px;
			line-height: 20px;
			margin-top: 5px;
			position: relative;

			* {
				margin-bottom: 0px;
			}

			ul {
				display: block;
				list-style-type: disc;
				margin-block-start: 0px;
				margin-block-end: 0px;
				margin-inline-start: 0px;
				margin-inline-end: 0px;
				padding-inline-start: 15px;
				padding-block-start: 0px;
				li {
					margin-top: 5px;
					p {
						margin-left: -5px;
					}
				}
			}

			.event-row {
				height: 28px;
				line-height: 24px;
				position: relative;

				.prefix-icon {
					position: relative;
					top: -2px;
				}

				.time {
					position: absolute;
					left: 30px;
					&::after {
						content: '';
						display: inline-block;
						width: 1px;
						height: 12px;
						background: #333333;
						position: relative;
						left: 10px;
						top: 2px;
					}
				}

				.text {
					position: absolute;
					left: 180px;
					&.metric {
						color: #006fcc;
						cursor: pointer;
					}
				}

				.modify-icon,
				.delete-icon {
					opacity: 0;
					transition: opacity 0.3s;
					margin-left: 10px;
					cursor: pointer;
				}
			}

			.event-row:hover {
				.modify-icon,
				.delete-icon {
					opacity: 1;
				}
			}

			&.loading {
				height: 60px;
			}
		}
	}
}
