@import "Constants";

$pan-prefix: pan;

@mixin reset{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: $text-color;
  font-size: $font-size-base;
  font-variant: $font-variant-base;
  line-height: $line-height-base;
  list-style: none;
  font-feature-settings: $font-feature-settings-base;
}

@mixin iconfont-size-under-12px($size, $rotate: 0deg) {
  display: inline-block;
  $font-scale: unit($size / 12px);

  font-size: 12px;
  // IE9
  font-size: '#{$size} \9';
  transform: scale($font-scale) rotate($rotate);
  :root & {
    font-size: $font-size-sm; // reset IE9 and above
  }
}