#explore-accviewer {
	.custom-tooltip.usercontext-popover.pan-popover {
		width: 100%;
		height: 100%;
		color: inherit;
		line-height: inherit;
		position: inherit;
		transform: inherit;
		opacity: inherit;
		overflow: inherit;
		z-index: inherit;
		font-size: inherit;
		background-color: inherit;
		pointer-events: inherit;
		cursor: inherit;
	}
	.custom-tooltip.usercontext-popover.pan-popover::before {
		display: none;
	}
	height: 100vh;
	overflow-x: auto;
	display: flex;
	align-items: stretch;

	.pan-spin {
		width: 100%;
		position: relative;
	}

	.ag-theme-balham {
		*::before {
			content: '' !important;
		}
		*::after {
			content: '' !important;
		}
	}

	.details-wrapper {
		background-color: transparent;
		grid-gap: 10px;
		grid-template-columns: 1fr 1fr;
		margin: 20px auto 20px auto;

		.details-box {
			.ag-body-horizontal-scroll {
				display: none;
			}
		}
	}
}
