.alert-timeline {
	position: relative;
	> .title {
		position: relative;
		.summary {
			display: inline-block;
			width: fit-content;
			font-size: 16px;
			line-height: 20px;
			color: #707070;

			.item {
				padding: 0px 12px;
				&:first-child {
					padding-left: 0px;
				}
				&:not(:last-child) {
					border-right: 1px solid #707070;
				}
				[class^='icon'] {
					position: relative;
					top: -2px;
					margin-right: 5px;
				}
				.count {
					margin-right: 3px;
				}
			}
		}

		.list-view {
			position: absolute;
			right: 42px;
			top: 8px;
		}

		.chart-view {
			position: absolute;
			right: 10px;
			top: 8px;
		}
		.time-range-picker {
			position: absolute;
			top: 8px;
			right: 75px;

			.time-range-select {
				.ant-select-arrow {
					top: 12px;
					right: 12px;
					* {
						display: inline-block;
					}
				}
				&.ant-select-dropdown {
					border-radius: 4px;
					padding: 0px;
					z-index: 3;

					.ant-select-item-option {
						font-family: Lato;
						font-style: normal;
						font-weight: bold;
						font-size: 12px;
						height: 32px;
						line-height: 32px;
						padding: 0px;

						&-content {
							padding-left: 16px;
							height: 32px;
							line-height: 32px;
						}

						&-active,
						&-selected {
							background: #eff7ff;
						}
					}
				}

				&.ant-select-selector {
					box-shadow: none !important;
					border-radius: 4px;
					border: 1px solid #dadbdb;
					.ant-select-selection-item {
						color: #006fcc;
						padding: 0px;
						font-family: Lato;
						font-style: normal;
						font-weight: normal;
						font-size: 12px;
						line-height: 24px;
						&::before {
							content: url(../../../../../images/icon-calendar.svg);
							margin-right: 8px;
							position: relative;
							top: 1px;
						}
					}
				}
			}
		}
	}

	> .content {
		position: relative;
		font-family: Lato;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		box-sizing: content-box;

		.start-time,
		.end-time {
			position: absolute;
			bottom: 20px;
			z-index: 3;
			.time {
				font-weight: 300;
				font-size: 10px;
			}
		}

		.start-time {
			left: 50px;
		}
		.end-time {
			right: 230px;
		}
	}
}
