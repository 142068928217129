//@import '../../../../styles/common.scss';

.schemaform-loading {
    // display: inline-block;
    // font: normal normal normal 14px/1 FontAwesome;
    // animation: fa-spin 2s infinite linear;
}

.schemaform-loading:before {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    animation: fa-spin 2s infinite linear;
    // position: absolute;
    content: "\f110";
    // width: 100%;
    // height: 100%;
    // z-index: 1;
    // background-image: url(/images/logo-pan-white.png);
    // background-repeat: no-repeat;
    // background-color: #cccccc;
    // background-size: 20px 20px;
    // background-position: center;
    // opacity: 0.5;
}

.btnContainer {
    padding: 24px 16px 16px;
    /* bottom: 0; */
    border-top: 1px solid #dadada;
    /* position: absolute; */
    /* width: 796px; */
}

.hlgrid-form .btnContainer {
    padding: 12px 0 24px;
}

.workflow-stage .hlgrid-form .btnContainer {
    margin: 0 16px 0 24px;
}
