@import "Constants";

$pan-prefix: pan;
$calendar-prefix-cls: '#{$pan-prefix}-calendar';
$calendar-timepicker-prefix-cls: '#{$pan-prefix}-calendar-time-picker';

@mixin reset{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: $text-color;
  font-size: $font-size-base;
  font-variant: $font-variant-base;
  line-height: $line-height-base;
  list-style: none;
  font-feature-settings: $font-feature-settings-base;
}

// input status
// == when focus or active
@mixin active($color: $outline-color) {
  border-color: mix(white, $color, 50%);
  border-right-width: $border-width-base !important;
  outline: none;
  // box-shadow: $input-outline-offset $outline-blur-size $outline-width rgba($color, 20%);
}

// == when hover
@mixin hover($color: $input-hover-border-color) {
  border-color: $color;
  border-right-width: $border-width-base !important;
}

@mixin disabled() {
  color: $disabled-color;
  background-color: $input-disabled-bg;
  cursor: not-allowed;
  opacity: 1;

  &:hover {
    @include hover($input-border-color);
  }
}

// size mixins for input
@mixin input-lg() {
  height: $input-height-lg;
  padding: $input-padding-vertical-lg $input-padding-horizontal-lg;
  font-size: $font-size-lg;
}

@mixin input-sm() {
  height: $input-height-sm;
  padding: $input-padding-vertical-sm $input-padding-horizontal-sm;
}

// Basic style for input
@mixin input() {
  position: relative;
  display: inline-block;
  width: 100%;
  height: $input-height-base;
  padding: $input-padding-vertical-base $input-padding-horizontal-base;
  color: $input-color;
  font-size: $font-size-base;
  line-height: $line-height-base;
  background-color: $input-bg;
  background-image: none;
  border: $border-width-base $border-style-base $input-border-color;
  border-radius: $border-radius-base;
  transition: all 0.3s;
  @include placeholder(); // Reset placeholder

  &:hover {
    @include hover();
  }

  &:focus {
    @include active();
  }

  &-disabled {
    @include disabled();
  }

  &[disabled] {
    @include disabled();
  }

  // Reset height for `textarea`s
  textarea#{&} {
    max-width: 100%; // prevent textearea resize from coming out of its container
    height: auto;
    min-height: $input-height-base;
    line-height: $line-height-base;
    vertical-align: bottom;
    transition: all 0.3s, height 0s;
  }

  // Size
  &-lg {
    @include input-lg();
  }

  &-sm {
    @include input-sm();
  }
}


@mixin calendarLeftArrow() {
  height: 100%;

  &::before,
  &::after {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 8px;
    height: 8px;
    vertical-align: middle;
    border: 0 solid #aaa;
    border-width: 1.5px 0 0 1.5px;
    border-radius: 1px;
    transform: rotate(-45deg) scale(0.8);
    transition: all 0.3s;
    content: '';
  }

  &:hover::before,
  &:hover::after {
    border-color: $text-color;
  }

  &::after {
    display: none;
  }
}

@mixin calendarLeftDoubleArrow() {
  @include calendarLeftArrow;

  &::after {
    position: relative;
    left: -3px;
    display: inline-block;
  }
}

@mixin calendarRightArrow() {
  @include calendarLeftArrow;

  &::before,
  &::after {
    transform: rotate(135deg) scale(0.8);
  }
}

@mixin calendarRightDoubleArrow() {
  @include calendarRightArrow;

  &::before {
    position: relative;
    left: 3px;
  }

  &::after {
    display: inline-block;
  }
}

@mixin calendarPanelHeader($calendar-prefix-cls) {
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: $border-width-base $border-style-base $border-color-split;
  user-select: none;

  a:hover {
    color: $link-hover-color;
  }

  .#{$calendar-prefix-cls}-century-select,
  .#{$calendar-prefix-cls}-decade-select,
  .#{$calendar-prefix-cls}-year-select,
  .#{$calendar-prefix-cls}-month-select {
    display: inline-block;
    padding: 0 2px;
    color: $heading-color;
    font-weight: 500;
    line-height: 40px;
  }

  .#{$calendar-prefix-cls}-century-select-arrow,
  .#{$calendar-prefix-cls}-decade-select-arrow,
  .#{$calendar-prefix-cls}-year-select-arrow,
  .#{$calendar-prefix-cls}-month-select-arrow {
    display: none;
  }

  .#{$calendar-prefix-cls}-prev-century-btn,
  .#{$calendar-prefix-cls}-next-century-btn,
  .#{$calendar-prefix-cls}-prev-decade-btn,
  .#{$calendar-prefix-cls}-next-decade-btn,
  .#{$calendar-prefix-cls}-prev-month-btn,
  .#{$calendar-prefix-cls}-next-month-btn,
  .#{$calendar-prefix-cls}-prev-year-btn,
  .#{$calendar-prefix-cls}-next-year-btn {
    position: absolute;
    top: 0;
    display: inline-block;
    padding: 0 5px;
    color: $text-color-secondary;
    font-size: 16px;
    font-family: Arial, 'Hiragino Sans GB', 'Microsoft Yahei', 'Microsoft Sans Serif', sans-serif;
    line-height: 40px;
  }

  .#{$calendar-prefix-cls}-prev-century-btn,
  .#{$calendar-prefix-cls}-prev-decade-btn,
  .#{$calendar-prefix-cls}-prev-year-btn {
    left: 7px;
    @include calendarLeftDoubleArrow;
  }

  .#{$calendar-prefix-cls}-next-century-btn,
  .#{$calendar-prefix-cls}-next-decade-btn,
  .#{$calendar-prefix-cls}-next-year-btn {
    right: 7px;
    @include calendarRightDoubleArrow;
  }

  .#{$calendar-prefix-cls}-prev-month-btn {
    left: 29px;
    @include calendarLeftArrow;
  }

  .#{$calendar-prefix-cls}-next-month-btn {
    right: 29px;
    @include calendarRightArrow;
  }
}

@mixin calendar-selected-cell() {
  .#{$calendar-prefix-cls}-date {
    color: $text-color-inverse;
    background: $primary-color;
    border: $border-width-base $border-style-base transparent;

    &:hover {
      background: $primary-color;
    }
  }
}

// Compatibility for browsers.

// Placeholder text
@mixin placeholder($color: $input-placeholder-color) {
  // Firefox
  &::-moz-placeholder {
    color: $color;
    opacity: 1; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
  }
  // Internet Explorer 10+
  &:-ms-input-placeholder {
    color: $color;
  }
  // Safari and Chrome
  &::-webkit-input-placeholder {
    color: $color;
  }

  &:placeholder-shown {
    text-overflow: ellipsis;
  }
}

// Base styles of buttons
// --------------------------------------------------
@mixin button-color($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;
  // a inside Button which only work in Chrome
  // http://stackoverflow.com/a/17253457
  > a:only-child {
    color: currentColor;
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: transparent;
      content: '';
    }
  }
}

@mixin button-size($height,  $padding, $font-size, $border-radius) {
  height: $height;
  padding: $padding;
  font-size: $font-size;
  border-radius: $border-radius;
}

@mixin button-disabled($color: $btn-disable-color, $background: $btn-disable-bg, $border: $btn-disable-border) {
  &-disabled,
  &.disabled,
  &[disabled] {
    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      @include button-color($color, $background, $border);

      text-shadow: none;
      box-shadow: none;
    }
  }
}

@mixin button-variant-primary($color, $background) {
  @include button-color($color, $background, $background);

  text-shadow: $btn-text-shadow;
  box-shadow: $btn-primary-shadow;

  &:hover,
  &:focus {
    @include button-color($color, mix(white, $background, 50%), mix(white, $background, 50%));
  }

  &:active,
  &.active {
    @include button-color($color, mix(white, $background, 70%), mix(white, $background, 70%));
  }

  @include button-disabled();
}

@mixin button-variant-other($color, $background, $border) {
  @include button-color($color, $background, $border);

  &:hover,
  &:focus {
    @include button-color($color, mix(white, $background, 50%), mix(white, $background, 50%));
  }
  &:active,
  &.active {
    @include button-color($color, mix(white, $background, 70%), mix(white, $background, 70%));
  }
  @include button-disabled();
}

@mixin btn() {
  position: relative;
  display: inline-block;
  font-weight: $btn-font-weight;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: $btn-border-width $btn-border-style transparent;
  box-shadow: $btn-shadow;
  cursor: pointer;
  transition: all 0.3s $ease-in-out;
  user-select: none;
  touch-action: manipulation;
  @include button-size($btn-height-base, $btn-padding-base, $font-size-base, $btn-border-radius-base);
  > .#{$iconfont-css-prefix} {
    line-height: 1;
  }
  &,
  &:active,
  &:focus {
    outline: 0;
  }
  &:not([disabled]):hover {
    text-decoration: none;
  }
  &:not([disabled]):active {
    outline: 0;
    box-shadow: none;
  }
  &.disabled,
  &[disabled] {
    cursor: not-allowed;
    > * {
      pointer-events: none;
    }
  }
  &-lg {
    @include button-size($btn-height-lg, $btn-padding-lg, $btn-font-size-lg, $btn-border-radius-base);
  }
  &-sm {
    @include button-size($btn-height-sm, $btn-padding-sm, $btn-font-size-sm, $btn-border-radius-sm);
  }
}
// primary button style
@mixin btn-primary() {
  @include button-variant-primary($btn-primary-color, $btn-primary-bg);
}
// default button style
@mixin btn-default() {
  @include button-variant-other($btn-default-color, $btn-default-bg, $btn-default-border);
  &:hover,
  &:focus,
  &:active,
  &.active {
    text-decoration: none;
    background: $btn-default-bg;
  }
}

