#alerts-app {
	.alerts-notifications {
		background: white;
		// background: #f4f5f5;
		.notifications-summary {
			height: 42px;
			position: relative;
			border-bottom: 1px solid #dadbdb;
			.notifications-stat {
				position: absolute;
				top: 10px;
				.total-count,
				.enabled-count {
					color: #707070;
					font-size: 16px;
					display: inline;
					padding: 0px 16px;
				}

				.total-count {
					border-right: 1px solid #707070;
				}
			}

			.add-rule-btn,
			.disable-rule-btn {
				cursor: pointer;
				font-size: 12px;
				background-color: #006fcc;
				color: white;
				display: inline-block;
				position: absolute;
				top: 9px;
				height: 24px;
				border: 1px solid #dadbdb;
				box-sizing: border-box;
				border-radius: 4px;
				> img {
					position: absolute;
					left: 10px;
					top: 5px;
				}
				> .text {
					position: absolute;
					line-height: 16px;
					top: 3px;
				}
			}

			.add-rule-btn {
				width: 155px;
				right: 16px;
				> .text {
					left: 28px;
				}
			}
		}

		.notifications-table {
			height: calc(100vh - 155px);
		}
	}
}
