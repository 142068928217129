.notifications-drawer {
	@keyframes loading-spinner {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}

	.loading-spinner {
		animation: loading-spinner 1s linear infinite;
	}
	.ant-drawer-content-wrapper {
		.ant-drawer-body {
			.servicenow-config {
				.account-setup {
					.btns {
						height: 45px;
						.test-result {
							position: absolute;
							left: 80px;
							top: 8px;
							.text {
								position: relative;
								left: 5px;
								top: 1px;
							}
						}
						.test-btn,
						.cancel-btn,
						.next-btn {
							display: grid;
							justify-content: center;
							align-content: center;
							width: 70px;
							height: 24px;
							position: absolute;
							top: 6px;
							bottom: 15px;
							cursor: pointer;

							box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
							border-radius: 4px;
						}
						.next-btn {
							right: 0px;
							background: #006fcc;
							color: white;
							&.disabled {
								background-color: #b8b8b8;
							}
						}

						.cancel-btn {
							right: 106px;
							border: 1px solid #dadbdb;
							background: white;
							color: #006fcc;
						}

						.test-btn {
							left: 0px;
							border: 1px solid #dadbdb;
							background: white;
							color: #006fcc;
						}
					}
				}
			}
		}
	}
}
