.alerts-drawer {
	.ant-drawer-content-wrapper {
		.ant-drawer-header {
			background: #f4f5f5;
			height: 50px;
			font-weight: normal;
			font-size: 16px;
			border-bottom: 1px solid #dadbdb;
		}

		.ant-drawer-body {
			padding: 10px 16px;
			font-size: 12px;
			.input-item {
				height: 90px;
				.title {
					margin: 4px 0px;
				}
				.input {
					.ant-radio-wrapper {
						margin-right: 16px;
						input[type='radio'] {
							cursor: pointer;
							outline: none;
							appearance: none;
							position: relative;
							top: 2px;
							margin-right: 8px;
							content: url(../../../../../images//icon-radio-off.svg);

							&:checked {
								outline: none;
								content: url(../../../../../images//icon-radio-on.svg);
							}
						}
					}
					.input-error {
						color: red;
						position: relative;
						top: 2px;
						font-size: 10px;
					}
				}
			}
			.count {
				padding-bottom: 10px;
				border-bottom: 1px solid #dadbdb;
			}
			.btns {
				position: relative;
				.cancel-btn,
				.export-btn {
					display: grid;
					justify-content: center;
					align-content: center;
					width: 105px;
					height: 24px;
					position: absolute;
					top: 15px;
					cursor: pointer;

					box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
					border-radius: 4px;
				}
				.export-btn {
					right: 0px;
					background: #006fcc;
					color: white;
				}

				.cancel-btn {
					right: 120px;
					border: 1px solid #dadbdb;
					background: white;
					color: #006fcc;
				}
			}
		}
	}
}
