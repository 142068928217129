@import './expVariables';
.checkbox-selection-header {
	text-align: center;
}

.form-group .pan-ag-grid .ag-theme-balham .pagination-bar {
	height: 34px;
	padding-top: 2px;
}

.pagination-row {
	display: flex;
	align-items: center;
}

.pagination-control {
	line-height: 14px;
	float: right;
	padding: 20px 10px;
	margin-left: auto;

	.btn {
		&.prev-page,
		&.next-page {
			border: 0;

			&.btn-secondary.disabled,
			&.btn-secondary:disabled {
				opacity: 0.5;
			}
		}

		+ .btn {
			margin-left: 0;
		}
	}
}

// .prev-page {
//     background: url(/images/caret-left.png) 50% 50% no-repeat;
// }

// .next-page {
//     background: url(/images/caret-right.png) 50% 50% no-repeat;
// }

.pagination-control .btn-secondary.disabled:hover {
	background-color: transparent;
	border-color: transparent;
}

.pagination-control .btn-secondary:not(:disabled):not(.disabled):hover {
	background-color: #edeeee;
	border-color: #edeeee;
}

.pagination-control .btn-secondary:not(:disabled):not(.disabled):active,
.pagination-control .btn-secondary:not(:disabled):not(.disabled).active {
	background-color: #c8cbce;
	border-color: #c8cbce;
}

.pagination-control .page-input {
	padding-left: 5px;
	padding-right: 5px;
	display: inline-block;
	text-align: center;
	width: auto;
	margin-left: 8px;
	margin-right: 8px;
}

.pagination-control .full-count {
	margin-right: 12px;
}

.pagination-bar {
	padding: 0 16px 2px 16px;
	z-index: 10000;
	/* box-shadow: 0 0 5px rgba(0, 0, 0, .3); */
	/* border-top: 1px solid darken(#dcdddc, 20%); */
	/* border-top: 1px solid #ccc; */
	display: flex;
	border-bottom: 0;
	font-size: 12px;
	display: none;
}

.pagination-bar .items-area {
	/* border-right: 1px solid #ccc; */
	border-right: 0;
	font-size: $text-lg;
	display: inline;
}

.pagination-bar .items-area + div:not(:empty) {
	padding-left: 12px;
	margin-left: 12px;
	position: absolute;
	right: 32px;

	&::before {
		content: '';
		display: inline-block;
		overflow: hidden;
		width: 1px;
		height: 16px;
		position: relative;
		top: 5px;
		left: -12px;
		border-right: none;
	}
}

.editor-grid-builder .editor-grid .is-invalid .ag-root {
	border: 1px solid;
	border-color: $red;
}

/* Storybook css*/
.sb-show-main .pan-ag-grid {
	height: 250px;
}

.pan-ag-grid {
	height: 250px;
	width: 100%;

	.ag-row {
		//border-color: transparent;
	}
	//separators for grid headers
	.ag-ltr .ag-header-cell:not(:last-of-type)::after {
		border-right: 1px solid $ag-header-separator;
	}

	.ag-row-selected {
		background-color: $ag-row-selected-bg !important;
		border-top: none;
		border-bottom: none;
		border-top-style: none;
	}

	.ag-row-selected.ag-row-hover {
		box-shadow: none;
		border-bottom-color: #a5e1ef;
	}

	.ag-row-odd,
	.ag-row-odd.ag-row-hover {
		background-color: $ag-row-odd-bg;
	}

	.ag-row-even,
	.ag-row-even.ag-row-hover {
		background-color: $ag-row-even-bg;
	}

	.ag-cell-range-selected-1:not(.ag-cell-focus) {
		background-color: transparent;
	}

	.pagination-bar {
		height: 45px;
	}

	.ag-row.disabled-row {
		font-style: italic;
		color: #91979c;

		a:not([href]):not([tabindex]) {
			font-style: italic;
			color: #91979c;
		}
	}

	//separators for grid row cells
	.ag-ltr .ag-cell {
		border-right: none;
	}

	.ag-ltr .ag-cell.custom-cell-left-border {
		border-left: 1px solid $ag-group-value;
		//pl. don't remove these needed for clean unbroken lines
		border-bottom: none;
		border-top-style: none;
		border-top-width: 0;
		border-bottom-width: 0;
	}

	.ag-ltr .ag-cell.custom-cell-right-border {
		border-right: 1px solid $ag-group-value;
		//pl. don't remove these needed for clean unbroken lines
		border-bottom: none;
		border-top-style: none;
		border-top-width: 0;
		border-bottom-width: 0;
	}

	.ag-theme-balham .ag-header-cell.custom-header-left-border {
		border-left: 1px solid $ag-group-value;
	}

	.ag-header-cell:not(:last-of-type).custom-header-right-border:after {
		border-right: 1px solid $ag-group-value;
	}

	.ag-header-cell:not(:last-of-type).custom-header-suppress-right:after {
		border-right-color: transparent;
		background: transparent;
	}

	.ag-row.ag-row-odd.inherited,
	.ag-row.ag-row-odd.inherited:not(.-padRow):hover {
		background: rgba($color: $ag-inherited-row, $alpha: 0.2);
	}

	.ag-row.ag-row-even.inherited,
	.ag-row.ag-row-even.inherited:not(.-padRow):hover {
		background: rgba($color: $ag-inherited-row, $alpha: 0.08);
	}

	.ag-cell.ag-cell-not-inline-editing.ag-cell-with-height.hover-over-bottom {
		border-bottom: 1px solid #000;
	}

	.ag-cell.ag-cell-not-inline-editing.ag-cell-with-height.hover-over-top {
		border-top: 1px solid #000;
	}

	.ag-cell img {
		width: 16px;
		height: 16px;
		margin-right: 3px;
	}

	.progress {
		background-color: #63c2de;
	}

	.ag-cell-wrapper {
		align-items: normal;
	}

	.pan-drag-checkbox-header {
		padding-left: 40px;
	}

	.ag-theme-balham {
		.ag-row:not(.ag-row-first) {
			border-top-style: none;
		}

		//don't show move icons for the column headers grouped and move suppressed
		.suppress-movable .ag-icon-menu:before {
			content: '';
		}

		//dark border for row group separators
		.ag-row:not(:first-child) > .ag-cell-wrapper {
			border-top: 2px solid $link-color;
		}

		font-family: Lato, sans-serif;

		.ag-row.ag-row-last:not(div:first-child) {
			border-bottom-style: none;
		}

		.ag-header {
			background-color: $ag-header-bg;

			.ag-header-row.ag-header-row-column {
				color: $ag-header-text;
			}

			.ag-header-group-cell-label {
				justify-content: center;
			}
		}

		//this is for row groupings header eg. pre-rules, post-rules
		.ag-cell-wrapper {
			span.ag-group-value {
				color: $ag-group-value-row;
				font-weight: 600;
			}
		}

		.ag-cell {
			line-height: 24px;
			.css-151xaom-placeholder {
				top: 70%;
			}
		}

		.ag-row.ag-row-selected:not(.ag-row-first) {
			border-top-style: none;
		}

		.ag-root-wrapper {
			border-radius: 0px;
		}

		//separators between grid group header columns
		.ag-header-cell::after,
		.ag-header-group-cell::after {
			height: 100%;
			top: 0%;
			background-color: $ag-header-separator;
		}

		//override for the special border columns
		.ag-header-group-cell.custom-header-left-border {
			border-left: 1px solid $ag-group-value;
		}

		.ag-header-group-cell.custom-header-right-border {
			border-right: 1px solid $ag-group-value;

			&:after {
				background-color: transparent;
			}
		}

		//don't display border if there is no label for group (new specs)
		.ag-header-group-cell.ag-header-group-cell-no-group::after {
			background-color: transparent;
		}
	}

	div.ag-row-group,
	.ag-row-pinned {
		line-height: 32px;
		background: $ag-row-group-bg;
		box-shadow: inset 0px 1px 0px #edeeee;
		&:hover {
			background: $ag-row-group-bg-hover;
		}
		//this seems to be for the inherited header
		&.inherited {
			&.ag-row-even,
			&.ag-row-odd,
			&.ag-row-even:not(.-padRow):hover,
			&.ag-row-odd:not(.-padRow):hover,
			div.ag-row-group,
			button {
				background: rgba($color: $ag-inherited-header, $alpha: 0.2);
			}
		}
	}
	.ag-row-pinned {
		color: $ag-group-value-row;
		font-weight: 600;
		button {
			background: $ag-row-group-bg;
			padding: 0px;
			border: none;
			&:focus {
				box-shadow: none;
			}
		}
	}
}

// .ag-theme-balham .ag-icon-grip:before {
//     content: url(../../../../images/custom_grip_icon.svg);
// }

.array-value-cell {
	height: 24px;
}
