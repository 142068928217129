@import 'style/Constants';
@import 'style/Mixins';

$pan-prefix: pan;
$tree-prefix-cls: '#{$pan-prefix}-tree';
$select-prefix-cls: '#{$pan-prefix}-select';
$select-tree-prefix-cls: '#{$pan-prefix}-select-tree';


.#{$select-tree-prefix-cls} {
  @include reset;

  margin: 0;
  margin-top: -4px;
  padding: 0 4px;
  li {
    margin: 8px 0;
    padding: 0;
    white-space: nowrap;
    list-style: none;
    outline: 0;
    &.filter-node {
      > span {
        font-weight: 500;
      }
    }
    ul {
      margin: 0;
      padding: 0 0 0 18px;
    }
    .#{$select-tree-prefix-cls}-node-content-wrapper {
      display: inline-block;
      width: calc(100% - 24px);
      margin: 0;
      padding: 3px 5px;
      color: $text-color;
      text-decoration: none;
      border-radius: $border-radius-sm;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        background-color: $item-hover-bg;
      }
      &.#{$select-tree-prefix-cls}-node-selected {
        background-color: $primary-2;
      }
    }
    span {
      &.#{$select-tree-prefix-cls}-checkbox {
        margin: 0 4px 0 0;
        + .#{$select-tree-prefix-cls}-node-content-wrapper {
          width: calc(100% - 46px);
        }
      }
      &.#{$select-tree-prefix-cls}-switcher,
      &.#{$select-tree-prefix-cls}-iconEle {
        display: inline-block;
        width: 24px;
        height: 24px;
        margin: 0;
        line-height: 22px;
        text-align: center;
        vertical-align: middle;
        border: 0 none;
        outline: none;
        cursor: pointer;
      }
      &.#{$select-prefix-cls}-icon_loading {
        .#{$select-prefix-cls}-switcher-loading-icon {
          position: absolute;
          left: 0;
          display: inline-block;
          color: $primary-color;
          font-size: 14px;
          transform: none;
          img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
          }
        }
      }
      &.#{$select-tree-prefix-cls}-switcher {
        position: relative;
        &.#{$select-tree-prefix-cls}-switcher-noop {
          cursor: auto;
        }
        &.#{$select-tree-prefix-cls}-switcher_open {
          @include TreeSwitcherIcon();
        }
        &.#{$select-tree-prefix-cls}-switcher_close {
          @include TreeSwitcherIcon();
          .#{$select-prefix-cls}-switcher-icon {
            img {
              transform: rotate(-90deg);
            }
          }
        }

        &.#{$select-tree-prefix-cls}-switcher_open,
        &.#{$select-tree-prefix-cls}-switcher_close {
          .#{$select-prefix-cls}-switcher-loading-icon {
            position: absolute;
            left: 0;
            display: inline-block;
            width: 24px;
            height: 24px;
            color: $primary-color;
            font-size: 14px;
            transform: none;
            img {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              margin: auto;
            }
          }
        }
      }
    }
  }

  .#{$select-tree-prefix-cls}-treenode-loading {
    .#{$select-tree-prefix-cls}-iconEle {
      display: none;
    }
  }
  &-child-tree {
    display: none;
    &-open {
      display: block;
    }
  }
  li#{&}-treenode-disabled {
    > span:not(.#{$select-tree-prefix-cls}-switcher),
    > .#{$select-tree-prefix-cls}-node-content-wrapper,
    > .#{$select-tree-prefix-cls}-node-content-wrapper span {
      color: $disabled-color;
      cursor: not-allowed;
    }
    > .#{$select-tree-prefix-cls}-node-content-wrapper:hover {
      background: transparent;
    }
  }
  &-icon__open {
    margin-right: 2px;
    vertical-align: top;
  }
  &-icon__close {
    margin-right: 2px;
    vertical-align: top;
  }
}

.#{$select-prefix-cls}-tree-dropdown {
  @include reset;
  .#{$select-prefix-cls}-dropdown-search {
    position: sticky;
    top: 0;
    z-index: 1;
    display: block;
    padding: 4px;
    background: $component-background;
    .#{$select-prefix-cls}-search__field__wrap {
      width: 100%;
    }
    .#{$select-prefix-cls}-search__field {
      box-sizing: border-box;
      width: 100%;
      padding: 4px 7px;
      border: $border-width-base $border-style-base $border-color-base;
      border-radius: 4px;
      outline: none;
    }
    &.#{$select-prefix-cls}-search--hide {
      display: none;
    }
  }
  .#{$select-prefix-cls}-not-found {
    display: block;
    padding: 7px 16px;
    color: $disabled-color;
    cursor: not-allowed;
  }
}



.#{$select-prefix-cls} {
  @include reset;

  position: relative;
  display: inline-block;
  outline: 0;

  ul,
  ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  > ul > li > a {
    padding: 0;
    background-color: $component-background;
  }

  // arrow
  &-arrow {
    @include iconfont-mixin();

    position: absolute;
    top: 50%;
    right: $control-padding-horizontal - 1px;
    margin-top: -$font-size-sm / 2;
    color: $disabled-color;
    font-size: $font-size-sm;
    line-height: 1;
    transform-origin: 50% 50%;

    & &-icon img {
      transition: transform 0.3s;
    }
  }

  &-open &-arrow{
    img{
      transform: rotate(-180deg);
    }
  }

  &-selection {
    display: block;
    box-sizing: border-box;
    background-color: $select-background;
    // strange align fix for chrome but works
    // https://gw.alipayobjects.com/zos/rmsportal/VFTfKXJuogBAXcvfAUWJ.gif
    border-top-width: $border-width-base + 0.02px;
    border-radius: $border-radius-base;
    outline: none;
    transition: all 0.3s $ease-in-out;
    user-select: none;

    &:hover {
      @include hover;
    }

    .#{$select-prefix-cls}-focused &,
    &:focus,
    &:active {
      @include active;
    }

    &__clear {
      @include selection__clear();
    }

    &:hover &__clear {
      opacity: 1;
    }

    &-selected-value {
      float: left;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &-no-arrow &-selection-selected-value {
    padding-right: 0;
  }

  &-disabled {
    color: $disabled-color;
  }

  &-disabled &-selection {
    background: $input-disabled-bg;
    cursor: not-allowed;
    &:hover,
    &:focus,
    &:active {
      border-color: $select-border-color;
      box-shadow: none;
    }

    &__clear {
      display: none;
      visibility: hidden;
      pointer-events: none;
    }
  }

  &-disabled &-selection--multiple &-selection__choice {
    padding-right: 10px;
    color: rgba($black, 33%);
    background: $background-color-base;
    &__remove {
      display: none;
    }
  }

  &-selection--single {
    position: relative;
    height: $input-height-base;
    cursor: pointer;
    border: $border-width-base $border-style-base $border-color-base;

    .#{$select-prefix-cls}-selection__rendered {
      margin-right: 24px;
    }
  }

  &-no-arrow {
    .#{$select-prefix-cls}-selection__rendered {
      margin-right: $control-padding-horizontal - 1px;
    }
  }

  &-selection__rendered {
    position: relative;
    display: block;
    margin-right: $control-padding-horizontal - 1px;
    margin-left: $control-padding-horizontal - 1px;
    line-height: $input-height-base - 2px;
    &::after {
      display: inline-block;
      width: 0;
      visibility: hidden;
      content: '.';
      pointer-events: none;
    }
  }

  &-lg {
    font-size: $font-size-lg;
    .#{$select-prefix-cls}-selection--single {
      height: $input-height-lg;
    }
    .#{$select-prefix-cls}-selection__rendered {
      line-height: $input-height-lg - 2px;
    }
    .#{$select-prefix-cls}-selection--multiple {
      min-height: $input-height-lg;
      .#{$select-prefix-cls}-selection__rendered {
        li {
          height: $input-height-lg - 8px;
          line-height: $input-height-lg - 8px;
        }
      }
      .#{$select-prefix-cls}-selection__clear,
      .#{$select-prefix-cls}-arrow {
        top: $input-height-lg / 2;
      }
    }
  }

  &-sm {
    .#{$select-prefix-cls}-selection--single {
      height: $input-height-sm;
    }
    .#{$select-prefix-cls}-selection__rendered {
      margin-left: $control-padding-horizontal-sm - 1px;
      line-height: $input-height-sm - 2px;
    }
    .#{$select-prefix-cls}-selection--multiple {
      min-height: $input-height-sm;
      .#{$select-prefix-cls}-selection__rendered {
        li {
          height: $input-height-sm - 8px;
          line-height: $input-height-sm - 10px;
        }
      }
      .#{$select-prefix-cls}-selection__clear,
      .#{$select-prefix-cls}-arrow {
        top: $input-height-sm / 2;
      }
    }
    .#{$select-prefix-cls}-selection__clear,
    .#{$select-prefix-cls}-arrow {
      right: $control-padding-horizontal-sm;
    }
  }

  &-disabled &-selection__choice__remove {
    color: $disabled-color;
    cursor: default;
    &:hover {
      color: $disabled-color;
    }
  }

  &-search__field__wrap {
    position: relative;
    display: inline-block;
  }

  &-selection__placeholder,
  &-search__field__placeholder {
    // for pan compatibility
    position: absolute;
    top: 50%;
    right: 9px;
    left: 0;
    max-width: 100%;
    height: 20px;
    margin-top: -10px;
    overflow: hidden;
    color: $input-placeholder-color;
    line-height: 20px;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;
  }

  &-search__field__placeholder {
    left: $control-padding-horizontal;
  }

  &-search__field__mirror {
    position: absolute;
    top: 0;
    left: 0;
    white-space: pre;
    opacity: 0;
    pointer-events: none;
  }

  &-search--inline {
    position: absolute;
    width: 100%;
    height: 100%;

    .#{$select-prefix-cls}-search__field__wrap {
      width: 100%;
      height: 100%;
    }

    .#{$select-prefix-cls}-search__field {
      width: 100%;
      height: 100%;
      font-size: 100%;
      line-height: 1;
      background: transparent;
      border-width: 0;
      border-radius: $border-radius-base;
      outline: 0;
    }

    > i {
      float: right;
    }
  }

  &-selection--multiple {
    min-height: $input-height-base;
    padding-bottom: 3px;
    cursor: text;
    @include clearfix;

    .#{$select-prefix-cls}-search--inline {
      position: static;
      float: left;
      width: auto;
      max-width: 100%;
      padding: 0;
      .#{$select-prefix-cls}-search__field {
        width: 0.75em;
        max-width: 100%;
      }
    }

    .#{$select-prefix-cls}-selection__rendered {
      height: auto;
      margin-bottom: -3px;
      margin-left: 5px;
    }

    .#{$select-prefix-cls}-selection__placeholder {
      margin-left: 6px;
    }

    > ul > li,
    .#{$select-prefix-cls}-selection__rendered > ul > li {
      height: $input-height-base - 8px;
      // for tree-select
      margin-top: 3px;
      line-height: $input-height-base - 8px - 2px;
    }

    .#{$select-prefix-cls}-selection__choice {
      position: relative;
      float: left;
      max-width: 99%;
      margin-right: 4px;
      padding: 0 20px 0 10px;
      overflow: hidden;
      color: $tag-default-color;
      background-color: $tag-default-bg;
      border: 1px solid $border-color-split;
      border-radius: $border-radius-sm;
      cursor: default;
      transition: padding 0.3s $ease-in-out;
      &__disabled {
        padding: 0 10px;
      }
    }

    .#{$select-prefix-cls}-selection__choice__content {
      display: inline-block;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      transition: margin 0.3s $ease-in-out;
    }

    .#{$select-prefix-cls}-selection__choice__remove {
      @include iconfont-mixin();

      position: absolute;
      right: 4px;
      display: inline-block;
      color: $text-color-secondary;
      font-weight: bold;
      font-size: $font-size-sm;
      line-height: inherit;
      cursor: pointer;
      transition: all 0.3s;
      @include iconfont-size-under-12px(10px);
      &:hover {
        color: $icon-color-hover;
      }
    }

    .#{$select-prefix-cls}-selection__clear,
    .#{$select-prefix-cls}-arrow {
      top: $input-height-base / 2;
    }
  }

  &-allow-clear &-selection--multiple &-selection__rendered,
  &-show-arrow &-selection--multiple &-selection__rendered {
    margin-right: 20px; // In case that clear button will overlap content
  }

  &-open {
    .#{$select-prefix-cls}-arrow {
      &-icon img {
        transform: rotate(180deg);
      }
    }
    .#{$select-prefix-cls}-selection {
      @include active();
    }
  }

  &-combobox {
    .#{$select-prefix-cls}-arrow {
      display: none;
    }
    .#{$select-prefix-cls}-search--inline {
      float: none;
      width: 100%;
      height: 100%;
    }
    .#{$select-prefix-cls}-search__field__wrap {
      width: 100%;
      height: 100%;
    }
    .#{$select-prefix-cls}-search__field {
      position: relative;
      z-index: 1;
      width: 100%;
      height: 100%;
      box-shadow: none;
      transition: all 0.3s $ease-in-out, height 0s;
    }
  }
  &-combobox#{&}-allow-clear &-selection:hover &-selection__rendered,
  &-combobox#{&}-show-arrow &-selection:hover &-selection__rendered {
    margin-right: 20px; // In case that clear button will overlap content
  }
}

.#{$select-prefix-cls}-dropdown {
  @include reset;

  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: $zindex-dropdown;
  box-sizing: border-box;
  font-size: $font-size-base;
  font-variant: initial;
  background-color: $select-dropdown-bg;
  border-radius: $border-radius-base;
  outline: none;
  box-shadow: $box-shadow-base;

  &.slide-up-enter.slide-up-enter-active#{&}-placement-bottomLeft,
  &.slide-up-appear.slide-up-appear-active#{&}-placement-bottomLeft {
    animation-name: slideUpIn;
  }

  &.slide-up-enter.slide-up-enter-active#{&}-placement-topLeft,
  &.slide-up-appear.slide-up-appear-active#{&}-placement-topLeft {
    animation-name: slideDownIn;
  }

  &.slide-up-leave.slide-up-leave-active#{&}-placement-bottomLeft {
    animation-name: slideUpOut;
  }

  &.slide-up-leave.slide-up-leave-active#{&}-placement-topLeft {
    animation-name: slideDownOut;
  }

  &-hidden {
    display: none;
  }

  &-menu {
    max-height: 250px;
    margin-bottom: 0;
    padding-left: 0; // Override default ul/ol
    overflow: auto;
    list-style: none;
    outline: none;

    &-item-group-list {
      margin: 0;
      padding: 0;

      > .#{$select-prefix-cls}-dropdown-menu-item {
        padding-left: 20px;
      }
    }

    &-item-group-title {
      height: 32px;
      padding: 0 $control-padding-horizontal;
      color: $text-color-secondary;
      font-size: $font-size-sm;
      line-height: 32px;
    }

    &-item-group-list &-item:first-child:not(:last-child),
    &-item-group:not(:last-child) &-item-group-list &-item:last-child {
      border-radius: 0;
    }

    &-item {
      position: relative;
      display: block;
      padding: 5px $control-padding-horizontal;
      overflow: hidden;
      color: $text-color;
      font-weight: normal;
      line-height: 22px;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
      transition: background 0.3s ease;

      &:hover:not(&-disabled) {
        background-color: $item-hover-bg;
      }

      &:first-child {
        border-radius: $border-radius-base $border-radius-base 0 0;
      }

      &:last-child {
        border-radius: 0 0 $border-radius-base $border-radius-base;
      }

      &-selected {
        color: $text-color;
        font-weight: $select-item-selected-font-weight;
        background-color: $select-item-selected-bg;
      }

      &-disabled {
        color: $disabled-color;
        cursor: not-allowed;

        &:hover {
          color: $disabled-color;
          cursor: not-allowed;
        }
      }

      &-active:not(&-disabled) {
        background-color: $select-item-active-bg;
      }

      &-divider {
        height: 1px;
        margin: 1px 0;
        overflow: hidden;
        line-height: 0;
        background-color: $border-color-split;
      }
    }
  }

  &#{&}--multiple {
    .#{$select-prefix-cls}-dropdown-menu-item {
      padding-right: $control-padding-horizontal + 20;
      & .#{$select-prefix-cls}-selected-icon {
        position: absolute;
        top: 50%;
        right: $control-padding-horizontal;
        color: transparent;
        font-weight: bold;
        font-size: 12px;
        text-shadow: 0 0.1px 0, 0.1px 0 0, 0 -0.1px 0, -0.1px 0;
        transform: translateY(-50%);
        transition: all 0.2s;
      }

      &:hover .#{$select-prefix-cls}-selected-icon {
        color: rgba($black, 87%);
      }

      &-disabled .#{$select-prefix-cls}-selected-icon {
        display: none;
      }

      &-selected .#{$select-prefix-cls}-selected-icon,
      &-selected:hover .#{$select-prefix-cls}-selected-icon {
        display: inline-block;
        color: $primary-color;
      }
    }
  }

  &--empty#{&}--multiple &-menu-item {
    padding-right: $control-padding-horizontal;
  }

  &-container-open,
  &-open {
    .#{$select-prefix-cls}-dropdown {
      display: block;
    }
  }
}

