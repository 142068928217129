.comment-cell {
	.comment-btn {
		position: absolute;
		cursor: pointer;
		top: 5px;
		right: 15px;
	}
}

.comment-dropdown {
	font-size: 12px;

	&::before {
		display: none;
	}

	.ant-dropdown-arrow {
		opacity: 0;
	}

	&.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
		opacity: 1;
		border-color: #353c47;
		right: 13px;
	}
	.comment-dropdown-overlay {
		background: white;
		width: 375px;
		height: 135px;
		.header {
			color: white;
			font-weight: bold;
			line-height: 36px;
			height: 36px;
			padding-left: 16px;
			background: #353c47;
			border-top-right-radius: 4px;
			border-top-left-radius: 4px;
		}
		.body {
			padding: 12px 14px;
			border: 1px solid #dadbdb;
		}
		.footer {
			position: relative;
			height: 48px;
			background: #f4f5f5;
			border-bottom-right-radius: 4px;
			border-bottom-left-radius: 4px;
			border: 1px solid #dadbdb;
			border-top: 0px;

			.cancel-btn,
			.save-btn {
				display: grid;
				justify-content: center;
				align-content: center;
				width: 105px;
				height: 30px;
				position: absolute;
				top: 9px;
				cursor: pointer;

				box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
				border-radius: 4px;
			}
			.save-btn {
				right: 14px;
				background: #006fcc;
				color: white;
			}

			.cancel-btn {
				right: 133px;
				border: 1px solid #dadbdb;
				background: white;
				color: #006fcc;
			}
		}
	}
}
