@import "style/Constants";
@import "style/Mixins";

$pan-prefix: "pan-ci.pan";

// Input styles
.#{$pan-prefix}-input {
	@include reset;
	@include input;
}

//== Style for input-group: input with label, with button or dropdown...
.#{$pan-prefix}-input-group {
	@include reset;
	@include input-group("#{$pan-prefix}-input");
	&-wrapper {
		display: inline-block;
		width: 100%;
		text-align: start;
		vertical-align: top;
	}
}

// Input with affix: prefix or suffix
.#{$pan-prefix}-input-affix-wrapper {
	@include reset;
	@include input-affix-wrapper("#{$pan-prefix}-input");

	.#{$pan-prefix}-input {
		min-height: 100%; // use min-height, assume that no smaller height to override
	}
}

.#{$pan-prefix}-input-password-icon {
	color: $text-color-secondary;
	cursor: pointer;
	transition: all 0.3s;

	&:hover {
		color: #333;
	}
}

.#{$pan-prefix}-input-clear-icon {
	@include clear-icon;
	vertical-align: 0;
}

.#{$pan-prefix}-input-textarea-clear-icon {
	@include clear-icon;
	position: absolute;
	top: 0;
	right: 0;
	margin: 8px 8px 0 0;
}
