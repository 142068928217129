.load-container{
    background-color: rgba(250, 250, 250, 0.5);
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
    .loader,
    .loader:after {
    border-radius: 50%;
    width: 7em;
    height: 7em;
    }
    .loader {
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1em solid rgba(165, 210, 243, 0.5);
    border-right: 1em solid rgba(165, 210, 243, 0.5);
    border-bottom: 1em solid rgba(165, 210, 243, 0.5);
    border-left: 1em solid #6fdfe2;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
    }
    @-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
    }
    @keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
    }
}