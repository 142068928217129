.severity-popover.pan-popover {
	.pan-popover-arrow {
		border-top-color: #24292d !important;
		border-left-color: #24292d !important;
		border-bottom-color: #24292d !important;
		border-right-color: #24292d !important;
		left: 5px !important;
	}
	.pan-popover-inner {
		background: #24292d;
		border-radius: 4px;
		position: relative;
		left: -5px;
		.pan-popover-inner-content {
			color: white;
			font-family: Lato;
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			letter-spacing: 0.1px;
		}
	}
	&-hidden {
		display: none;
	}
}

.metric-desc-popover.pan-popover {
	.pan-popover-arrow {
		border-top-color: #24292d !important;
		border-left-color: #24292d !important;
		border-bottom-color: #24292d !important;
		border-right-color: #24292d !important;
	}
	.pan-popover-inner {
		background: #24292d;
		border-radius: 4px;
		.pan-popover-inner-content {
			color: white;
			font-family: Lato;
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			letter-spacing: 0.1px;
			max-width: 500px;
			.content {
				* {
					margin: 0px;
				}
				ul {
					padding-inline-start: 20px;
				}
			}
		}
	}
	&-hidden {
		display: none;
	}
}

#insights-app {
	.insights-main {
		> .grid {
			> .table-grid {
				> .log-table {
					.info-area {
						top: 10%;
					}
					.ag-pinned-right-header {
						border-left: none;
					}

					.ag-pinned-right-floating-bottom,
					.ag-pinned-right-cols-container {
						.ag-cell {
							border-left: none;
						}
					}
					.ag-pinned-right-header {
						.ag-header-row {
							.ag-header-cell:last-child {
								border-right: none !important;
							}
						}
					}
					.ag-header {
						.ag-header-cell {
							font-family: Lato;
							font-style: normal;
							font-weight: bold;
							font-size: 12px;
							color: #333333;
						}
						&.sticky {
							position: absolute;
							top: 56px;
							width: 100%;
							height: 28px !important;
							min-height: 28px !important;

							.ag-header-row {
								height: 28px !important;
							}
							background: #ffffff;
							border-bottom: 1px solid#DADBDB;
							.ag-header-cell {
								border-right: 1px solid#DADBDB;
								&[col-id='metric_value_pct'] {
									padding-left: 35px;
								}

								&[col-id='metric'],
								&[col-id='hostname'],
								&[col-id='timegenerated'] {
									padding-left: 12px;
								}

								&:last-child {
									border-right: none;
								}
							}
						}
					}
					.ag-status-bar {
						display: none;
					}
					.ag-primary-cols-header-panel {
						.ag-icon {
							display: none !important;
						}
						.ag-input-wrapper {
							margin-left: 0px;
							margin-right: 7px;
						}
					}
					.severity-icon {
						vertical-align: text-top;
						margin-right: 7px;
					}
					.ag-root {
						border-width: 0px;
						.ag-header {
							background-color: white;
							height: 28px !important;
							min-height: 28px !important;
							font-family: Lato;
							font-style: normal;
							font-weight: normal;
							font-size: 12px;
							color: #333333;

							.ag-header-row {
								height: 28px !important;
							}

							.ag-header-cell {
								&[col-id='metric_value_pct'],
								&[col-id='hostname'],
								&[col-id='metric'] {
									padding-left: 35px;
								}

								&::after {
									display: none;
								}

								border-right: 1px solid#DADBDB;
							}
						}
						.ag-row {
							> .ag-react-container {
								height: 100%;
								.inner-grid {
									position: relative;
									height: inherit;
									background-color: white;
									.master-detail-grid {
										.inner-severity-cell {
											padding-left: 25px;
											.severity-icon {
												top: -1px;
												position: relative;
												display: inline-block;
												width: 16px;
												height: 16px;
												&.critical {
													background: transparent url('../../../../../images/icon-critical.svg') no-repeat center;
												}
												&.warning {
													background: transparent url('../../../../../images/icon-warning.svg') no-repeat center;
												}
												&.healthy {
													background: transparent url('../../../../../images/icon-healthy.svg') no-repeat center;
												}
											}
										}
										.ag-root {
											border: none;
										}
										.ag-cell {
											background-color: white;
											text-decoration: none;
											cursor: default;
											&[col-id='hostname'],
											&[col-id='metric'] {
												color: #006fcc !important;
												cursor: pointer;
												font-family: Lato;
												font-style: normal;
												font-weight: normal;
												font-size: 12px;
											}
										}
										.ag-header {
											background: #ffffff;
											border-bottom: 1px solid#DADBDB;
											.ag-header-row {
												.ag-header-cell {
													&[col-id='metric'],
													&[col-id='hostname'] {
														padding-left: 12px;
													}

													&:last-child {
														border-right: none !important;
													}
												}
											}
										}

										.ag-body-viewport {
											overflow: hidden;
											border: none;
											.ag-cell {
												border: none;
											}
											.ag-row.ag-row-last {
												border-bottom: none;
											}
											.ag-row-odd {
												.ag-cell {
													background-color: #f4f5f5;
												}
											}
											.ag-row-even {
												.ag-cell {
													background-color: white;
												}
											}
										}
									}

									&-nomore .master-detail-grid {
										height: 100% !important;
									}
									.more-details {
										position: relative;
										cursor: pointer;
										height: 28px;
										line-height: 28px;
										padding-left: 37px;
										font-family: Lato;
										font-style: normal;
										font-weight: normal;
										font-size: 12px;
										display: flex;
										letter-spacing: 0.1px;
										color: #006fcc;
										border-top: 1px solid#DADBDB;
										.next-detail-icon {
											margin-right: 7px;
											top: 5px;
											position: relative;
											display: inline-block;
											width: 16px;
											height: 16px;
											&.critical {
												background: transparent url('../../../../../images/icon-critical.svg') no-repeat center;
											}
											&.warning {
												background: transparent url('../../../../../images/icon-warning.svg') no-repeat center;
											}
											&.healthy {
												background: transparent url('../../../../../images/icon-healthy.svg') no-repeat center;
											}
										}
									}

									.detail-separator {
										height: 3px;
										background: #6d757b;
									}
								}
							}
						}
						.ag-cell {
							font-family: Lato;
							font-style: normal;
							font-weight: normal;
							letter-spacing: 0.1px;
							border-right: none;
							color: #24292d;
							background-color: #eaebeb;
							.metric-cell,
							.hostname-cell {
								margin-left: -2px;
								position: relative;
								.severity-icon {
									margin-right: 5px;
									top: -4px;
									position: relative;
									display: inline-block;
									width: 20px;
									height: 22px;
									border-radius: 0px;

									&.critical {
										background: transparent url('../../../../../images/icon-critical.svg') no-repeat center;
										&:hover {
											background: transparent url('../../../../../images/icon-critical.svg') no-repeat center;
										}
									}
									&.warning {
										background: transparent url('../../../../../images/icon-warning.svg') no-repeat center;
										&:hover {
											background: transparent url('../../../../../images/icon-warning.svg') no-repeat center;
										}
									}
									&.healthy {
										background: transparent url('../../../../../images/icon-healthy.svg') no-repeat center;
										&:hover {
											background: transparent url('../../../../../images/icon-healthy.svg') no-repeat center;
										}
									}
								}
								.hostname {
									cursor: pointer;
									color: #006fcc;
								}
							}

							&[col-id='timegenerated'] {
								cursor: default;
							}
							&[col-id='hostname'],
							&[col-id='metric'] {
								text-decoration: none;
							}
						}
					}
					.ag-cell {
						line-height: 28px !important;
					}
				}
			}
		}
	}
}
