// .bd-example-modal-lg .modal-dialog{
//     display: table;
//     position: relative;
//     margin: 0 auto;
//     top: calc(50% - 24px);
//   }

//   .bd-example-modal-lg .modal-dialog .modal-content{
//     background-color: transparent;
//     border: none;
//   }

.hlgrid-form {
    height: calc(100vh - 114px);   
    max-height: calc(100vh - 114px);
    overflow: hidden;

    &.multi-step {
        height: calc(100vh - 50px);
        max-height: calc(100vh - 50px);
    }

    > .form-group {
        overflow-y: auto;
        /* padding: 10px 16px 96px 8px; */
        margin-bottom: 4px;

        /*
        .row {
            margin-right: 0;
            margin-left: 0;
            padding-right: 0;
            padding-left: 0;
        }
        */

        > .d-flex.flex-row.row:last-child {

            .fieldset:first-child:last-child,
            .fieldset:last-child {
                margin-bottom: 8px;
            }
        }
    }
}

.hlgrid-form:not(.hlgrid-form-wide) {
    max-width: 800px;
}

.modal-dialog .hlgrid-form {
    height: auto;   
    max-height: inherit;

    &:not(.hlgrid-form-wide) {
        max-width: none;
    }

    > .form-group {
        padding: 10px 16px 9px 8px;
    }
}