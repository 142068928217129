
.viewer-display.form-group {

    .col-form-label {
        padding-top: 6px;
        align-self: auto;
    }
    .col.is-invalid {
        .h-100.card {
            border-color: #d94949;
        }
    }
    .m-0.card {

        .d-flex.flex-column.border.border-light {
            border: 0 !important;

            > .form-group {
                margin: 0;

                > .row {

                    > .col-9 {
                        padding-left: 12px;
                        padding-right: 0;
                    }

                    > .col-3 {
                        padding-left: 15px;

                        .btn-sm {
                            padding: 4px 10px 6px;
                        }
                    }
                }

                .form-group {
                    margin: 0;

                    .indent-align-col-form-label {
                        margin-top: 2px;
                    }
                }
            }
        }
    }

    .card::before {
        height: 0;
        background: none;
    }
}