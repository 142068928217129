@import "Constants";
@import "Mixins";

.#{$calendar-prefix-cls}-year-panel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-picker-panel;
  background: $component-background;
  border-radius: $border-radius-base;
  outline: none;

  > div {
    display: flex;
    flex-direction: column;
    // TODO: this is a useless wrapper, and we need to remove it in rc-calendar
    height: 100%;
  }
}

.#{$calendar-prefix-cls}-year-panel-hidden {
  display: none;
}

.#{$calendar-prefix-cls}-year-panel-header {
  @include calendarPanelHeader('#{$calendar-prefix-cls}-year-panel');
  position: relative;
}

.#{$calendar-prefix-cls}-year-panel-body {
  flex: 1;
}

.#{$calendar-prefix-cls}-year-panel-footer {
  border-top: $border-width-base $border-style-base $border-color-split;
  .#{$calendar-prefix-cls}-footer-extra {
    padding: 0 12px;
  }
}

.#{$calendar-prefix-cls}-year-panel-table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-collapse: separate;
}

.#{$calendar-prefix-cls}-year-panel-cell {
  text-align: center;
}

.#{$calendar-prefix-cls}-year-panel-year {
  display: inline-block;
  height: 24px;
  margin: 0 auto;
  padding: 0 8px;
  color: $text-color;
  line-height: 24px;
  text-align: center;
  background: transparent;
  border-radius: $border-radius-sm;
  transition: background 0.3s ease;

  &:hover {
    background: $item-hover-bg;
    cursor: pointer;
  }
}

.#{$calendar-prefix-cls}-year-panel-selected-cell .#{$calendar-prefix-cls}-year-panel-year {
  color: $text-color-inverse!important;
  background: $primary-color;

  &:hover {
    color: $text-color-inverse;
    background: $primary-color;
  }
}

.#{$calendar-prefix-cls}-year-panel-last-decade-cell,
.#{$calendar-prefix-cls}-year-panel-next-decade-cell {
  .#{$calendar-prefix-cls}-year-panel-year {
    color: $disabled-color;
    user-select: none;
  }
}
