@import './expVariables';

.compact-select__menu {
	margin-top: 5px !important;
}

.cell-popover.pan-popover {
	min-width: 70px;
	padding-top: 5px;
	& .pan-popover-arrow {
		display: none;
	}
}

.log-viewer-body {
	position: relative;
	flex-grow: 1;
	margin: 0 16px 16px 16px;
	background-color: white;
	border-bottom-left-radius: $border-radius;
	border-bottom-right-radius: $border-radius;
	border-top: 1px solid $border-color;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
	.ag-theme-balham .ag-row {
		border: none;
	}
	.ag-theme-balham .ag-ltr .ag-cell {
		cursor: pointer;

		.compact-select__control {
			background: transparent;
			border: 0px;
			input {
				height: 0px;
			}
			.compact-select__indicator {
				display: none;
			}
			&::before {
				content: '';
				display: block;
				background: url(~ui-lib/src/images/icon-list.svg) no-repeat;
				width: 20px;
				height: 20px;
				line-height: 24px;
			}
		}
	}

	.ag-theme-balham .ag-ltr .ag-cell:focus {
		color: #0883b9;
	}

	.ag-theme-balham .ag-row-focus {
		background: #d7f2ff;
	}

	.ag-theme-balham .ag-header-row:first-child .ag-header-cell,
	.ag-theme-balham .ag-header-row:first-child .ag-header-group-cell {
		background-color: #fff;
	}

	.ag-theme-balham .ag-header-cell,
	.ag-theme-balham .ag-header-group-cell {
		border-style: solid;
		border-color: #bdc3c7;
		line-height: 32px;
		padding-left: 12px;
		padding-right: 12px;
		border-width: 0;
		border-bottom: 1px solid #bdc3c7;
	}

	.log-details-icon {
		background: url('~ui-lib/src/images/icon-log-details.svg');
		width: 16px;
		height: 16px;
		margin: auto;
		margin-top: 5px;

		&.log-selected {
			background: url('~ui-lib/src/images/icon-log-details-active.svg');
			width: 16px;
			height: 16px;
			margin-top: 5px;
		}
	}
}
