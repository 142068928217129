#insights-app {
	.device-details {
		.alert-timeline {
			.timeline {
				position: relative;
				.highcharts-container {
					overflow: visible !important;
					z-index: 2 !important;
					.highcharts-axis {
						&.highcharts-yaxis {
							display: none;
						}
					}

					.legend-item {
						position: relative;
						left: 10px;
						.legend-text {
							position: relative;
							top: 2px;
							left: 5px;
							width: 122px;
						}
					}

					.highcharts-tooltip {
						pointer-events: auto !important;
					}

					.alerts-tooltip,
					.commits-tooltip {
						position: relative;
						background-color: white;
						width: 100%;
						max-height: 170px;
						border: 1px solid #c7caca;
						border-radius: 4px;
						overflow-y: auto;
						overflow-x: auto;
						&::-webkit-scrollbar {
							width: 2px;
							height: 2px;
						}
						&::-webkit-scrollbar-track {
							-webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
							border-radius: 4px;
						}
						&::-webkit-scrollbar-thumb {
							-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
							border-radius: 4px;
						}
						.alert,
						.commit {
							width: 100%;
							display: flex;
							color: black;
							height: 40px;
							padding: 0px;
							margin: 0px;
							font-family: Lato;
							font-style: normal;
							&:not(:last-child) {
								// &::after {
								// 	content: '';
								// 	height: 1px;
								// 	width: 100%;
								// 	background: #dadbdb;
								// }
								border-bottom: 1px solid #dadbdb;
							}
						}
						.commit {
							flex-direction: column;
							width: 100%;
							padding: 5px 10px;
							.command {
								font-size: 12px;
								font-weight: 400;
								width: 100%;
								color: #006fcc;
								.user {
									color: #333333;
								}
							}

							.time {
								width: 100%;
								height: 12px;
								font-weight: 300;
								font-size: 10px;
							}
						}
						.alert {
							flex-direction: row;
							width: 100%;
							padding: 5px 10px;
							[class^='icon-'] {
								width: fit-content;
								margin-right: 8px;
							}
							.icon-critical {
								content: url(../../../../../../images/icon-critical.svg);
							}
							.icon-warning {
								content: url(../../../../../../images/icon-warning.svg);
							}
							.name {
								height: 12px;
								width: 100%;
								font-size: 12px;
								font-weight: 400;
								color: #006fcc;
							}
							.time {
								height: 12px;
								width: 100%;
								font-weight: 300;
								font-size: 10px;
								position: relative;
								top: 2px;
							}
						}
					}
				}
				.legend-bg {
					position: absolute;
					display: inline-block;
					width: 200px;
					height: 214px;
					background: #f4f5f5;
					top: 15px;
					right: 15px;
					z-index: 0;
					border-radius: 4px;
				}
			}
		}
	}
}
