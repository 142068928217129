.insights-nav {
	height: 32px;
	display: flex;
	flex-direction: row;

	.insights-nav-item {
		cursor: pointer;
		text-align: center;
		padding: 6px 15px;

		font-size: 12px;
		line-height: 20px;
		margin-right: 4px;

		border-bottom: 4px solid #b8b8b8;

		&:hover {
			background: linear-gradient(rgba(86, 175, 252, 0.25) 0.89%, rgba(42, 153, 249, .25) 100.89%);
		}

		&.active {
			border-bottom: 4px solid #006fcc;
			cursor: default;

            &:hover {
                background: none;
            }
		}
	}

	.insights-nav-filler {
		flex-grow: 1;
		border-bottom: 4px solid #eaebeb;
	}
}
