.div-link {
	color: #0ba4e8;
	cursor: pointer;
}

.expanded-rows {
	padding-left: 0px;
}

.job-manager .pan-ag-grid {
	height: 400px;
}