.rule-config-card {
	height: fit-content;
	width: 465px;
	border-radius: 4px;
	border: 1px solid #dadbdb;
	> .title {
		height: 48px;
		position: relative;
		> .card-icon {
			position: absolute;
			top: 12px;
			left: 12px;
		}
		> .card-title {
			position: absolute;
			top: 12px;
			left: 45px;
			font-family: Lato;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 24px;
		}

		> .open-icon {
			position: absolute;
			top: 22px;
			right: 16px;
			cursor: pointer;
			transition: transform 0.3s;
		}
	}
	&.open {
		border: 1px solid #c0e3ff;
		> .title {
			border-bottom: 1px solid #dadbdb;
			.open-icon {
				transform: rotate(180deg);
			}
		}
		> .content {
			min-height: 50px;
			padding: 10px 16px;
		}
	}
}
