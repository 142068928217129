.export-config {
	font-family: Lato;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.2px;
	& > div {
		& > label {
			display: block;
		}
		.field-radio-group {
			text-transform: uppercase;
		}

		&:not(:last-child) {
			margin-bottom: 18px;
		}
	}

	& .error-list {
		display: block;
		color: red;
	}

	.export-btns {
		display: flex;
		justify-content: flex-end;
		.export-btn {
			width: 80px;
			height: 28px;
			background: linear-gradient(180deg, #2686cb 0.89%, #1f75b2 100.89%);
			border-radius: 4px;
		}
	}
}
