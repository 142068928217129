@import "Constants";
@import "Mixins";

.#{$calendar-prefix-cls}-decade-panel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-picker-panel;
  display: flex;
  flex-direction: column;
  background: $component-background;
  border-radius: $border-radius-base;
  outline: none;
}

.#{$calendar-prefix-cls}-decade-panel-hidden {
  display: none;
}

.#{$calendar-prefix-cls}-decade-panel-header {
  @include calendarPanelHeader('#{$calendar-prefix-cls}-decade-panel');
  position: relative;
}

.#{$calendar-prefix-cls}-decade-panel-body {
  flex: 1;
}

.#{$calendar-prefix-cls}-decade-panel-footer {
  border-top: $border-width-base $border-style-base $border-color-split;
  .#{$calendar-prefix-cls}-footer-extra {
    padding: 0 12px;
  }
}

.#{$calendar-prefix-cls}-decade-panel-table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-collapse: separate;
}

.#{$calendar-prefix-cls}-decade-panel-cell {
  white-space: nowrap;
  text-align: center;
}

.#{$calendar-prefix-cls}-decade-panel-decade {
  display: inline-block;
  height: 24px;
  margin: 0 auto;
  padding: 0 6px;
  color: $text-color;
  line-height: 24px;
  text-align: center;
  background: transparent;
  border-radius: $border-radius-sm;
  transition: background 0.3s ease;

  &:hover {
    background: $item-hover-bg;
    cursor: pointer;
  }
}

.#{$calendar-prefix-cls}-decade-panel-selected-cell .#{$calendar-prefix-cls}-decade-panel-decade {
  color: $text-color-inverse!important;
  background: $primary-color;

  &:hover {
    color: $text-color-inverse;
    background: $primary-color;
  }
}

.#{$calendar-prefix-cls}-decade-panel-last-century-cell,
.#{$calendar-prefix-cls}-decade-panel-next-century-cell {
  .#{$calendar-prefix-cls}-decade-panel-decade {
    color: $disabled-color;
    user-select: none;
  }
}
