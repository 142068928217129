.select-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 10px;
    color: #7f868c;
    text-transform: uppercase;
    letter-spacing: .6px;
    font-weight: 800;
    border-bottom: 1px solid #dadcde;
    padding: 10px;
    padding-bottom: 4px;
    cursor: pointer;
}

.select-group-badge {
    background-color: #ebecf0;
    border-radius: 2em;
    color: #172b4d;
    display: inline-block;
    font-size: 12px;
    font-weight: normal;
    line-height: 1;
    min-width: 1px;
    padding: .16666666666667em .5em;
    text-align: center;
}

/*
.select-item-label {
    margin-left: 20px;
}
*/

.compact-select__control {
    min-height: 24px !important;
    height: 24px !important;
    font-size: 12px;
}

.tag-select__control {
    min-height: 30px !important;
    height: 30px !important;
}

.compact-select__control,
.tag-select__control {
    border-color: #d4d4d4;
}

.compact-select__control,
.compact-select__control--is-focused,
.compact-select__control--menu-is-open,
.tag-select__control {
    border-radius: 2px !important;
    border-width: 1px !important;
    box-shadow: none !important;
}

.compact-select__control--is-focused,
.compact-select__control--menu-is-open,
.tag-select__control--is-focused,
.tag-select__control--menu-is-open {
    border-color: #09acdd !important;
}

.compact-select__value-container {
    height: 22px;
}

.tag-select__value-container {
    height: 30px;
}

.compact-select__value-container,
.compact-select__value-container--has-value,
.tag-select__value-container,
.tag-select__value-container--has-value {
    top: -1px;
    padding: 0 8px !important;

    &.compact-select__value-container--is-multi {
        top: -2px;
    }
}

.compact-select__indicator,
.compact-select__clear-indicator,
.tag-select__indicator,
.tag-select__clear-indicator {
    padding: 1px 2px !important;
    cursor: pointer;
}

.compact-select__indicator-separator,
.tag-select__indicator-separator {
    display: none;
}

.compact-select__single-value,
.tag-select__single-value {
    color: #1d2124 !important;
    top: 12px !important;
}

.compact-select__single-value + div,
.tag-select__single-value + div {
    color: #1d2124 !important;
    padding: 0;
    position: relative;
    top: -1px;
}

.compact-select__menu,
.tag-select__menu {
    font-size: 12px;
    border-radius: 2px !important;
    .btn {
        margin: 6px 0 9px 12px;
    }
}
.actionHolder {
    border-bottom: 1px solid #ccc;
}
.compact-select__option,
.tag-select__option {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    cursor: pointer !important;

    &:hover {
        background: rgba(11, 164, 232, .1) !important;
    }
}

.compact-select__option:active,
.compact-select__option:checked,
.compact-select__option:focus,
// .compact-select__option--is-focused,
.compact-select__option--is-selected,
.compact-select__option--is-selected:hover,
.tag-select__option:active,
.tag-select__option:checked,
.tag-select__option:focus,
.tag-select__option--is-focused,
.tag-select__option--is-selected,
.tag-select__option--is-selected:hover {
    background: rgba(10, 156, 221, .18) !important;
    color: #1d2124 !important;
}

.tag-select__multi-value__remove {
    cursor: pointer;
}

.tag-select__placeholder {
    font-size: 12px;
    color: #91979c !important;
}

.compact-select__multi-value__label {
    padding: 2px 3px 2px 6px !important;
}

.compact-select__placeholder {
    color: #91979c !important;
}
.css-1c9lvui-option{
    background-color: #fff !important;
}

.select-group::before{
    transition: transform 0.3s, display 1s;
}

.select-group.collapsed::before{
    content:  url(/static/media/chevron-down.034d8e64.svg);
    transform: rotate(-90deg); 
}
.select-group.open::before{
    content:  url(/static/media/chevron-down.034d8e64.svg);
}
.select-group-label{
    flex-grow: 1;
    padding-left: 10px;
}
.select-group-children > div{
    padding-left: 40px;
}