
.field-radio-group.form-control {
    border: none;
    padding: 0;
}

.field-radio-group.form-control.is-invalid {
    /* border-width: 1px; */
    /* border-style: solid; */
    background: none;
}

.field-radio-group .invalid-feedback {
    margin-top: .7rem;
}

.form-check-inline {
    margin-right: 20px;

    &:last-of-type {
        margin-right: 0;
    }
}