/* --- BEGIN Lato CSS --- */
@font-face {
	font-family: Lato;
	src: url(../fonts/Lato-Hairline.eot);
	src: url(../fonts/Lato-Hairline.eot?#iefix) format("embedded-opentype"), url(../fonts/Lato-Hairline.woff) format("woff"), url(../fonts/Lato-Hairline.ttf) format("truetype");
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: Lato;
	src: url(../fonts/Lato-HairlineItalic.eot);
	src: url(../fonts/Lato-HairlineItalic.eot?#iefix) format("embedded-opentype"), url(../fonts/Lato-HairlineItalic.woff) format("woff"), url(../fonts/Lato-HairlineItalic.ttf) format("truetype");
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: Lato;
	src: url(../fonts/Lato-Thin.eot);
	src: url(../fonts/Lato-Thin.eot?#iefix) format("embedded-opentype"), url(../fonts/Lato-Thin.woff) format("woff"), url(../fonts/Lato-Thin.ttf) format("truetype");
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: Lato;
	src: url(../fonts/Lato-ThinItalic.eot);
	src: url(../fonts/Lato-ThinItalic.eot?#iefix) format("embedded-opentype"), url(../fonts/Lato-ThinItalic.woff) format("woff"), url(../fonts/Lato-ThinItalic.ttf) format("truetype");
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: Lato;
	src: url(../fonts/Lato-Light.eot);
	src: url(../fonts/Lato-Light.eot?#iefix) format("embedded-opentype"), url(../fonts/Lato-Light.woff) format("woff"), url(../fonts/Lato-Light.ttf) format("truetype");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: Lato;
	src: url(../fonts/Lato-LightItalic.eot);
	src: url(../fonts/Lato-LightItalic.eot?#iefix) format("embedded-opentype"), url(../fonts/Lato-LightItalic.woff) format("woff"), url(../fonts/Lato-LightItalic.ttf) format("truetype");
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: Lato;
	src: url(../fonts/Lato-Regular.eot);
	src: url(../fonts/Lato-Regular.eot?#iefix) format("embedded-opentype"), url(../fonts/Lato-Regular.woff) format("woff"), url(../fonts/Lato-Regular.ttf) format("truetype");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: Lato;
	src: url(../fonts/Lato-Italic.eot);
	src: url(../fonts/Lato-Italic.eot?#iefix) format("embedded-opentype"), url(../fonts/Lato-Italic.woff) format("woff"), url(../fonts/Lato-Italic.ttf) format("truetype");
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: "Lato Medium";
	src: url(../fonts/Lato-Medium.eot);
	src: url(../fonts/Lato-Medium.eot?#iefix) format("embedded-opentype"), url(../fonts/Lato-Medium.woff) format("woff"), url(../fonts/Lato-Medium.ttf) format("truetype");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "Lato Medium";
	src: url(../fonts/Lato-MediumItalic.eot);
	src: url(../fonts/Lato-MediumItalic.eot?#iefix) format("embedded-opentype"), url(../fonts/Lato-MediumItalic.woff) format("woff"), url(../fonts/Lato-MediumItalic.ttf) format("truetype");
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: Lato;
	src: url(../fonts/Lato-Semibold.eot);
	src: url(../fonts/Lato-Semibold.eot?#iefix) format("embedded-opentype"), url(../fonts/Lato-Semibold.woff) format("woff"), url(../fonts/Lato-Semibold.ttf) format("truetype");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: Lato;
	src: url(../fonts/Lato-SemiboldItalic.eot);
	src: url(../fonts/Lato-SemiboldItalic.eot?#iefix) format("embedded-opentype"), url(../fonts/Lato-SemiboldItalic.woff) format("woff"), url(../fonts/Lato-SemiboldItalic.ttf) format("truetype");
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: Lato;
	src: url(../fonts/Lato-Bold.eot);
	src: url(../fonts/Lato-Bold.eot?#iefix) format("embedded-opentype"), url(../fonts/Lato-Bold.woff) format("woff"), url(../fonts/Lato-Bold.ttf) format("truetype");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: Lato;
	src: url(../fonts/Lato-BoldItalic.eot);
	src: url(../fonts/Lato-BoldItalic.eot?#iefix) format("embedded-opentype"), url(../fonts/Lato-BoldItalic.woff) format("woff"), url(../fonts/Lato-BoldItalic.ttf) format("truetype");
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: Lato;
	src: url(../fonts/Lato-Heavy.eot);
	src: url(../fonts/Lato-Heavy.eot?#iefix) format("embedded-opentype"), url(../fonts/Lato-Heavy.woff) format("woff"), url(../fonts/Lato-Heavy.ttf) format("truetype");
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: Lato;
	src: url(../fonts/Lato-HeavyItalic.eot);
	src: url(../fonts/Lato-HeavyItalic.eot?#iefix) format("embedded-opentype"), url(../fonts/Lato-HeavyItalic.woff) format("woff"), url(../fonts/Lato-HeavyItalic.ttf) format("truetype");
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: Lato;
	src: url(../fonts/Lato-Black.eot);
	src: url(../fonts/Lato-Black.eot?#iefix) format("embedded-opentype"), url(../fonts/Lato-Black.woff) format("woff"), url(../fonts/Lato-Black.ttf) format("truetype");
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: Lato;
	src: url(../fonts/Lato-BlackItalic.eot);
	src: url(../fonts/Lato-BlackItalic.eot?#iefix) format("embedded-opentype"), url(../fonts/Lato-BlackItalic.woff) format("woff"), url(../fonts/Lato-BlackItalic.ttf) format("truetype");
	font-weight: 900;
	font-style: italic;
}
/* --- END Lato CSS --- */

/* Madhavi */
.select-display-same-row {
	flex: 1;
}

.horizontal-scroll {
	overflow-x: scroll;
}

.vertical-scroll {
	overflow-y: scroll;
}

.Resizer {
	background: #000;
	opacity: 0.2;
	z-index: 1;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-moz-background-clip: padding;
	-webkit-background-clip: padding;
	background-clip: padding-box;
}

.Resizer:hover {
	-webkit-transition: all 2s ease;
	transition: all 2s ease;
}

.Resizer.horizontal {
	height: 11px;
	margin: -5px 0;
	border-top: 5px solid rgba(255, 255, 255, 0);
	border-bottom: 5px solid rgba(255, 255, 255, 0);
	cursor: row-resize;
	width: 100%;
}

.Resizer.horizontal:hover {
	border-top: 5px solid rgba(0, 0, 0, 0.5);
	border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
	width: 11px;
	margin: 0 -5px;
	border-left: 5px solid rgba(255, 255, 255, 0);
	border-right: 5px solid rgba(255, 255, 255, 0);
	cursor: col-resize;
}

.Resizer.vertical:hover {
	border-left: 5px solid rgba(0, 0, 0, 0.5);
	border-right: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.disabled {
	cursor: not-allowed;
}

.Resizer.disabled:hover {
	border-color: transparent;
}

/* End Madhavi */

img.icon {
	opacity: 0.6;

	&.clickable-icon {
		transition: all 0.2s ease-in-out;
		cursor: pointer;

		&:hover {
			opacity: 1;
		}
	}
}

/* Top border effect on cards */

.card::before {
	content: "";
	position: absolute;
	height: 1px;
	z-index: 10;
	left: 4px;
	top: -1px;
	width: calc(100% - 8px);
	background: linear-gradient(to right, rgba($border-color, 0), $blue, rgba($border-color, 0));
}

.table-container:before {
	top: 0;
	z-index: 10000;
}

.table-container {
	position: relative;
}

/* Main Nav Bar */

@keyframes slidein {
	0% {
		right: -320px;
	}

	100% {
		right: 0;
	}
}

@keyframes slideout {
	0% {
		right: 0;
	}

	100% {
		right: -320px;
	}
}

.page-body {
	z-index: 10;
}

/* Text */

html,
body {
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	font-smoothing: antialiased;
	-webkit-font-smoothing: antialiased;
	font-kerning: normal;
	font-size: 14px;
}

.text-lg {
	font-size: $text-lg;
}

.text-sm {
	font-size: $text-sm;
}

/* Links */

a {
	&:active {
		color: #0a9cdd;
		text-decoration: underline;
	}

	// Along with some JS, prevent outline when user clicks links with mouse
	&.mouse {
		outline: none;
	}
}

a.link-muted {
	color: $text-muted;

	&:hover {
		color: $link-hover-color;
		text-decoration: underline;
	}

	&:active {
		color: #0a9cdd;
		text-decoration: underline;
	}
}

/* Basic page framework */

.wrap {
	padding-top: $nav-bar-height;
	display: flex;
	align-items: stretch;
	height: 100vh;
	overflow: hidden;
	align-items: stretch;
}

aside.sidebar,
aside.left-nav {
	overflow-y: hidden;

	&:hover {
		overflow-y: overlay;
	}
}

.main-page {
	background-color: #fff;
	overflow: auto;

	&.gray-bg {
		background-color: #f5f5f5;
	}
}

.gradient-bg {
	background-image: linear-gradient(120deg, #fafafa 50px, #f6f7f7);
}

.left-nav {
	flex-grow: 0;
	flex-shrink: 1;
	background-color: #263444;
	box-shadow: inset -1px 0 0 $border-color;
	z-index: 10;
	position: relative;

	&.secondary-nav .nav-link {
		padding-top: 7px;
		padding-bottom: 7px;
		min-height: 32px;
	}

	/* Madhavi */
	.nav-dropdown.open {
		background: transparent;
	}

	/* End Madhavi */

	.nav-link,
	.nav-title {
		display: flex;
		border-left: 4px solid transparent;
		color: rgba(255, 255, 255, 0.7);
		min-height: 40px;
		/* align-items: center; */
		padding: 12px 4px 12px 16px;
		line-height: 16px;
		font-size: 14px;

		.nav-icon {
			height: 16px;
			width: 16px;
			text-align: center;
			margin-right: 8px;
			color: #7f868c;
			display: flex;
			align-items: center;
			justify-content: center;
			background-repeat: no-repeat;
			/* background-position: center; */
			/* background-size: 24px; */
			margin-top: -1px;
		}

		.nav-icon-spacer {
			width: 16px;
			height: 16px;
			display: inline-block;
		}

		.nav-label {
			color: #6d757b;
			letter-spacing: 0.2px;
		}

		&.active {
			background-color: #405467;
			color: #fff;
			box-shadow: inset -1px 0 0 $border-color;
			//font-weight: 600;
			border-left: 4px solid #ffcb06;

			.nav-icon {
				color: $body-color;
			}

			.nav-label {
				color: $body-color;
			}
		}
	}

	.nav-link:not(.active):hover {
		background-color: rgba(64, 84, 103, 0.24);
		color: #fff;
		box-shadow: inset -1px 0 0 $border-color;

		.nav-icon {
			color: $body-color;
		}

		.nav-label {
			color: $body-color;
		}
	}

	/* Madhavi */
	.nav-title {
		text-transform: none;
		font-size: 12px;
		font-weight: 400;
		color: #6d757b;

		.nav-icon {
			justify-content: inherit;
			font-style: normal;
			padding-left: 20px;
			color: #49535b;
		}
	}

	/* End Madhavi */

	&.left-filters {
		flex-basis: 250px;

		.nav-item {
			padding-bottom: 14px;
			border-bottom: 1px solid rgba(0, 0, 0, 0.08);
		}

		.nav-link {
			min-height: auto;
			padding-bottom: 0;
			padding-top: 14px;

			&:hover {
				background-color: transparent;
			}

			&.active {
				background-color: transparent;
				/* color: $body-color; */
				box-shadow: none;
			}
		}
	}

	/* Special styling if the left nav is primary nav */
	&.main-nav {
		.nav-link {
			.nav-label {
				text-transform: uppercase;
				font-size: 12px;
			}

			&.active {
				background-color: #0993d1;

				.nav-icon {
					color: #fff;
				}

				.nav-label {
					color: #fff;
				}

				&:hover {
					box-shadow: inset -1px 0 0 #0993d1;
				}
			}
		}
	}

	.nav-item.expandable {
		> .nav-link:not(.collapsed) {
			color: $body-color;

			.nav-icon {
				color: $body-color;
			}

			.nav-label {
				color: $body-color;
			}
		}
	}

	.submenu {
		.nav-link {
			min-height: 32px;
			padding: 6px 20px 6px 40px;

			.nav-label {
				text-transform: none;
				font-size: 14px;
			}
		}
	}

	&.with-icons {
		/* If the nav doesn't have icons, the submenu items need extra indentation */
		.nav-item {
			.submenu {
				.nav-link {
					padding-left: 60px;
				}
			}
		}
	}
}

.sidebar .nav {
	padding: 8px 0 0;
}

.sidebar .nav-dropdown {
	&.open .nav-link {
		color: #fff;
	}

	> .nav-dropdown-items .nav-link {
		color: #49535b;
		padding-left: 36px;
	}
}

.nav .icon-col {
	width: 20px;
	text-align: center;
	margin-right: 10px;
	flex-grow: 0;
	overflow: hidden;
	flex-grow: 0;
	flex-shrink: 0;

	.fas {
		font-size: 15px;
	}
}

.level2 > li > .nav-link {
	padding-left: 60px;
}

.main-page {
	flex-grow: 1;
	z-index: 1;
}

.main-page.full-height {
	display: flex;
	flex-direction: column;
	height: 100%;
	height: calc(100vh - 50px);
}

.main-page.full-height .page-body {
	flex-grow: 1;
}

.nav-item.expandable .caret {
	margin-left: auto;
	transition: all 0.15s ease;
	opacity: 0.8;
	transform: rotate(90deg);
	width: 12px;
}

.nav-item.expandable > .nav-link.collapsed .caret {
	transform: rotate(0deg);
}

.page-header {
	margin-bottom: 0;
	padding: 20px;
}

.header-btn-area {
	margin-left: auto;
}

.page-body {
	padding: 0 20px 20px;
}

.page-body.no-padding {
	padding: 0;
}

h1.page-title {
	color: $page-title-color;
	font-size: 26px;
	line-height: 34px;
}

.page-subtitle {
	color: #6b828a;
	margin-top: 4px;
}

.instructional-text {
	color: $text-muted;
	line-height: 20px;
}

#overlay {
	background-color: rgba(0, 0, 0, 0.8);
	position: fixed;
	height: calc(100vh);
	width: calc(100vw);
	z-index: 1031;
}

/* Buttons */

/* Basic button styles */

.btn {
	height: $btn-height;
	min-width: 80px;
	text-align: center;
	line-height: 1em;
	font-size: 14px;
	white-space: nowrap;

	&.btn-primary {
		background-color: $blue;
		border-color: transparent;
		box-shadow: none;

		&:hover {
			background-color: #2fb1eb;
			border-color: transparent;
		}

		&:not(:disabled):not(.disabled):active,
		&:not(:disabled):not(.disabled).active {
			background-color: #0a9cdd;
			border-color: transparent;
			box-shadow: none;
		}

		svg {
			fill: #fff;
		}
	}

	&.btn-secondary {
		background-color: #fff;
		border-color: #91979c;
		color: $body-color;
		box-shadow: none;

		&:hover {
			background-color: #edeeee;
		}

		&.disabled,
		&:disabled {
			background-color: #fff;
			border-color: $border-color;
		}

		&:not(:disabled):not(.disabled):active,
		&:not(:disabled):not(.disabled).active {
			background-color: #dadcde;
			border-color: #91979c;
			box-shadow: none;
			color: $body-color;
		}

		.icon {
			opacity: 0.6;
			transition: $btn-transition;
		}

		&:hover .icon {
			opacity: 1;
		}
	}

	/* For display on a dark background */
	&.btn-outline-light {
		background-color: transparent;
		border-color: rgba(255, 255, 255, 0.9);
		color: #fff;
		box-shadow: none;

		&:hover {
			background-color: rgba(255, 255, 255, 0.1);
			border-color: rgba(255, 255, 255, 0.9);
		}

		&:not(:disabled):not(.disabled):active,
		&:not(:disabled):not(.disabled).active {
			background-color: rgba(0, 0, 0, 0.08);
			border-color: rgba(255, 255, 255, 0.9);
			color: #fff;
			box-shadow: none;
		}
	}

	&.btn-hover-only {
		border: 1px solid transparent;
		background-color: transparent;
		box-shadow: none;

		&:hover {
			background-color: #edeeee;
			border: 1px solid $border-color;
		}

		&:not(:disabled):not(.disabled):active,
		&:not(:disabled):not(.disabled).active {
			background-color: #dadcde;
			border: 1px solid $border-color;
			box-shadow: none;
		}
	}

	&.disabled {
		cursor: not-allowed;
	}

	&.indent-align-col-form-label {
		margin-left: 190px;
		margin-bottom: 8px;
	}
}

.btn-xs {
	height: 20px;
	font-size: 11px;
	padding: 3px 6px;
	min-width: auto;
}

.btn.d-flex {
	justify-content: center;
}

/* Madhavi */
.btn.show-on-hover {
	background-color: transparent;
	border-color: transparent;

	&.disabled {
		background-color: transparent;
		border-color: transparent;
		opacity: 0.5;

		&:hover {
			background-color: transparent;
			border-color: transparent;
			opacity: 0.5;
		}
	}
}

/* End Madhavi */

.btn.btn-invisible {
	border-color: transparent;
	background-color: transparent;
	opacity: 0.6;
}

.show .btn.btn-invisible {
	border-color: $blue;
	background-color: #fff;
}

.btn.btn-invisible:active {
	border-color: transparent;
	background-color: transparent;
	opacity: 0.8;
	color: #32393f;
}

.show .btn.btn-invisible {
	opacity: 1;
}

.btn.no-padding {
	padding-left: 0;
	padding-right: 0;
	border-radius: 0;
}

.dropdown .btn.no-padding {
	padding-right: 20px;

	&.dropdown-toggle:after {
		right: 8px;
	}
}

/* Dropdown Buttons */

.dropdown {
	display: inline-block;

	.btn {
		position: relative;
		padding-right: 36px;
		box-shadow: none;
		text-align: left;
		transition: all 0.15s ease-in-out;
		cursor: pointer;

		&.dropdown-toggle:after {
			border: 0;
			content: url(../images/chevron-down.svg);
			vertical-align: top;
			margin-left: 6px;
			margin-top: -1px;
			position: absolute;
			right: 24px;
		}

		&.btn-primary {
			&:after {
				content: url(../images/chevron-down-white.svg);
			}
		}

		&.btn-outline-light {
			&:after {
				content: url(../images/chevron-down-white.svg);
			}
		}

		&.btn-sm {
			&.dropdown-toggle:after {
				right: 18px;
				top: 5px;
			}
		}
	}

	&.show {
		.dropdown-toggle:focus {
			box-shadow: none;
		}

		> .btn-primary,
		> .btn-primary:hover {
			background-color: #0a9cdd;
		}

		> .btn-secondary,
		> .btn-secondary:hover {
			background-color: #dadcde;
			border-color: $border-color;
			color: $body-color;
		}

		> .btn-outline-light,
		> .btn-outline-light:hover {
			background-color: rgba(0, 0, 0, 0.08);
			border-color: rgba(255, 255, 255, 0.9);
		}
	}

	.dropdown-menu {
		overflow: visible !important;
		top: 4px !important;
		margin-top: 0;
	}

	&.no-caret {
		.btn {
			padding-right: $btn-padding-x;
		}
	}
}

.btn:not(:disabled):not(.disabled):active,
.btn:not(:disabled):not(.disabled).active,
.dropdown.show > .btn:active:focus,
.dropdown.show > .btn:not(:disabled):not(.disabled):active:focus,
.dropdown.show > .btn:not(:disabled):not(.disabled).active:focus,
.dropdown.show > .dropdown > .btn:active:focus {
	box-shadow: none;
}

.dropdown .input-group-prepend + .dropdown-toggle {
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}

.btn.dropdown-toggle:after {
	content: url(../images/chevron-down-white.svg);
	border: 0;
	margin-left: 8px;
}

.btn.dropdown-toggle:after {
	margin-left: 8px;
	position: absolute;
	right: 24px;
	top: 9px;
}

.nav-link.dropdown-toggle {
	padding-right: 30px;
}

.nav-link.dropdown-toggle:after {
	content: url(../images/chevron-down-white.svg);
	border: 0;
	margin-left: 6px;
	position: absolute;
	/* top: 4px; */
	opacity: 0.5;
}

.nav-link.dropdown-toggle:hover:after,
.nav-item.show .nav-link.dropdown-toggle:after {
	opacity: 1;
}

.floating-button.btn-secondary {
	width: 60px;
	height: 60px;
	min-width: auto;
	position: fixed;
	bottom: 30px;
	right: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.05);
}

/* Disabled */

.btn-primary.disabled,
.btn-primary:disabled,
.btn-secondary.disabled,
.btn-secondary:disabled,
.btn-primary.disabled:hover {
	background-color: rgba(73, 83, 91, 0.2);
	background-color: #e7e9ea;
	color: #b6babd;
	cursor: not-allowed;
	opacity: 1;
}

.btn-secondary.disabled,
.btn-secondary:disabled,
.btn-secondary.disabled:hover,
.btn-secondary:disabled:hover {
	background-color: #fff;
	border-color: $border-color;
	color: #b6babd;
	cursor: not-allowed;
	opacity: 1;
}

.btn-link.disabled,
.btn-link:disabled,
.btn-link.disabled:hover,
.btn-link:disabled:hover {
	cursor: not-allowed;
	text-decoration: none;
	color: #a4a9ad;
}

.btn-outline-light.disabled,
.btn-outline-light:disabled,
.btn-outline-light.disabled:hover,
.btn-outline-light:disabled:hover {
	opacity: 0.65;
	cursor: not-allowed;
	background-color: transparent;
}

.btn + .btn,
.btn + .dropdown,
.dropdown + .btn,
.dropdown + .dropdown,
.btn + .btn-group,
.btn-group + .btn {
	margin-left: 8px;
}

/* Focus States */

.btn:focus,
.btn.focus,
.dropdown > .btn:focus,
.dropdown > .btn.focus {
	box-shadow: 0 0 0 2px #003d52;
}

.btn:active:focus,
.btn:not(:disabled):not(.disabled):active:focus,
.btn:not(:disabled):not(.disabled).active:focus,
.dropdown > .btn:active:focus {
	box-shadow: none;
}

.btn-outline {
	&:focus,
	&.focus {
		border-color: transparent;
	}
}

.btn-outline,
.btn-link,
.btn-hover-only {
	&:focus,
	&.focus {
	}

	&.disabled,
	&:disabled {
	}

	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active,
	.show > &.dropdown-toggle {
	}
}

/* Madhavi */
.btn-icon-only {
	width: $btn-height;
	min-width: $btn-height;
	padding-left: 0;
	padding-right: 0;
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

/* End Madhavi */

.icon-btn {
	width: $btn-height;
	min-width: $btn-height;
	padding-left: 0;
	padding-right: 0;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 2;

	&.btn-sm {
		height: $btn-height-sm;
		width: $btn-height-sm;
		min-width: $btn-height;
	}

	&.disabled {
		opacity: 0.4;
	}
}

.icon-btn.hover-effect * {
	z-index: 2;
	opacity: 0.6;
	position: relative;
	transition: all 0.2s ease;
}

.icon-btn.btn-sm.hover-effect {
	opacity: 0.7;
}

.icon-btn.blue * {
	opacity: 1;
	z-index: 2;
}

.icon-btn.blue {
	opacity: 0.6;
	transition: all 3.5s ease;
}

.icon-btn.blue:hover {
	opacity: 1;
}

.icon-btn.blue:hover #fill1 {
	fill: $blue;
}

.icon-btn.hover-effect:hover * {
	opacity: 1;
}

.icon-btn.hover-effect:before {
	content: "";
	position: absolute;
	width: 36px;
	height: 36px;
	background-color: transparent;
	border-radius: 100px;
	display: block;
	transition: all 0.2s ease;
	opacity: 0;
	transform: scale(0.5);
	border: 1px solid transparent;
	z-index: 1;
}

.icon-btn.btn-sm.hover-effect:before {
	height: $btn-height-sm;
	width: $btn-height-sm;
}

.icon-btn.hover-effect:hover:before {
	/* background-color: rgba(0, 0, 0, .08); */
	background-color: #edeeee;
	transform: scale(1);
	opacity: 1;
	border: 1px solid $border-color;
	z-index: 1;
}

.icon-btn.hover-effect + .icon-btn.hover-effect {
	margin-left: 0;
}

.icon-btn.hover-effect.btn-primary:before {
	display: none;
}

.icon-btn.hover-effect.btn-primary * {
	opacity: 1;
}

.clickable-icon {
	/* For clickable icons with no button styling */
	opacity: 0.5;
	width: 16px;
	cursor: pointer;
	transition: $btn-transition;

	&:hover {
		opacity: 0.8;
	}
}

/* Button Sizes */

.btn-sm {
	height: $btn-height-sm;
	padding: 4px $btn-sm-padding-x $btn-sm-padding-y;
	min-width: 60px;

	/* Madhavi */
	&.btn-icon-only {
		width: $btn-height-sm;
		min-width: $btn-height-sm;
		padding-left: 0;
		padding-right: 0;
	}

	/* End Madhavi */

	&.icon-btn {
		width: $btn-height-sm;
		min-width: $btn-height-sm;
		padding-left: 0;
		padding-right: 0;
	}
}

.btn-lg {
	height: $btn-height-lg;
	padding: $btn-lg-padding-y $btn-lg-padding-x;
	font-size: 16px;
}

.btn-height-text {
	height: $btn-height;
	line-height: $btn-height;
}

.btn-height-text-sm {
	height: $btn-height-sm;
	line-height: $btn-height-sm;
}

/* Icon Buttons */

.btn-with-icon > .btn-icon {
	margin-right: 6px;
}

.form-control {
	height: 24px;
	font-size: 12px;
	padding-left: 0;
	padding-bottom: 0.5rem;
	color: #1d2124;
}

.form-control.form-control-sm {
	height: $btn-height-sm;
	padding: 0 10px;
	font-size: 12px;
	line-height: 14px;
}

// Context Menu isn't picking up correct menu styling because of markup differences. This is fixing that.

.bootstrapMenu > ul.dropdown-menu > li > a {
	display: block;
	width: 100%; // For `<button>`s
	padding: $dropdown-item-padding-y $dropdown-item-padding-x;
	clear: both;
	font-weight: $font-weight-normal;
	color: $dropdown-link-color;
	text-align: inherit; // For `<button>`s
	white-space: nowrap; // prevent links from randomly breaking onto new lines
	background-color: transparent; // For `<button>`s
	border: 0; // For `<button>`s

	@include hover-focus {
		color: $dropdown-link-hover-color;
		text-decoration: none;
		@include gradient-bg($dropdown-link-hover-bg);
	}

	&.active,
	&:active {
		color: $dropdown-link-active-color;
		text-decoration: none;
		background-color: $dropdown-link-active-bg;
	}

	&.disabled,
	&:disabled {
		color: $dropdown-link-disabled-color;
		background-color: transparent;

		// Remove CSS gradients if they're enabled
		@if $enable-gradients {
			background-image: none;
		}
	}
}

/* Tabs */

.nav-tabs {
	border-bottom: 1px solid $border-color;
	/* padding-left: 4px; */
	font-size: $text-med;

	&.no-border {
		border-bottom: 0;
	}
}

.nav-tabs-sm {
	font-size: $text-sm;
}

a:not([href]):not([tabindex]):not(.nav-link) {
	/* color: #a4a9ad; */ /* Madhavi */
	color: #0ba4e8;
	cursor: pointer;

	&:active,
	&:hover,
	&:focus {
		background: none;
		color: #0a9cdd;
		text-decoration: underline;
	}
}

.nav-tabs .nav-link {
	border-radius: 0;
	padding: 2px 12px;
	color: #a4a9ad;
}

.nav-tabs .nav-link:hover {
	color: #33aed7;
}

.nav-tabs .nav-item {
	position: relative;
}

.nav-tabs .nav-item:after {
	content: "";
	display: inline-block;
	position: absolute;
	top: 50%;
	height: 14px;
	margin-top: -7px;
	width: 1px;
	border-right: 1px solid #c7cfd3;
}

/* Madhavi */
.nav-tabs .nav-item-with-icon.nav-item:after {
	border-right: 0;
}

.nav-tabs .nav-item-with-icon {
	text-align: center;
	margin-top: 16px;

	.nav-link {
		padding: 6px 8px;
	}
}

/*
.modal-body .nav-tabs .nav-item-with-icon:first-of-type {
    margin-left: 0;
}
*/

.nav-tabs .nav-item-with-icon .nav-link {
	padding-bottom: 8px;
}
/* End Madhavi */

.nav-tabs .nav-item:first-child:after {
	content: "";
	display: none;
}

.nav-tabs a:not([href]):not([tabindex]).nav-link {
	color: #7f868c;
}

.nav-tabs a:not([href]):not([tabindex]).nav-link.active,
.nav-tabs .nav-item.show a:not([href]):not([tabindex]).nav-link {
	color: #333;
	background-color: transparent;
	border-color: transparent transparent #fff;
	border-bottom: 2px solid #1f75b2;
}

.card > .card-tabs > .nav-tabs {
	padding-left: $card-spacer-x;
}

ul.nav-tabs.body-padding {
	padding-left: 20px;
	padding-right: 20px;
}

.nav-tabs .nav-item.show .nav-link {
	border-bottom: 0;
}

.nav-pills {
	.nav-link {
		color: $body-color;
		margin-right: 2px;

		&:hover {
			background-color: rgba(0, 0, 0, 0.1);
		}

		&.disabled,
		&.disabled:hover {
			background-color: transparent;
			cursor: not-allowed !important;
			color: #999;
		}

		&.active,
		&.active:hover {
			background-color: rgba(0, 0, 0, 0.6);
			color: #fff;
		}

		&.nav-link-add {
			img {
				opacity: 0.6;
			}

			&:hover {
				img {
					opacity: 1;
				}
			}
		}
	}

	&.nav-pills-sm {
		font-size: 13px;

		.nav-link {
			padding: 0.4rem 0.8rem;
		}
	}
}

/* Cards */

.card {
	margin-bottom: $base-margin;
	position: relative;
}

.card-header {
	border-bottom: 0;
}

.card {
	.card-buttons {
		margin-left: auto;
		margin-top: -6px;
		transition: opacity 0.3s ease-out;
		-moz-transition: opacity 0.3s ease-out;
		-webkit-transition: opacity 0.3s ease-out;
		opacity: 0;

		> img {
			width: 14px;
			margin-left: 8px;
		}
	}

	&:hover {
		.card-buttons {
			opacity: 1;
		}
	}

	/* Madhavi */
	.form-group .col-form-label {
		overflow-wrap: normal;
	}
	/* End Madhavi */
}

.m-0.card {
	border: 0;
	border-radius: 0;

	.hlgrid-header.bg-light.border-bottom.card-header {
		border-bottom: 0 !important;
	}

	.hlgrid-form.d-flex.flex-column.border.border-light {
		border: 0 !important;
		padding: 6px 16px 0 24px;
	}
}

.modal-body {
	&.px-1,
	> .col-sm-12 {
		padding: 0 !important;
	}

	&.px-1:last-child {
		border-radius: 0 0 4px 4px;
	}

	.d-flex.flex-column.border.border-light,
	.m-0.card .d-flex.flex-column.border.border-light {
		border: 0 !important;
		padding: 0;
		max-height: calc(100vh - 250px);
		overflow: hidden;

		&.hlgrid-form {
			max-height: inherit;
		}

		> .form-group {
			overflow-y: auto;
			margin-bottom: 0;
			padding: 16px;

			.col-form-label {
				padding-left: 0;
			}

			> .form-group:first-child:last-child,
			> .form-group:last-child {
				margin-bottom: 0;

				> .form-group:first-child:last-child,
				> .form-group:last-child {
					margin-bottom: 0;
				}
			}
		}

		> .btnContainer {
			padding: 16px;
		}
	}
}

.h-100.card {
	border: 0;
	border-radius: 0;
	/* background-image: linear-gradient(120deg, #fafafa 50px, #f6f7f7); */
	margin: 0;
}

.card-footer {
	margin-top: -1px;
	/* To avoid a double-border when a table displays above the footer */
}

.card-header + .card-body {
	padding-top: 0;
}

.card-row {
	display: flex;

	> .card {
		margin-right: 12px;
		/* 12px gutter between cards */
	}

	> .card:last-of-type {
		margin-right: 0;
	}
}

.card-col {
	margin-right: 12px;

	&:last-of-type {
		margin-right: 0;
	}
}

.selectable-card {
	transition: all 0.3s ease;
	cursor: pointer;
}

.selectable-card:hover,
.selectable-card.selected,
.selectable-card.selected:hover {
	cursor: pointer;
	border-color: #bbb;
	box-shadow: 0 0 0 3px rgba(4, 165, 229, 0.3);
	border-color: $blue;
}

/* Select Widget */

/* Bootstrap's semi-custom select element */

.custom-select {
	background-image: url(../images/caret-down.svg);
	height: $btn-height;
	padding: 0 $input-rl-padding;
	border-radius: $input-border-radius;
	border: 1px solid $border-color;
}

.custom-select-sm {
	font-size: 14px;
	height: $btn-height-sm;
	line-height: 14px;
	padding: 0 $input-rl-padding-sm;
	padding-right: 25px;
	border-radius: $input-border-radius-sm;
}

.custom-select:focus {
	box-shadow: none;
	outline: none;
}

.input-group-prepend > .select,
.input-group-prepend > .btn {
	border-radius: 18px 0 0 18px;
}

.input-group-text {
	border-color: $border-color;
	background-color: #fff;
	padding: 0 0.75rem;
}

.hlgrid-header-title + div:not(:last-child) > .location-dropdown,
.location-dropdown:not(:last-child) {
	margin-right: 20px;
}

/* Lists */

.list {
	padding-left: 0;

	> .list-item {
		position: relative;
		display: block;
		padding: 20px;
		background-color: #fff;
		border-bottom: 1px solid $table-border-color-v;
	}

	.row-title {
		font-size: 18px;
	}
}

/* Menus */

.dropdown-menu {
}

.dropdown-header {
	display: block;
	padding: 0.5rem 1.5rem;
	margin-bottom: 0;
	font-size: 0.875rem;
	color: #0e9ac8;
	white-space: nowrap;
	font-weight: 600;
}

.dropdown-item:focus {
	outline: 2px #007ba4 solid;
}

.dropdown-item:active,
.dropdown-item:active:hover {
	background-color: $dropdown-link-active-bg;
}

.dropdown-menu > li {
	position: relative;
}

.dropdown-menu > li a.disabled {
	color: #b6babd;

	&:hover {
		background-color: transparent;
		cursor: not-allowed;
	}
}

.dropdown-menu > li .icon-col {
	width: 12px;
	text-align: center;
	margin-right: 8px;
	line-height: inherit;
	vertical-align: baseline;
}

.dropdown-submenu > a {
	padding-right: 30px;
}

.dropdown-submenu > a:after {
	display: block;
	float: right;
	width: 6px;
	height: 10px;
	margin-top: 5px;
	margin-right: -10px;
	content: "";
	position: absolute;
	right: 20px;
	top: 8px;
	background-image: url(../images/angle-right.png);
	background-repeat: no-repeat;
}

.dropdown-menu > li > a {
	display: block;
	clear: both;
	white-space: nowrap;
}

.dropdown-submenu:hover > .dropdown-menu {
	display: block;
}

.dropdown-submenu > .dropdown-menu {
	top: 0;
	left: 100%;
	margin-top: -6px;
	margin-left: -1px;
	-webkit-border-radius: 0 6px 6px;
	-moz-border-radius: 0 6px 6px;
	border-radius: 0 6px 6px;
	clip-path: inset(-3px -3px -6px 2px);
	-webkit-clip-path: inset(-3px -3px -6px 2px);
}

.dropdown-submenu > .dropdown-menu:before {
	display: none;
}

/* Madhavi */
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-submenu:hover > a,
.dropdown-submenu:focus > a {
	background-color: $hover-bg;
}

.dropdown-item.active,
.dropdown-item:active,
.dropdown-item.active:hover {
	background-color: $dropdown-link-active-bg;
	color: $body-color;
}
/* End Madhavi */

.bootstrap-select .no-results {
	margin: 0 !important;
	padding: $dropdown-item-padding-y $dropdown-item-padding-x !important;
}

/* Alerts */

.alert {
	color: #fff;
}

.alert-info {
	background-color: #314e56;
}

.alert-error {
	background-color: #e36163;
}

.alert-warning {
	background-color: #ffc528;
	color: $body-color;
}

.alert-dismissible .close,
.banner-dismissible .close {
	padding: 0;
	top: 20px;
	right: 20px;
	opacity: 0.6;
	height: 16px;
	width: 16px;
	display: flex;
}

.alert-dismissible .close img,
.banner-dismissible .close img {
	width: 16px;
	height: 16px;
}

.alert-dismissible .close:hover,
.banner-dismissible .close:hover {
	opacity: 1;
}

.banner {
	text-align: center;
	padding: $alert-padding-y $alert-padding-x;
	position: relative;
	margin-bottom: 20px;
	text-align: center;
	position: fixed;
	top: 0;
	width: 100%;
	height: 50px;
	display: flex;
	align-items: center;
}

.show-banner #pan-nav {
	top: $nav-bar-height;
}

.show-banner .wrap {
	padding-top: calc(50px + 50px);
}

.banner-info {
	background-color: #314e56;
	color: #fff;
}

.banner-warning {
	background-color: #ffc528;
	color: $body-color;
}

.banner-warning a {
	color: $body-color;
}

.banner-error {
	background-color: #e36163;
	color: #fff;
}

.banner-error a {
	color: #fff;
}

/* Filters */

.filter-group {
	padding: 16px 0;
	font-size: 13px;
	position: relative;
	z-index: 100;
}

.expandable-header {
	text-transform: uppercase;
	font-size: 0.9rem;
	color: #888;
	font-weight: 500;
	cursor: pointer;
	z-index: 1;
}

.expandable-header:after {
	content: "\f078";
	font-family: "Font Awesome 5 Free";
	margin-left: 8px;
	font-size: 0.7rem;
	position: relative;
	top: -2px;
	display: inline-block;
	opacity: 0.7;
}

.expandable-section.closed .expandable-header:after {
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	-o-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	transform: rotate(-90deg);
	-webkit-transition: all 0.05s linear;
	-moz-transition: all 0.05s linear;
	-o-transition: all 0.05s linear;
	transition: all 0.05s linear;
}

.expandable-body {
	margin-top: 10px;
}

.expandable-section.closed .expandable-body {
	display: none;
}

.filter-header,
.filter-name {
	padding: 0 16px;
}

.filter-name {
	padding-bottom: 0;
}

.filter-row {
	display: flex;
	align-items: center;
	padding-left: 16px;
	padding-right: 16px;
	cursor: pointer;
}

.filter-row:hover {
	background-color: rgba(0, 0, 0, 0.05);
}

.filter-check-col {
	width: 16px;
	margin-right: 5px;
}

.filter-icon-col {
	width: 16px;
	text-align: center;
	margin-right: 6px;
}

.filter-label-col {
	flex-grow: 1;
}

.ellipsize {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: inline-block;
}

.filter-count-col {
	align-self: flex-end;
	width: 30px;
	text-align: right;
}

.filter-row .count {
	font-size: 0.9em;
	color: #888;
	align-self: flex-end;
}

/* Global Search Styles (need work) */

#search-bar {
	position: absolute;
	align-items: center;
	display: flex;
	top: 100px;
	z-index: 1040;
	margin-right: auto;
	margin-left: auto;
	background-color: #fff;
	padding: 20px;
	height: 80px;
	width: 800px;
	border-radius: 5px;
	left: 50%;
	margin-left: -400px;
}

#search-box-icon {
	width: 24px;
	margin-right: 25px;
	opacity: 0.4;
}

#search-bar.open {
	border-radius: 7px 7px 0 0;
}

.invisible-input {
	border: 0;
	padding: 0;
	flex-grow: 1;
	font-size: 20px;
	font-weight: 400;
	color: #32393f;

	&::placeholder {
		color: #91979c;
	}
}

.invisible-input:focus {
	border: 0;
	box-shadow: 0;
	outline: none;
}

#search-menu {
	position: absolute;
	top: 200px;
	z-index: 1041;
	background-color: #fff;
	border-top: 1px solid #d5d8d9;
	width: 800px;
	border-radius: 0 0 5px 5px;
	padding-bottom: 12px;
	left: 50%;
	margin-left: -400px;
	box-shadow: inset 0 0 8px -4px #b6babd;
	overflow: hidden;

	.search-menu-header {
		color: #3a4248;
		text-transform: uppercase;
		font-weight: 700;
		padding: 24px 24px 12px;
	}

	.search-menu-item {
		padding: 12px 24px;
		cursor: pointer;

		/* Madhavi */
		&:hover {
			background-color: $row-hover-color;
		}
		/* End Madhavi */
	}

	.search-menu-item-title {
		font-weight: 700;
	}
}

.query-box {
	display: flex;
	position: relative;
	align-items: center;
	flex-grow: 1;

	.search-icon {
		position: absolute;
		left: 16px;
		width: 18px;
		z-index: 1000;
	}

	.form-control {
		padding-left: 50px;
		border-radius: 18px;
		flex-grow: 1;
	}

	.fas.go-btn {
		position: absolute;
		right: 13px;
		opacity: 0.5;
		font-size: 18px;
		z-index: 1000;
	}

	img.go-btn {
		position: absolute;
		right: 13px;
		opacity: 0.7;
		z-index: 1000;
		cursor: pointer;

		&:hover {
			opacity: 1;
		}
	}
}

.input-container {
	position: relative;

	.search-icon {
		width: 16px;
		position: absolute;
		left: 15px;
		top: 10px;
		opacity: 0.5;
	}

	.clear-search-icon {
		position: absolute;
		width: 10px;
		right: 16px;
		opacity: 0.5;
		top: 13px;
		cursor: pointer !important;
		display: none;
		z-index: 10000;

		&:hover {
			opacity: 1;
		}
	}

	&.focus {
		.search-icon {
			opacity: 0.8;
		}
	}

	&.has-content {
		.clear-search-icon {
			display: block;
		}
	}
}

.result-date {
	color: #999;
	margin-left: auto;
}

.nav-link:hover {
	cursor: pointer !important;
}

/* Forms */

.form {
	display: flex;
	flex-direction: column;
	height: calc(100vh - 50px);
	margin: 0;

	.form-header {
		border-bottom: 1px solid $border-color;
	}

	.form-body {
		overflow-y: auto;
		overflow-x: hidden;
		padding-top: 24px;
	}

	.form-footer {
		border-top: 1px solid $border-color;
		padding-top: 24px;
		padding-bottom: 24px;
		z-index: 1000;
		background-color: $body-bg;

		/* Madhavi */
		.form-fixed-width {
			display: flex;
			padding-left: 20px;
			align-items: center;
		}
		/* End Madhavi */

		.button-row-container {
			display: flex;
			align-items: center;

			.col-required-legend {
				text-align: right;
				color: $red;
				flex: 0 0 204px;
				display: flex;
				align-items: center;
				justify-content: flex-end;

				&:before {
					content: "";
					width: 6px;
					height: 6px;
					border-radius: 10px;
					display: inline-block;
					background-color: $red;
					margin-right: 5px;
				}
			}

			.col-buttons {
				margin-left: auto;
			}
		}

		.all-required {
			color: $text-muted;
			display: flex;
			align-items: center;

			&:before {
				content: "";
				width: 6px;
				height: 6px;
				border-radius: 10px;
				display: inline-block;
				background-color: $red;
				margin-right: 5px;
			}
		}
	}

	&.scrolling {
		.form-header {
		}

		.form-footer {
			box-shadow: 0 -3px 5px -2px rgba(0, 0, 0, 0.2);
		}
	}
}

.form-fixed-width {
	width: 900px;
	padding-left: 20px;
}

.form-group {
	margin-bottom: 8px;

	&.row {
		margin-left: 0;
		margin-right: 0;
		flex: 0 0 auto;
	}

	.col-form-label {
		text-align: right;
		/* padding-top: calc(0.375rem + 6px); */
		padding-top: 0;
		padding-bottom: 0;
		padding-right: 12px !important;
		/* padding-left: 40px; */
		line-height: 1.3;
		width: 192px;
		/* position: relative; */
		justify-content: flex-end;
		align-self: center;

		&.align-left {
			text-align: left;
		}

		&.flex-grow-0.flex-shrink-0.col-1:empty {
			width: 0 !important;
			flex: 0 1 !important;
			padding: 0 !important;
		}
	}

	&.list-group .col-form-label,
	&.row-builder .col-form-label,
	&.single-line-layout .col-form-label,
	> .form-group:not(.flex-row):not(.row) .col-form-label,
	[for="$.description"].col-form-label,
	[for="$.qos.marking.ip-dscp"].col-form-label,
	[for="$.port"].col-form-label,
	[for="$.default.ident-by-ip-protocol"].col-form-label,
	[for="$.match"].col-form-label,
	[for="$.certificate"].col-form-label,
	[for="$.rules.entry.*.threat-name"].col-form-label {
		padding-top: 6px;
		align-self: auto;
	}

	> label {
		font-size: 10px;
		color: $gray-medium;
		text-transform: uppercase;
		letter-spacing: 0.6px;
		font-weight: 800;
	}

	&.required .col-form-label:before {
		content: "";
		width: 6px;
		height: 6px;
		border-radius: 10px;
		display: inline-block;
		background-color: $red;
		margin-right: 6px;
		position: relative;
		top: -1px;
	}

	.h-100.card {
		border: 1px solid #c8cbce;
	}
}

.tab-pane {
	.flex-row.row {
		margin-left: 0;
		margin-right: 0;
		flex: 0 0 auto;

		.col-8,
		.col-9:not(.form-control),
		.col-10,
		.col-11,
		.col-12,
		&.col-12 {
			padding-right: 0;
			padding-left: 0;
		}
	}
}

.form-check-input {
	margin-top: 2px;
}

.form-check-label {
	font-size: 12px;
}

[id="$.config.devices.entry.device-group.entry.profiles.hip-objects.entry"] .d-flex.flex-row.form-group:last-child,
[id="$.config.devices.entry.device-group.entry.application-filter.entry"] .form-group {
	.col-form-label:empty {
		display: none;
	}
}

.input-group-append {
	> .btn {
		border-radius: 0 $input-border-radius $input-border-radius 0;
		min-width: auto;

		&.btn-secondary {
			background-color: #fafafa;

			&:hover {
				background-color: #edeeee;
			}
		}
	}

	.dropdown .btn {
		padding-right: $btn-padding-x;
	}
}

.form-group > .d-flex.flex-row.row {
	margin-right: 0;
	margin-left: 0;
}

.form-group.row .col-1,
.form-group.row .col-2,
.form-group.row .col-3,
.form-group.row .col-4,
.form-group.row .col-5,
.form-group.row .col-6,
.form-group.row .col-7,
.form-group.row .col-8,
.form-group.row .col-9,
.form-group.row .col-10,
.form-group.row .col-11,
.form-group.row .col-12 {
	padding-left: 0;
	padding-right: 0;
}

/* Madhavi */
.form-group.row .col,
.form-group.flex-row .col {
	padding-left: 0;
	padding-right: 0;
	font-size: 12px;
}

/* End Madhavi */

.form-group.row .radios-h,
.form-group.row .radios-v,
.form-group.row .checkboxes-h,
.form-group.row .checkboxes-v {
	margin-top: 5px;
}

.col-8,
.col-9,
.col-10 {
	/* max-width: 85%; */ /* Madhavi */
	max-width: 100%;
}

.hint {
	font-size: 12px;
	color: $text-muted;
	margin-top: 5px;
}

.side-by-side-inputs {
	display: flex;

	> input,
	> div {
		margin-right: 10px;
		flex-grow: 1;

		&:last-child {
			margin-right: 0;
		}
	}
}

.form-group .col-check {
	width: 26px;
}

.required .col-form-value {
	flex-grow: 1;
}

.form-control {
	background-color: transparent;
	box-shadow: none;
	padding-left: $input-rl-padding;
	padding-right: $input-rl-padding;
	background-color: #fff;
	align-self: center;
	display: flex;

	&.form-control-underline {
		border-width: 0 0 1px;
		border-radius: 0 !important;
		padding-left: 0;
		padding-right: 0;
	}

	&.form-control-invisible {
		border-color: transparent;
		background-color: transparent;
	}

	&.form-control-sm {
		width: 70px;
	}

	&.form-control-rounded {
		border-radius: 18px;
		padding-left: 16px;
		padding-right: 16px;
	}

	&.form-control-search {
		/* Madhavi */
		border-radius: 18px;
		/* End Madhavi */

		padding-left: 40px;
	}

	&.rounded-input {
		border-radius: 100px;
	}
}

.form-control:focus {
	box-shadow: none;
}

.form-control::placeholder,
.form-control::-webkit-input-placeholder {
	color: #91979c;
	line-height: inherit !important;
}

.dropdown-item.no-hover:hover {
	background-color: transparent;
	cursor: default;
}

.checkboxes-v > div,
.radios-v > div {
	margin-bottom: 4px;
}

.checkboxes-h,
.radios-h {
	display: flex;
}

.checkboxes-h > div,
.radios-h > div {
	margin-bottom: 0;
	margin-right: 20px;
}

.col-check,
.col-radio,
.col-static {
	padding-top: calc(0.375rem + 1px);
}

.form-button-row {
	text-align: right;
	padding-top: 15px;
	border-top: 0;
	width: 800px;
}

.custom-checkbox .custom-control-label::before {
	box-shadow: none;
	background-color: transparent;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 2px;
	width: 14px;
	height: 14px;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
	border-color: $blue;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
	background-size: 10px;
}

.checkboxes-v {
	> div {
		margin-bottom: 4px;
	}

	&.comfortable > div {
		margin-bottom: 8px;
	}
}

.custom-radio .custom-control-label::before {
	position: absolute;
	top: 0.25rem;
	left: 0;
	display: block;
	width: 1rem;
	height: 1rem;
	pointer-events: none;
	content: "";
	user-select: none;
	background-color: transparent;
	box-shadow: none;
	border: 1px solid rgba(0, 0, 0, 0.2);
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
	border-color: $blue;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
	background-size: 8px;
}

.form-text {
	font-size: 12px;
}

input.is-invalid:not([value=""]),
input.is-invalid:focus:not([value=""]) {
	background-color: rgba($red, 0.08);
}

.invalid-feedback {
	align-items: center;
	font-size: $text-sm;
	padding-left: 22px;
	position: relative;
	margin-top: 6px;
	line-height: 16px;

	&::before {
		content: "";
		width: 16px;
		height: 16px;
		background-image: url(../images/Icon_alert_red_16.svg);
		margin-right: 5px;
		background-repeat: no-repeat;
		display: inline-block;
		position: absolute;
		top: 0;
		left: 0;
	}
}

.custom-select.is-invalid,
.form-control.is-invalid:not(.field-radio-group),
.was-validated .custom-select:invalid,
.was-validated .form-control:invalid {
	background-image: none;
}

.custom-select.is-invalid[value=""],
.form-control.is-invalid[value=""]:not(.field-radio-group),
.was-validated .custom-select[value=""]:invalid,
.was-validated .form-control[value=""]:invalid {
	border-color: $red;
}

.custom-select.is-invalid:not([value=""]),
.form-control.is-invalid:not(.field-radio-group):not([value=""]),
.was-validated .custom-select:invalid:not([value=""]),
.was-validated .form-control:invalid:not([value=""]) {
	border-color: $red;
}

/* Madhavi */
// .ReactTable.is-invalid:not([value = '']),
.rt-td.is-invalid:not([value=""]),
.field-radio-group.is-invalid:not(.field-radio-group):not([value=""]) {
	/* border: 1px solid #e15050; */
	border-color: $red;
}
/* End Madhavi */

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:not(.field-radio-group):focus,
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
	box-shadow: none;
}

.form-control:disabled,
.form-control[readonly] {
	background-color: transparent;
	border-color: #eaeaea;
}

.custom-select:disabled {
	opacity: 0.6;
	background-color: transparent;
}

.custom-select.rounded-input,
.custom-select-sm.rounded-input {
	border-radius: 100px;
	padding-left: 12px;
}

textarea.form-control {
	padding-top: 3px;
	padding-bottom: 2px;
	line-height: 1.3em;
	font-size: 12px;
}

.error-banner {
	position: absolute;
	left: 580px;
	top: 60px;
	padding: 0 16px;
	background-color: $red;
	color: #fff;
	border-radius: $border-radius;
	opacity: 0;
	width: 340px;
	height: 50px;
	display: flex;
	align-items: center;
	box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.4);
	transition: all 0.1s;

	&.slideDown {
		top: 80px;
		opacity: 1;
	}

	&.fade-out {
		opacity: 0;
	}

	img {
		margin-right: 11px;
	}
}

#error-banner .error-icon {
	margin-right: 14px;
	width: 24px;
}

/* Table elements (query box, pagination, table container, etc.) */

.table-page-container {
	display: flex;
	flex-direction: column;
	height: calc(100vh - 50px);
	flex-grow: 1;
	flex-basis: auto;
}

.searchbox {
	flex: 0 0 auto;
	padding: 16px;
	display: flex;
	align-items: center;
	position: relative;

	.icon-area {
		padding-left: 15px;
		height: 24px;
		display: flex;
		align-items: center;
		border-left: 1px solid $border-color;
		margin-left: 15px;

		.fas {
			color: #888;
		}
	}

	.border-left {
		border-left: 1px solid $border-color;
		margin-left: 16px;
		padding-left: 16px;
	}
}

#searchbox #search::placeholder {
	font-size: 17px;
	line-height: 24px;
}

.search-icon {
	opacity: 0.4;
	transition: all 0.3s ease;
}

.focus .search-icon {
	opacity: 1;
}

.viz-area {
	background-color: #dadada;
	height: 300px;
	flex-basis: 200px;
	flex-shrink: 0;
	text-align: center;
	color: #888;
	padding: 80px;
}

.table-controls {
	padding: 10px;
	border-bottom: 1px solid #ccc;
	background-color: #fafafa;
	display: flex;
	align-items: center;
	flex: 0 0 auto;
}

/* Pagination */

.pagination-bar {
	flex-shrink: 0;
	padding: 10px 16px;
	display: flex;
	align-items: center;
}

.searchbox + .pagination-bar {
	padding-top: 0;
}

.pagination-bar #context-buttons,
.pagination-bar .context-buttons {
	border-left: 1px solid $border-color;
	padding-left: 16px;
	margin-left: 16px;
}

.pagination-bar .icon-area {
	padding-left: 15px;
	height: 24px;
	display: flex;
	align-items: center;
	border-left: 1px solid $border-color;
	margin-left: 15px;
	flex-shrink: 1;
}

.pagination-bar .icon-area .fas {
	color: #888;
}

.pagination-bar .icon-area img {
	width: 16px;
}

#icon-settings,
.icon-settings {
	opacity: 1;
	cursor: pointer;
	width: 16px;
	height: 16px;

	&:hover {
		opacity: 1;
	}
}

// Pagination container when pagination is inline on the page
.pagination-row {
	display: flex;
	align-items: center;
}

.pagination-control {
	line-height: 14px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-left: auto;

	.page-input {
		padding-left: 5px;
		padding-right: 5px;
		display: inline-block;
		text-align: center;
		width: auto;
		margin-left: 8px;
		margin-right: 8px;
	}

	.full-count {
		margin-right: 12px;
	}

	.btn {
		height: $btn-height-sm;
		width: $btn-height-sm;
		padding: 3px 0;
		min-width: auto;
	}
}

.per-page-control {
	border-right: 1px solid #ccc;
	display: flex;
	margin-right: 12px;
	padding-right: 12px;
	align-items: center;
	white-space: nowrap;

	> span {
		margin-right: 8px;
	}
}

/* Description List */

.description-list {
	> div {
		display: flex;
		margin-bottom: 14px;
		align-items: flex-start;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	.label {
		padding-right: 8px;
		text-align: right;
		flex-basis: 50%;
		flex-grow: 0;
		flex-shrink: 0;
		line-height: 21px;
		margin-top: 1px;
		font-size: 14px;
	}

	.value {
		padding-left: 8px;
		line-height: 21px;
	}

	&.description-list-sm {
		> div {
			margin-bottom: 12px;

			&:last-of-type {
				margin-bottom: 0;
			}
		}

		.label {
			font-size: 11px;
			line-height: 14px;
		}

		.value {
			font-size: $text-sm;
			line-height: 14px;
			margin-top: 1px;
		}
	}
}

/* Tables */

.table {
	thead {
		th {
			color: $table-head-color;
			background-color: $table-head-bg;
			border-top: 1px solid $border-color;
			border-bottom: 1px solid $border-color;
			white-space: nowrap;
			line-height: 18px;
		}
	}

	td {
		border-right: 1px solid $table-border-color-v;
		border-bottom: 1px solid $table-border-color-h;
		border-top: 1px solid $table-border-color-h;
		border-top: 1px solid blue;
		line-height: 18px;
	}
}

.table-hover {
	tbody > tr:hover {
		box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
		z-index: 1000000;
		position: relative;

		> td {
			border-top: 1px solid #ccc;
			border-bottom: 1px solid darken($table-border-color-h, 7%);
		}
	}

	&.table-striped tbody tr:nth-of-type(odd) {
		background-color: $table-accent-bg;
	}
}

.table thead th {
	text-transform: uppercase;
	font-size: $text-sm;
	font-weight: bold;
	border-right: 1px solid $table-border-color-v;
	letter-spacing: 0.75px;
}

/* Madhavi */
.table thead th {
	text-transform: uppercase;
	font-size: 12px;
	font-weight: bold;
	border-bottom: 1px solid #bdc3c7;
	white-space: nowrap;
	border-top: 0;
	color: #3f4448;
	height: 32px;
	line-height: 32px;
	background-color: #fdfdfd;
	padding: 0 10px;
	border-right: 1px solid $border-v-color;
}

.table tr {
	background-color: #fff;
}

.table.table-hover tr:hover {
	background-color: $row-hover-color;
}

.table td {
	border-right: 1px solid $border-v-color;
	border-bottom: 1px solid $border-h-color;
	padding: 5px 10px;
}

/* End Madhavi */

.table.no-borders td {
	border-right: 0;
	border-bottom: 0;
}

.table.h-borders td,
.table.h-borders th {
	border-right: 0;
}

.dot-btn {
	width: 24px;
	text-align: center;
	opacity: 1;
	cursor: pointer;

	.fas {
		color: #777;
	}
}

.ag-layout-compact .ag-cell .dot-btn {
	height: 26px;
	line-height: 26px;
}

.ag-cell .dot-btn,
.ag-header-cell span.dot-btn {
	position: absolute;
	right: 0;
	width: 24px;
	height: 36px;
	line-height: 36px;
	text-align: center;
	visibility: hidden;
}

.ag-cell:hover .dot-btn,
.ag-header-cell:hover .dot-btn {
	opacity: 0.6;
	visibility: visible;
}

/* Madhavi */
.ag-cell:hover .dot-btn {
	background-color: #fff;
}

.table-striped.ag-theme-balham .ag-row-odd ag-cell:hover .dot-btn {
	background-color: #f3fafe;
}

.ag-theme-balham.table-hover .ag-row:hover .dot-btn,
.table-striped.table-hover.ag-theme-balham .ag-row-odd ag-cell:hover .dot-btn {
	background-color: #edf8fd;
}

/* End Madhavi */

.ag-cell .dot-btn-visible,
.ag-header-cell .dot-btn-visible {
	position: relative;
	visibility: visible;
	display: inline-block;
	opacity: 0.6;
}

.dot-btn:hover,
.ag-cell .dot-btn:hover {
	opacity: 1;
}

.dot-btn.open,
.ag-header-cell .dot-btn.open {
	visibility: visible;
	opacity: 1;
}

.cell-menu {
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1032;
}

/* Table Settings */

.table-settings-row {
	padding: 20px;
	display: flex;
	border-bottom: 1px solid $border-color;
	align-items: center;

	.label {
		text-transform: uppercase;
		color: #888;
		font-size: $text-sm;
	}

	.table-settings-icons {
		margin-left: auto;

		.btn + .btn {
			margin-left: 0;
		}

		.btn {
			fill: #0e1012;
		}

		.btn-selected {
			background-color: $blue;
			border-color: transparent;

			&:hover {
				background-color: #2fb1eb;
				border-color: transparent;
			}

			svg {
				fill: #fff;
			}
		}
	}
}

.column-controls {
	.table-settings-row {
		border-bottom: 0;
		padding-bottom: 10px;
	}

	.column-control-row {
		display: flex;
		align-items: center;
		padding: 0 15px;
		height: 36px;

		.col-check {
			width: 14px;
			flex-grow: 0;
			flex-shrink: 0;
			margin-right: 12px;
			height: 100%;
			display: flex;
			align-items: center;

			.custom-checkbox {
				margin-top: 2px;
			}
		}

		.col-lock {
			width: 14px;
			flex-grow: 0;
			flex-shrink: 0;
			margin-right: 10px;
			height: 100%;
			display: flex;
			align-items: center;

			img {
				width: 12px;
				opacity: 0.4;

				&:hover {
					opacity: 1;
				}
			}
		}

		.custom-control-label::before,
		.custom-control-label::after {
			top: 0;
		}

		.col-reorder {
			cursor: grab;
		}

		&:hover {
			background-color: #edf8fd;
		}
	}
}

/* Modals */

.modal-header {
	background-color: $modal-header-bg-color;
	border-bottom: 1px solid $border-color;
	border-radius: $border-radius $border-radius 0 0;
	padding: 8px 20px;

	h1,
	h2,
	h3,
	h4,
	h5,
	.modal-title {
		font-size: 16px;
		font-weight: 400;
	}

	.close {
		margin-top: 4px !important;
	}
}

.close {
	background: url(/images/close_tool.png) 1px 1px no-repeat;
	width: 16px;
	height: 16px;
	padding: 0 !important;
	margin: 0 !important;
	opacity: 0.5;

	&:hover {
		opacity: 1;
	}

	span {
		display: none;
	}
}

.modal-body {
	max-height: calc(100vh - 250px);
	overflow-y: auto;
	padding: 20px;
}

.modal-header .modal-title-lg {
	font-size: 22px;
}

.modal-content {
	border-radius: 4px;
}

.modal-footer {
	background-color: transparent;
	padding: 16px;

	:not(:last-child) {
		margin-right: 0;
	}
}

.modal-xl .modal-footer,
.modal-lg .modal-footer {
	border-top-width: 1px;
}

.full-screen-modal {
	position: fixed;
	top: 20px;
	left: 20px;
	height: calc(100vh - 40px);
	width: calc(100vw - 40px);
	overflow: hidden;
	display: none;
	z-index: 200000;
	border-radius: 4px;
	box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.5);
	-webkit-transition: all 0.1s ease-out;
	-moz-transition: all 0.1s ease-out;
	-ms-transition: all 0.1s ease-out;
	-o-transition: all 0.1s ease-out;
	transition: all 0.1s ease-out;

	&.scale {
		opacity: 0;
		transform: scale(0.9);
	}

	&.scale-open {
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	&.scale-close {
		opacity: 0;
		transform: scale(0.9);
	}

	.modal-header {
		display: flex;
		flex-direction: column;
		flex-shrink: 0;
		flex-grow: 0;
		padding: 20px;
		border-bottom: 1px solid $border-color;

		.top-row {
			display: flex;
			width: 100%;
			align-items: center;
		}

		.pan-logo {
			padding-right: 8px;
		}

		.app-name,
		h1.app-name {
			font-size: $text-sm;
			font-weight: 600;
			text-transform: uppercase;
			margin-bottom: 0;
		}

		.modal-buttons {
			margin-left: auto;
			display: flex;
			align-items: center;
		}
	}

	.modal-subheader {
		padding-top: 10px;
	}

	.modal-body {
		max-height: calc(100vh - 120px);
	}

	.modal-content {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		border-radius: 0;
		box-shadow: none;
		background-color: #fff;
	}
}

/* --- BEGIN hack form modals --- */
.modal-form {
	max-height: calc(100vh - 250px);

	&.modal-xl {
		.modal-body {
			max-height: calc(100vh - 250px);
		}
	}

	.modal-body {
		overflow-y: auto;
		padding: 0;
		border-radius: 4px;
		max-height: calc(100vh - 116px);

		.card {
			margin-bottom: 0;
			border: 0;

			&::before {
				height: 0;
			}

			.hlgrid-header.bg-light.border-bottom.card-header {
				background-color: $modal-header-bg-color !important;
				border-bottom: 1px solid $border-color !important;
				border-radius: $border-radius $border-radius 0 0;
				padding: 8px 16px;
				height: auto;
				min-height: unset;

				h1,
				h2,
				h3,
				h4,
				h5,
				.modal-title,
				.hlgrid-header-title {
					font-size: 16px;
					font-weight: 400;
					margin-bottom: 0;
					line-height: 1.5;
				}

				> .align-items-center {
					height: auto;
				}

				.close {
					margin-top: 4px !important;
				}
			}

			.hlgrid-form.d-flex.flex-column.border.border-light {
				padding: 0;
				max-height: calc(100vh - 250px);
				overflow: hidden;

				> .form-group {
					overflow-y: auto;
					margin-bottom: 0;

					.tab-pane {
						.row:not(:last-child) .row-builder.form-group {
							margin-bottom: 24px;
						}

						.row:first-child:last-child.form-group,
						.row:last-child.form-group,
						.row:first-child:last-child .form-group,
						.row:last-child .form-group {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
}
/* --- END hack form modals --- */

.overlay-scale.open {
	visibility: visible;
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
	transition: transform 0.4s, opacity 0.4s;
}

.overlay {
	visibility: hidden;
	opacity: 0;
	-webkit-transform: scale(0.9);
	transform: scale(0.9);
	-webkit-transition: -webkit-transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
	transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
}

.overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba(153, 204, 51, 0.9);
}

.sidecar {
	background-color: #fff;
	display: flex;
	flex-direction: column;
	z-index: 1040;
	border-left: 1px solid $border-color;

	&.sidecar-right {
		box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
	}

	.sidecar-header {
		background-color: #f6f7f7;
		height: 50px;
		display: flex;
		flex-shrink: 0;
		align-items: center;
		padding: 0 8px 0 15px;
		border-bottom: 1px solid $border-color;

		h1 {
			font-size: 11px;
			color: $text-muted;
			font-weight: bold;
			text-transform: uppercase;
			letter-spacing: 0.75px;
		}

		.close-sidecar {
			width: 16px;
			cursor: pointer;
		}
	}

	.sidecar-body {
		flex-basis: auto;
		flex-grow: 1;
		padding: 0;
		overflow-y: auto;

		&.no-padding {
			padding: 0;
		}
	}

	.sidecar-section {
		padding: 16px;
		margin-bottom: 20px;
		flex: 0 0 auto;

		&.no-padding {
			padding: 0;
		}

		&:last-of-type {
			border-bottom: 0;
		}

		&.border-bottom {
			border-bottom: 1px solid $border-color;
			margin-bottom: 0;
		}
	}

	.sidecar-footer {
		margin-top: auto;
		border-top: 1px solid $border-color;
		padding: 16px;
	}
}

.pull-right {
	margin-left: auto;
}

.label {
	text-transform: uppercase;
	color: #7f868c;
	font-size: 11px;
	font-weight: 600;
}

/* Customizing the display of the cards and description lists on the row details popup */

.alternate-card-view .card {
	border: 0;
}

.alternate-card-view .card:before {
	display: none;
}

.alternate-card-view .form-group > label {
	padding-top: 8px;
}

.alternate-card-view .col {
	border-right: 1px solid $border-color;
	padding-right: 0;
	padding-left: 0;
}

.alternate-card-view .card-header {
	padding: 11px 18px;
	margin-bottom: 10px;
	border-top: 1px solid $border-color;
	border-bottom: 1px solid $border-color;
	background-color: #f8f8f8;
}

.alternate-card-view .card-header h2 {
	font-size: 16px;
	line-height: 16px;
	margin-bottom: 0;
	color: #24292d;
	text-transform: none;
	font-weight: 400;
}

.alternate-card-view .modal-body {
	padding: 0;
}

.alternate-card-view .row {
	margin-right: 0;
	margin-left: 0;
}

.alternate-card-view .form-group {
	margin-bottom: 0;
}

.alternate-card-view .col-form-label {
	flex-basis: 50%;
}

.alternate-card-view .card-body {
	padding: 15px;
}

.alternate-card-view .card-header + .card-body {
	padding: 15px;
}

.splitter-horizontal {
	flex: 0 0 1px;
	height: 1px;
	overflow: visible;
	background-color: transparent;
	z-index: 10000;
	display: flex;
	justify-content: center;
	position: relative;
	cursor: row-resize;
	border-bottom: 1px solid #ccc;

	&:after {
		content: "";
		height: 4px;
		min-height: 4px;
		display: block;
		width: 100%;
		position: absolute;
		top: 0;
		cursor: row-resize;
		z-index: 10000;
	}
}

.handle {
	display: block;
	height: 12px;
	border-radius: 5px 5px 0 0;
	border: 1px solid #ccc;
	width: 50px;
	border-bottom: 0;
	background-color: #f8f8f8;
	cursor: row-resize;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	bottom: -1px;
	box-shadow: 0 -1px 2px 2px rgba(0, 0, 0, 0.05);
	z-index: 2000;
	position: absolute;
}

.handle > div {
	border-bottom: 1px solid #c9c9c9;
	height: 3px;
	width: 20px;
}

.icon-bg {
	position: relative;
	z-index: 1032;
	display: flex;
	align-items: center;
	justify-content: center;
}

.icon-bg:before {
	content: "";
	position: absolute;
	width: 36px;
	height: 36px;
	background-color: transparent;
	border-radius: 100px;
	display: block;
	transition: all 0.2s ease-in-out;
	opacity: 0.5;
	transform: scale(0.7);
	border: 1px solid transparent;
}

.icon-bg:hover:before {
	background-color: rgba(0, 0, 0, 0.08);
	background-color: #edeeee;
	transform: scale(1);
	opacity: 1;
	border: 1px solid $border-color;
}

.icon-bg .header-icon {
	z-index: 1033;
	position: relative;
	transition: all 0.2s ease-in-out;
}

.icon-bg:hover .header-icon {
	opacity: 0.8;
}

.header-icon {
	width: 16px;
	height: auto;
	opacity: 0.5;
}

.header-icon:hover {
	opacity: 0.8;
}

.resizable-nav {
	flex: 0 0 auto;
	width: 220px;
	min-height: 100px;
	min-width: 10px;
	white-space: nowrap;
}

.splitter {
	content: "";
	width: 8px;
	background-color: transparent;
	position: absolute;
	right: 0;
	top: 0;
	min-height: 100px;
	height: 100%;
	cursor: col-resize;
	z-index: 500000;
}

.tooltip {
	font-weight: 600;
}

.bs-tooltip-left {
	margin-right: 5px;
}

.bs-tooltip-right {
	margin-left: 5px;
}

.bs-tooltip-top {
	margin-bottom: 5px;
}

.bs-tooltip-bottom {
	margin-top: 5px;
}

.app-icon-container {
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.app-icon-container img {
	width: 20px;
}

/* ------- */

.nav-submenu {
	padding: 30px 30px;
	background-color: #fff;
	position: absolute;
	left: -250px;
	height: calc(100vh);
	width: 300px;
	z-index: 1999;
	color: #333;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

#applications-menu,
#monitor-menu,
#search-menu2 {
	visibility: hidden;
	position: absolute;
}

.nav-submenu a {
	color: #333;
}

.nav-submenu.slide {
	left: 50px;
}

.nav-submenu.offscreen {
	left: -300px;
}

.visible {
	visibility: visible;
	opacity: 1;
	transition: all 0.15s ease;
	left: 30px;
}

.hidden {
	visibility: hidden;
	opacity: 0;
	left: -200px;
}

body.no-sidebar {
	padding-left: 0;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

body {
	-webkit-transition: padding-left 0.2s ease;
	-moz-transition: padding-left 0.2s ease;
	-o-transition: padding-left 0.2s ease;
	transition: padding-left 0.2s ease;
}

.applications-menu,
.monitor-menu,
.search-menu2 {
	color: #333;
}

.applications-menu a,
.monitor-menu a {
	color: #333;
}

#overlay.light {
	opacity: 0.4;
}

#close-submenu {
	position: absolute;
	right: 20px;
	top: 20px;
	opacity: 0.5;
	cursor: pointer;
	transition: all 0.15s ease;
}

#close-submenu:hover {
	opacity: 0.8;
}

#list-of-apps > div {
	display: flex;
	align-items: center;
}

.nav-submenu .count {
	background-color: #eaeaea;
	font-size: 12px;
	margin-left: 10px;
	border-radius: 10px;
	padding: 1px 6px;
	color: #777;
}

#list-of-apps > div.instances {
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	padding-left: 22px;
	display: none;
}

.nav-submenu .label {
	text-transform: uppercase;
	font-size: 12px;
	color: #0ba4e8;
}

/* Madhavi */
.no-padding {
	padding: 0;
}

.btn-sm,
.btn-group-sm > .btn {
	font-size: 11px;
}

.main .container-fluid {
	padding: 0;
}

.tag-group-item-wrapper {
	-webkit-box-align: center;
	align-items: center;
	display: flex;
	flex: 1;
	flex-wrap: wrap;
	padding: 2px 8px;
	position: relative;
	overflow: hidden;
	box-sizing: border-box;
}

.tag-group-item {
	background-color: hsl(0, 0%, 90%);
	border-radius: 2px;
	display: flex;
	margin: 2px;
	min-width: 0;
	box-sizing: border-box;
}

.tag-group-item .tag-group-item-label {
	border-radius: 2px;
	color: hsl(0, 0%, 20%);
	font-size: 85%;
	overflow: hidden;
	padding: 3px;
	padding-left: 6px;
	text-overflow: ellipsis;
	white-space: nowrap;
	box-sizing: border-box;
}

.tag-group-item .tag-group-item-delete {
	align-items: center;
	border-radius: 2px;
	display: flex;
	padding-left: 4px;
	padding-right: 4px;
	box-sizing: border-box;
}

.tag-group-item .tag-group-item-delete-btn {
	display: inline-block;
	fill: currentColor;
	line-height: 1;
	stroke: currentColor;
	stroke-width: 0;
}

// Custom for Adelphi

@media (min-width: 992px) {
	.header-fixed .app-body {
		margin-top: $nav-bar-height;
	}
}

.badge {
	font-size: 12px !important;
	padding: 0.25em 0.46em !important;
}

.app-body {
	display: flex;
	width: 100%;
	/* padding-left: 50px; */
	transition: all 0.3s ease-out 0s;
	background: #f1f2f2;

	&.reset-body {
		padding-left: 0;
	}

	> .container-fluid {
		padding: 0;
	}

	.badge {
		border-radius: 0;
	}

	.main > .container-fluid > div > .card {
		margin: 0;
		border: 0;
	}

	.main > .container-fluid > div > .card::before {
		height: 0;
	}
}

.ps > .ps__rail-x,
.ps > .ps__rail-y {
	opacity: 0.6;
}
/* End Madhavi */
