#explore-logviewer {
	@import '../expVariables';
	.log-query-wrapper {
		display: flex;
		height: 63px;
		padding: 16px;
		width: 100%;
		background-color: white;

		.pan-select-selection,
		.pan-input {
			border-color: $light-gray;

			&:focus {
				border-color: $link-color;
			}
		}

		.pan-select-dropdown {
			z-index: 1150;
		}

		.pan-input-group.pan-input-group-compact {
			flex: 1 0;
			display: flex;
			// background-color: #fff;

			&.log-query-input-group {
				margin-right: 15px;

				&::after {
					content: '';
					border-left: 1px solid $light-gray;
					width: 1px;
					height: 32px;
					margin-left: 15px;
				}
			}

			&.log-query-date-group {
				flex: 0;
			}

			> * {
				flex: none;
				// fix border and effect overlapping issue
				&.pan-select-focused,
				&:focus,
				&:active,
				&:hover {
					z-index: 1100;
				}
			}

			> .log-query-completion,
			> .log-query-input {
				flex: 1 0;
			}

			// fix border moving issue
			> .log-query-logtype-select {
				> .pan-select-selection {
					border-right-width: 1px !important;
					margin-right: -1px;
					min-width: 100px;
				}

				.pan-select-arrow-icon {
					position: relative;
					left: 4px;
				}
			}

			> *:first-child,
			> .pan-select:first-child > .pan-select-selection,
			> .pan-calendar-picker:first-child .pan-input,
			> .pan-select-auto-complete:first-child .pan-input,
			> .pan-cascader-picker:first-child .pan-input,
			> .pan-mention-wrapper:first-child .pan-mention-editor,
			> .pan-time-picker:first-child .pan-time-picker-input,
			> .pan-time-range-picker:first-child .pan-input {
				border-top-left-radius: $border-radius !important;
				border-bottom-left-radius: $border-radius !important;
			}

			> *:last-child,
			> .pan-select:last-child > .pan-select-selection,
			> .pan-calendar-picker:last-child .pan-input,
			> .pan-select-auto-complete:last-child .pan-input,
			> .pan-cascader-picker:last-child .pan-input,
			> .pan-mention-wrapper:last-child .pan-mention-editor,
			> .pan-time-picker:last-child .pan-time-picker-input,
			> .pan-time-range-picker:first-child .pan-input {
				border-top-right-radius: $border-radius !important;
				border-bottom-right-radius: $border-radius !important;
			}
		}
	}
}
