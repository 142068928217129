.export-popover.pan-popover {
	z-index: 1200;
	padding-top: 30px;

	.pan-popover-arrow {
		display: none;
	}

	.key {
		font-size: 10px;
		font-weight: bold;
		padding-right: 10px;
		text-align: right;
	}

	.value {
		font-size: 10px;
		vertical-align: middle;
		word-wrap: break-word;
		max-width: 590px;
	}
	table {
		max-width: 690px;
	}
}

.export-history {
	@import '../expVariables';
	@keyframes loading-spinner {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
	.loading-btn {
		animation: loading-spinner 1s linear infinite;
		width: 20px;
		height: 20px;
	}
	table {
		font-family: Lato;
		font-style: normal;
		color: $dark-gray;
		border-collapse: collapse;
		width: 100%;
		tbody {
			tr:nth-child(even) {
				background-color: $ag-row-even-bg;
			}
			td {
				padding: 3px 16px;
				.id {
					width: fit-content;
				}
				.status {
					width: fit-content;
					font-size: 14px;
					font-weight: 400;
					text-transform: capitalize;
				}
				.filename {
					margin: auto;
					max-width: 250px;
					text-overflow: ellipsis;
					overflow: hidden;
				}
				.download {
					margin: auto;
					width: 22px;
				}
			}
		}
		thead {
			th {
				font-weight: bold;
				font-size: 12px;
				padding: 5px 16px;
			}
		}
		td,
		th {
			border: 1px solid #dddddd;
			.download-btn,
			.info-btn {
				width: 22px;
				height: 22px;
				cursor: pointer;
			}
			.list-btn {
				width: 20px;
				height: 20px;
				margin-right: 10px;
				cursor: pointer;
			}
		}
		tr {
			td:not(:last-child) {
				border-right: none;
			}
			td:not(:first-child) {
				border-left: none;
			}
		}
	}
}
