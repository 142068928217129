@import 'Constants';

$pan-prefix: pan;
$input-affix-width: 19px;
$input-affix-with-clear-btn-width: 38px;

// ICONFONT
$iconfont-css-prefix: panicon;

@mixin reset{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: $text-color;
  font-size: $font-size-base;
  font-variant: $font-variant-base;
  line-height: $line-height-base;
  list-style: none;
  font-feature-settings: $font-feature-settings-base;
}

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@function sqrt($r) {
  $r: strip-unit($r);
  $x0: 1;
  $x1: $x0;

  @for $i from 1 through 10 {
    $x1: $x0 - ($x0 * $x0 - abs($r)) / (2 * $x0);
    $x0: $x1;
  }

  @return $x1;
}

@function rgba($r, $g, $b, $a) {
  @return unquote("rgba(#{$r}, #{$g}, #{$b}, #{$a})");
}

