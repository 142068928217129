@import "Constants";
@import "Mixins";

.#{$calendar-prefix-cls} {
  position: relative;
  width: 280px;
  font-size: $font-size-base;
  line-height: $line-height-base;
  text-align: left;
  list-style: none;
  background-color: $component-background;
  background-clip: padding-box;
  border: $border-width-base $border-style-base $border-color-inverse;
  border-radius: $border-radius-base;
  outline: none;
  box-shadow: $box-shadow-base;

  &-input-wrap {
    height: 34px;
    padding: 6px $control-padding-horizontal - 2px;
    border-bottom: $border-width-base $border-style-base $border-color-split;
  }

  &-input {
    pointer-events: none;
    width: 100%;
    height: 22px;
    color: $input-color;
    background: $input-bg;
    border: 0;
    outline: 0;
    cursor: auto;
    @include placeholder;
  }

  &-week-number {
    width: 286px;

    &-cell {
      text-align: center;
    }
  }

  &-header {
    @include calendarPanelHeader($calendar-prefix-cls);
  }

  &-body {
    padding: 8px 12px;
  }

  table {
    width: 100%;
    max-width: 100%;
    background-color: transparent;
    border-collapse: collapse;
  }

  table,
  th,
  td {
    text-align: center;
    border: 0;
  }

  &-calendar-table {
    margin-bottom: 0;
    border-spacing: 0;
  }

  &-column-header {
    width: 33px;
    padding: 6px 0;
    line-height: 18px;
    text-align: center;
    .#{$calendar-prefix-cls}-column-header-inner {
      display: block;
      font-weight: normal;
    }
  }

  &-week-number-header {
    .#{$calendar-prefix-cls}-column-header-inner {
      display: none;
    }
  }

  &-cell {
    height: 30px;
    padding: 3px 0;
  }

  &-date {
    display: block;
    width: 24px;
    height: 24px;
    margin: 0 auto;
    padding: 0;
    color: $text-color;
    line-height: 22px;
    text-align: center;
    background: transparent;
    border: $border-width-base $border-style-base transparent;
    border-radius: $border-radius-sm;
    transition: background 0.3s ease;

    &-panel {
      position: relative;
      outline: none;
    }

    &:hover {
      background: $item-hover-bg;
      cursor: pointer;
    }

    &:active {
      color: $text-color-inverse;
      background: $primary-5;
    }
  }

  &-today &-date {
    color: $primary-color;
    font-weight: bold;
    border-color: $primary-color;
  }

  &-selected-day &-date {
    background: $primary-2;
  }

  &-last-month-cell &-date,
  &-next-month-btn-day &-date {
    &,
    &:hover {
      color: $disabled-color;
      background: transparent;
      border-color: transparent;
    }
  }

  &-disabled-cell &-date {
    position: relative;
    width: auto;
    color: $disabled-color;
    background: $disabled-bg;
    border: $border-width-base $border-style-base transparent;
    border-radius: 0;
    cursor: not-allowed;

    &:hover {
      background: $disabled-bg;
    }
  }

  &-disabled-cell#{&}-selected-day &-date::before {
    position: absolute;
    top: -1px;
    left: 5px;
    width: 24px;
    height: 24px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: $border-radius-sm;
    content: '';
  }

  &-disabled-cell#{&}-today &-date {
    position: relative;
    padding-right: 5px;
    padding-left: 5px;
    &::before {
      position: absolute;
      top: -1px;
      left: 5px;
      width: 24px;
      height: 24px;
      border: $border-width-base $border-style-base $disabled-color;
      border-radius: $border-radius-sm;
      content: ' ';
    }
  }

  &-disabled-cell-first-of-row &-date {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &-disabled-cell-last-of-row &-date {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &-footer {
    padding: 0 12px;
    line-height: 38px;
    border-top: $border-width-base $border-style-base $border-color-split;
    &:empty {
      border-top: 0;
    }
    &-btn {
      display: block;
      text-align: center;
    }
    &-extra {
      text-align: left;
    }
  }

  .#{$calendar-prefix-cls}-today-btn,
  .#{$calendar-prefix-cls}-clear-btn {
    display: inline-block;
    margin: 0 0 0 8px;
    text-align: center;
    &-disabled {
      color: $disabled-color;
      cursor: not-allowed;
    }
    &:only-child {
      margin: 0;
    }
  }

  .#{$calendar-prefix-cls}-clear-btn {
    position: absolute;
    top: 7px;
    right: 5px;
    display: none;
    width: 20px;
    height: 20px;
    margin: 0;
    overflow: hidden;
    line-height: 20px;
    text-align: center;
    text-indent: -76px;
  }

  .#{$calendar-prefix-cls}-clear-btn::after {
    display: inline-block;
    width: 20px;
    color: $disabled-color;
    font-size: $font-size-base;
    line-height: 1;
    text-indent: 43px;
    transition: color 0.3s ease;
  }

  .#{$calendar-prefix-cls}-clear-btn:hover::after {
    color: $text-color-secondary;
  }

  .#{$calendar-prefix-cls}-ok-btn {
    @include btn;
    @include btn-primary;
    @include button-size($btn-height-sm, $btn-padding-sm, $font-size-base, $border-radius-base);

    background: linear-gradient(#10ace0, #0c88b0)!important;
    color: #ffffff!important;
    line-height: $btn-height-sm - 2px;

    @include button-disabled();
  }
}
