@import "Constants";
@import "Mixins";

.#{$calendar-prefix-cls}-week-number {
  &-cell {
    opacity: 0.5;
  }
  .#{$calendar-prefix-cls}-body tr {
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      background: $primary-1;
    }
    &.#{$calendar-prefix-cls}-active-week {
      font-weight: bold;
      background: $primary-2;
    }
    .#{$calendar-prefix-cls}-selected-day .#{$calendar-prefix-cls}-date,
    .#{$calendar-prefix-cls}-selected-day:hover .#{$calendar-prefix-cls}-date {
      color: $text-color;
      background: transparent;
    }
  }
}
