.whiteListSwitchButton {
    width: 54px;
    height: 30px;
    display: inline-block;
    cursor: pointer;
    background-image: url(../../../../images/default_state_list_view.png);
    &:hover {
        background-image: url(../../../../images/hover_state_list_view.png);
    }
    &:active {
        background-image: url(../../../../images/Pressed_state_list_view.png);
    }
}
.blueMapSwitchButton {
    width: 53px;
    height: 30px;
    display: inline-block;
    cursor: pointer;
    background-image: url(../../../../images/Default_state_map.png);
    &:hover {
        background-image: url(../../../../images/Hover_state_map.png);
    }
    &:active {
        background-image: url(../../../../images/Pressed_state_Map.png);
    }
}

.blueListSwitchButton {
    width: 54px;
    height: 30px;
    display: inline-block;
    cursor: pointer;
    background-image: url(../../../../images/list_view_Default.png);
    &:hover {
        background-image: url(../../../../images/list_view_hover.png);
    }
    &:active {
        background-image: url(../../../../images/list_view_pressed.png);
    }
}
.whiteMapSwitchButton {
    width: 53px;
    height: 30px;
    display: inline-block;
    cursor: pointer;
    background-image: url(../../../../images/map_default.png);
    &:hover {
        background-image: url(../../../../images/map_hover.png);
    }
    &:active {
        background-image: url(../../../../images/msp_pressed.png);
    }
}


