#insights-app {
	.device-details {
		.metrics-table {
			.device-metrics-table {
				position: relative;
				background-color: white;
				.info-area {
					top: 10%;
				}
				.ag-theme-balham {
					.ag-row,
					.ag-cell {
						border: none;
					}

					.ag-full-width-container {
						border-bottom: 1px solid #dadbdb;
					}

					.ag-pinned-right-header {
						border-left: none;
					}

					.ag-pinned-right-floating-bottom,
					.ag-pinned-right-cols-container {
						.ag-cell {
							border-left: none;
						}
					}
					.ag-pinned-right-header {
						.ag-header-row {
							.ag-header-cell:last-child {
								border-right: none !important;
							}
						}
					}
					.ag-header {
						.ag-header-cell {
							font-family: Lato;
							font-style: normal;
							font-weight: bold;
							font-size: 12px;
							color: #333333;
						}
					}
					.ag-header-cell-text {
						color: #333333;
					}
					.inner-severity-cell {
						padding-left: 25px;
						.severity-icon {
							top: 5px;
							margin-right: 7px;
							position: relative;
							display: inline-block;
							width: 16px;
							height: 16px;
							&.critical {
								background: transparent url('../../../../../../images/icon-critical.svg') no-repeat center;
							}
							&.warning {
								background: transparent url('../../../../../../images/icon-warning.svg') no-repeat center;
							}
							&.healthy {
								background: transparent url('../../../../../../images/icon-healthy.svg') no-repeat center;
							}
						}
					}
					.ag-root {
						border: none;
					}
					.ag-cell {
						background-color: white;
						text-decoration: none;
						cursor: default;
						&[col-id='hostname'],
						&[col-id='metric'] {
							color: #006fcc !important;
							cursor: pointer;
							font-family: Lato;
							font-style: normal;
							font-weight: normal;
							font-size: 12px;
						}
					}
					.ag-header {
						background: #ffffff;
						border-bottom: 1px solid#DADBDB;
						.ag-header-row {
							.ag-header-cell {
								&[col-id='metric'],
								&[col-id='hostname'] {
									padding-left: 12px;
								}

								&:last-child {
									border-right: none !important;
								}
							}
						}
					}

					.ag-body-viewport {
						overflow: auto;
						border: none;

						&::-webkit-scrollbar {
							display: none;
						}
						scrollbar-width: none;
						-ms-overflow-style: none;
						.ag-cell {
							border: none;
						}
						.ag-row.ag-row-last {
							border-bottom: none;
						}
						.ag-row-odd {
							.ag-cell {
								background-color: #f4f5f5;
							}
						}
						.ag-row-even {
							.ag-cell {
								background-color: white;
							}
						}
					}

					.ag-status-bar {
						display: none;
					}
					.ag-primary-cols-header-panel {
						.ag-icon {
							display: none !important;
						}
						.ag-input-wrapper {
							margin-left: 0px;
							margin-right: 7px;
						}
					}

					.ag-root {
						border-width: 0px;
						.ag-header {
							background-color: white;
							height: 28px !important;
							min-height: 28px !important;
							font-family: Lato;
							font-style: normal;
							font-weight: normal;
							font-size: 12px;
							color: #333333;

							.ag-header-row {
								height: 28px !important;
							}

							.ag-header-cell {
								&[col-id='metric_value_pct'],
								&[col-id='hostname'],
								&[col-id='metric'] {
									padding-left: 35px;
								}

								&::after {
									display: none;
								}

								border-right: 1px solid#DADBDB;
							}
						}
					}
				}
			}
		}
	}
}
