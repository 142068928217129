.notifications-drawer {
	.ant-drawer-content-wrapper {
		.ant-drawer-header {
			background: #f4f5f5;
			height: 50px;
			font-weight: normal;
			font-size: 16px;
			border-bottom: 1px solid #dadbdb;
		}

		.ant-drawer-body {
			padding: 10px 16px;
			font-size: 12px;
			.rule-config-card {
				&:not(:last-child) {
					margin-bottom: 16px;
				}
				.input-item {
					&:not(:last-child) {
						margin-bottom: 20px;
					}
					.title {
						margin-bottom: 4px;
					}
					.input {
						.input-error {
							color: red;
							position: relative;
							top: 2px;
							font-size: 10px;
						}
						.condition-builder {
							.add-condition {
								margin-top: 10px;
								.text {
									margin-left: 6px;
								}
							}
							.condition {
								position: relative;
								&:not(:last-child) {
									margin-bottom: 10px;
								}
								> .ant-select {
									> .ant-select-selector {
										border-radius: 4px;
									}
									&:not(:last-child) {
										margin-right: 10px;
									}
									font-family: Lato;
									font-size: 12px;
									.ant-select-selector {
										padding: 0px 5px;
									}
									.ant-select-selection-item {
										padding: 0px;
									}
								}
								.icon-remove {
									position: relative;
									left: 10px;
									top: -2px;
									cursor: pointer;
								}
							}
						}
					}
				}

				&.recipient-config {
					.content {
						padding: 0px;
					}
				}
			}

			.btns {
				position: relative;
				height: 50px;
				.cancel-btn,
				.save-btn {
					display: grid;
					justify-content: center;
					align-content: center;
					width: 105px;
					height: 24px;
					position: absolute;
					top: 10px;
					cursor: pointer;

					box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
					border-radius: 4px;
				}
				.save-btn {
					right: 0px;
					background: #006fcc;
					color: white;
					&.incomplete {
						background-color: #b8b8b8;
					}
				}

				.cancel-btn {
					right: 120px;
					border: 1px solid #dadbdb;
					background: white;
					color: #006fcc;
				}
			}
		}
	}
}

.condition-select {
	.ant-select-item-option {
		font-family: Lato;
		font-size: 12px;
		padding: 5px;
	}

	.ant-select-arrow {
		right: 5px;
		* {
			display: inline-block;
		}
	}
}
